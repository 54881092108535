import React, { useState, useEffect, useRef } from "react";
import "../Styles/PublicCafeDirectory.css";
import { Row, Col } from "react-bootstrap";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import ArrowDown from "../Assets/Images/input-arrow-down.svg";
import SearchIcon from "../Assets/Images/search-icon.svg";
import { useGetallbranchesQuery } from "../Services/CcownerAPI";
import { useNavigate } from "react-router-dom";
import {
  useAdditionalDetailsEditWithAccountHolderIdMutation,
  useGetadditionalDetailswithAccountHolderIdQuery,
  useGetCommonAmbienceQuery,
  useGetCommonFeaturesQuery,
  useGetCommonMenuHighlihtsQuery,
  useGetCommonSpecialistDietryServiceQuery,
  useAdditionalDetailsAddWithAccountHolderIdMutation,
  useGetFlagshipBranchesQuery,
  useGetMapFilterDataQuery,
} from "../Services/CcownerAPI";
import { useGetStatesQuery } from "../Services/CommonAPI"
import DefaultLogo from "../Assets/Images/default-preview-logo.png"
import { Helmet } from 'react-helmet';
import CloseCircle from "../Assets/Images/close-circle.svg"
const mapContainerStyle = {
  height: "925px",
  width: "100%",
  borderRadius: "20px",
};

function PublicCafeDirectory() {
  // const { data: locations, isLoading, error, refetch } = useGetallbranchesQuery();
  const [tradeServiceParams, settradeServiceParams] = useState({
    features: [],
    menu_highlights: [],
    ambience: [],
    dietryCategory: [],
  });
  const {
    data: locations,
    isLoading,
    error,
    refetch,
  } = useGetMapFilterDataQuery(tradeServiceParams);
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  const [filteredLocations, setFilteredLocations] = useState([]); // Filtered locations state
  const [isVisible, setIsVisible] = useState(false);
  const [ismenuVisible, setismenuVisible] = useState(false);
  const [isDietryVisible, setisDietryVisible] = useState(false);
  const [isAmbienceVisible, setisAmbienceVisible] = useState(false);
  const [isState, setisState] = useState(false);


  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedMenuHighlights, setSelectedMenuHighlights] = useState([]);
  const [selectedDietaryServices, setSelectedDietaryServices] = useState([]);
  const [selectedAmbience, setSelectedAmbience] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedTechStack, setSelectedTechStack] = useState([]);

  const { data: featuresData } = useGetCommonFeaturesQuery();

  const { data: ambienceData } = useGetCommonAmbienceQuery();

  // const { data: techstackData } = useGetTechStackQuery();

  const { data: menuhighlitsData } = useGetCommonMenuHighlihtsQuery();

  const { data: specialdietryServiceData } =
    useGetCommonSpecialistDietryServiceQuery();

  const { data: stateData } =
    useGetStatesQuery();

  const handleFeatureChange = (id) => {
    setSelectedFeatures((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle Menu Highlights Change
  const handleMenuHighlightChange = (id) => {
    setSelectedMenuHighlights((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((highlightId) => highlightId !== id)
        : [...prevSelected, id]
    );
  };
  const handleDietaryServiceChange = (id) => {
    setSelectedDietaryServices((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleAmbienceChange = (id) => {
    setSelectedAmbience((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleStateChange = (id) => {
    setSelectedState((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };
  const handleTechStackChange = (id) => {
    setSelectedTechStack((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const mapRef = useRef(null);
  const navigate = useNavigate();
  const toggleDiv = () => {
    setIsVisible(!isVisible);
    setismenuVisible(false);
    setisDietryVisible(false);
    setisAmbienceVisible(false);
    setisState(false);
  };
  const toggleMenuDiv = () => {
    setismenuVisible(!ismenuVisible);
    setisDietryVisible(false);
    setisAmbienceVisible(false);
    setisState(false);
    setIsVisible(false)
  };
  const toggleDietryuDiv = () => {
    setisDietryVisible(!isDietryVisible);
    setisAmbienceVisible(false);
    setisState(false);
    setIsVisible(false);
    setismenuVisible(false)
  };
  const toggleAmbienceuDiv = () => {
    setisAmbienceVisible(!isAmbienceVisible);
    setisState(false);
    setIsVisible(false);
    setismenuVisible(false);
    setisDietryVisible(false);
  };
  const toggleStateuDiv = () => {
    setisState(!isState);
    setIsVisible(false);
    setismenuVisible(false);
    setisDietryVisible(false);
    setisAmbienceVisible(false);
  };

  // Filter locations based on search query
  // Filter locations based on search query
  useEffect(() => {
    if (locations && Array.isArray(locations)) {
      const filtered = locations.filter((location) =>
        location.business_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredLocations(filtered);
    }
  }, [locations, searchQuery]);

  useEffect(() => {
    // Load the Google Maps script
    const script = document.createElement("script");
    script.src =
      `https://maps.googleapis.com/maps/api/js?key=` +
      process.env.REACT_APP_GOOGLEMAP_API;
    script.async = true;
    script.onload = () => {
      // Initialize the map
      const australia = { lat: -25.2744, lng: 133.7751 };
      const map = new window.google.maps.Map(mapRef.current, {
        zoom: 4,
        center: australia,
      });

      // Add markers for filtered locations
      if (filteredLocations && Array.isArray(filteredLocations)) {
        filteredLocations.forEach((location) => {
          const marker = new window.google.maps.Marker({
            position: location.position,
            map: map,
            title: location.title,
          });

          // Create an InfoWindow
          const infoWindow = new window.google.maps.InfoWindow({
            content: `<h6 style="color:orange; cursor:pointer;" id="navigate-${location.id}">${location.business_name}</h6>`,
          });

          // Add a click listener to the marker to open the InfoWindow
          marker.addListener("click", () => {
            infoWindow.open(map, marker);

            // Wait for InfoWindow DOM to be ready
            window.google.maps.event.addListenerOnce(
              infoWindow,
              "domready",
              () => {
                const h6Element = document.getElementById(
                  `navigate-${location.id}`
                );
                if (h6Element) {
                  h6Element.addEventListener("click", () => {
                    navigate(`/cafe-view/${location.id}`);
                  });
                }
              }
            );
          });
        });
      } else {
        console.error("Filtered locations data is unavailable or not an array.");
      }
    };
    document.head.appendChild(script);
  }, [filteredLocations, navigate]);

  const [featuesCategories, setFeaturesCategories] = useState([]);
  const handleFeaturesButtonclick = (serviceTypeId) => {
    setFeaturesCategories((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const [menucategory, setMenu] = useState([]);
  const handleMenuButtonclick = (serviceTypeId) => {
    setMenu((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const [ambiencecategory, setAmbience] = useState([]);
  const handleAmbienceButtonclick = (serviceTypeId) => {
    setAmbience((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const [dietryCategory, setDietry] = useState([]);
  const handleDietryButtonclick = (serviceTypeId) => {
    setDietry((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const [state, setState] = useState([]);
  const handleStateButtonclick = (serviceTypeId) => {
    setState((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const handleApply = () => {

    settradeServiceParams((prevtradeServiceParams) => ({
      ...prevtradeServiceParams,
      features: featuesCategories,
      menu_highlights: menucategory,
      ambience: ambiencecategory,
      dietryCategory: dietryCategory,
      states: state
    }));
    setIsVisible(false);
    setismenuVisible(false);
    setisDietryVisible(false);
    setisAmbienceVisible(false);
    setisState(false);
    refetch();
  };
  const handleResetDietry = () => {
    setSelectedDietaryServices([]);
  };
  const handleResetFeatures = () => {
    setSelectedFeatures([]);
  };
  const handleResetMenu = () => {
    setSelectedMenuHighlights([]);
  };
  const handleResetAmbience = () => {
    setSelectedAmbience([]);
  };

  const handleResetState = () => {
    setSelectedState([]);
  };



  return (
    <>
      <Helmet>
        <title>Clever Cafe | FREE Directory: List Your Business Easily & Make It a 'Cafe Near Me' Destination</title>
        <meta name="description" content="List your cafe business in our free directory to increase visibility, drive local customers, and turn your cafe into a go-to destination in your area." />
        <meta name="keywords" content="free Cafe Directory Page, discover cafes in australia, best free cafe directory page in australia, free australian business directories online, cafe directory australia" />
      </Helmet>
      <div className="public-cafe-directory">
        <section className="public-cafedirectory-banner">
          <div className="publicdirectory-banner-content">
            <h2>Public Cafe Directory</h2>
            {/* <p>
            Maximise your cafe’s visibility with a FREE listing in Australia’s
            most relevant{" "}
            <span className="disply-blk">
              cafe directory. Whether you're a startup or an established cafe,
              reach local
            </span>{" "}
            and national audiences quickly and easily.
          </p> */}
            <h5>
              Boost your cafe's visibility with a FREE listing in Australia's top
              cafe directory.
            </h5>
            <p>
              Cafe entrepreneurs can list their businesses at no cost, whether
              they're a startup{" "}
              <span className="disply-blk">
                or an established business. Gain instant access to local and
                national audiences, increase{" "}
              </span>{" "}
              <span className="disply-blk">
                brand awareness, and attract more customers - all for free.
              </span>
            </p>
          </div>
        </section>
        <section className="directory-map">
          {/* <h2>
          Take your <span className="text-ylw">"cafe near me"</span> search to
          the next <span className="disply-blk">level with Clever Cafe. </span>
        </h2> */}
          <h2>For the general public, accessing the Clever Cafe </h2>
          <h2>directory is completely free.</h2>
          <p>
            Explore unique cafes near you, discover their stories, menus, and<span className="disply-blk">
              ambience, and find new favourites, whether they’re already established</span><span className="disply-blk">
              or opening soon.</span>
          </p>
          <Row className="mb-20 mt-100 directory-map-row">
            <Col md={2} sm={12} lg={2} className="directory-map-col">
              <button onClick={toggleDiv} className="toogle-btn-directory">
                Features <img src={ArrowDown} />
              </button>
              {isVisible && (
                <div className="dropdown-content">
                  <div className="directory-feature-close"><img src={CloseCircle} alt="CloseCircle" width={30} onClick={() => setIsVisible(false)} /></div>
                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      Features
                    </Col>
                    <Col sm={12} md={6} lg={6} className="text-end">
                      <button
                        className="btn-outline"
                        onClick={handleResetFeatures}
                      >
                        Reset
                      </button>
                      <button className="btn-primary mb-20" onClick={handleApply}>
                        Apply
                      </button>
                    </Col>
                  </Row>
                  <div className="d-flex feature-items">
                    <div className="multi-select-wrapper">
                      {featuresData?.map((option) => (
                        <div key={option.id} className="multi-select-item">
                          <div
                            className={`multi-select-container ${selectedFeatures.includes(option.id)
                                ? "selected"
                                : ""
                              }`}
                            onClick={() => handleFeatureChange(option.id)}
                          >
                            <input
                              type="checkbox"
                              id={`option${option.id}`}
                              name="services"
                              value={option.id}
                              checked={selectedFeatures.includes(option.id)}
                              onChange={() => handleFeatureChange(option.id)}
                              onClick={() => handleFeaturesButtonclick(option.id)}
                              style={{ display: "none" }}
                            />
                            <label
                              htmlFor={`option${option.id}`}
                              title={option.name} // Show full name on hover
                            >
                              {option.name.length > 15
                                ? `${option.name.substring(0, 15)}...`
                                : option.name}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>

                  </div>
                </div>
              )}
            </Col>
            <Col md={2} sm={12} lg={2} className="directory-map-col">
              <button onClick={toggleMenuDiv} className="toogle-btn-directory">
                Menu
                <img src={ArrowDown} />
              </button>
              {ismenuVisible && (
                <div className="dropdown-content">
                  <div className="directory-feature-close"><img src={CloseCircle} alt="CloseCircle" width={30} onClick={() => setismenuVisible(false)} /></div>
                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      Menu
                    </Col>
                    <Col sm={12} md={6} lg={6} className="text-end">
                      <button className="btn-outline" onClick={handleResetMenu}>
                        Reset
                      </button>
                      <button className="btn-primary mb-20" onClick={handleApply}>
                        Apply
                      </button>
                    </Col>
                  </Row>
                  <div className="d-flex feature-items">
                    <div className="multi-select-wrapper">
                      {menuhighlitsData?.map((option) => (
                        <div key={option.id} className="multi-select-item">
                          <div
                            className={`multi-select-container ${selectedMenuHighlights.includes(option.id)
                                ? "selected"
                                : ""
                              }`}
                            onClick={() => handleMenuHighlightChange(option.id)}
                          >
                            <input
                              type="checkbox"
                              id={`option${option.id}`}
                              name="products"
                              value={option.id}
                              checked={selectedMenuHighlights.includes(option.id)}
                              onChange={() =>
                                handleMenuHighlightChange(option.id)
                              }
                              onClick={() => handleMenuButtonclick(option.id)}
                              style={{ display: "none" }} // Hide the checkbox
                            />

                            <label
                              htmlFor={`option${option.id}`}
                              title={option.name} // Show full name on hover
                            >
                              {option.name.length > 15
                                ? `${option.name.substring(0, 15)}...`
                                : option.name}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </Col>
            <Col md={2} sm={12} lg={2} className="directory-map-col">
              <button onClick={toggleDietryuDiv} className="toogle-btn-directory">
                Dietary
                <img src={ArrowDown} />
              </button>
              {isDietryVisible && (
                <div className="dropdown-content">
                  <div className="directory-feature-close"><img src={CloseCircle} alt="CloseCircle" width={30} onClick={() => setisDietryVisible(false)} /></div>
                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      Dietary
                    </Col>
                    <Col sm={12} md={6} lg={6} className="text-end">
                      <button className="btn-outline" onClick={handleResetDietry}>
                        Reset
                      </button>
                      <button className="btn-primary mb-20" onClick={handleApply}>
                        Apply
                      </button>
                    </Col>
                  </Row>
                  <div className="d-flex feature-items">
                    <div className="multi-select-wrapper">
                      {specialdietryServiceData?.map((option) => (
                        <div key={option.id} className="multi-select-item">
                          <div
                            className={`multi-select-container ${selectedDietaryServices.includes(option.id)
                                ? "selected"
                                : ""
                              }`}
                            onClick={() => handleDietaryServiceChange(option.id)}
                          >
                            <input
                              type="checkbox"
                              id={`dietaryOption${option.id}`}
                              name="dietaryServices"
                              value={option.id}
                              checked={selectedDietaryServices.includes(
                                option.id
                              )}
                              onChange={() =>
                                handleDietaryServiceChange(option.id)
                              }
                              onClick={() => handleDietryButtonclick(option.id)}
                              style={{ display: "none" }} // Hide the checkbox
                            />

                            <label
                              htmlFor={`option${option.id}`}
                              title={option.name} // Show full name on hover
                            >
                              {option.name.length > 15
                                ? `${option.name.substring(0, 15)}...`
                                : option.name}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </Col>
            <Col md={2} sm={12} lg={2} className="directory-map-col">
              <button
                onClick={toggleAmbienceuDiv}
                className="toogle-btn-directory"
              >
                Ambience
                <img src={ArrowDown} />
              </button>
              {isAmbienceVisible && (

                <div className="dropdown-content">
                  <div className="directory-feature-close"><img src={CloseCircle} alt="CloseCircle" width={30} onClick={() => setisAmbienceVisible(false)} /></div>
                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      Ambience
                    </Col>
                    <Col sm={12} md={6} lg={6} className="text-end">
                      <button
                        className="btn-outline"
                        onClick={handleResetAmbience}
                      >
                        Reset
                      </button>
                      <button
                        className="btn-primary mb-20"
                        onClick={handleApply}
                      >
                        Apply
                      </button>
                    </Col>
                  </Row>
                  <div className="d-flex feature-items">
                    <div className="multi-select-wrapper">
                      {ambienceData?.map((option) => (
                        <div key={option.id} className="multi-select-item">
                          <div
                            className={`multi-select-container ${selectedAmbience.includes(option.id)
                                ? "selected"
                                : ""
                              }`}
                            onClick={() => handleAmbienceChange(option.id)}
                          >
                            <input
                              type="checkbox"
                              id={`ambienceOption${option.id}`}
                              name="ambience"
                              value={option.id}
                              checked={selectedAmbience.includes(option.id)}
                              onChange={() => handleAmbienceChange(option.id)}
                              onClick={() =>
                                handleAmbienceButtonclick(option.id)
                              }
                              style={{ display: "none" }} // Hide the checkbox
                            />
                            <label
                              htmlFor={`ambienceOption${option.id}`}
                              title={option.name}
                            >
                              {option.name.length > 15
                                ? `${option.name.substring(0, 15)}...`
                                : option.name}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

              )}
            </Col>

            <Col md={2} sm={12} lg={2} className="directory-map-col">
              <button
                onClick={toggleStateuDiv}
                className="toogle-btn-directory"
              >
                State
                <img src={ArrowDown} />
              </button>
              {isState && (

                <div className="dropdown-content">
                  <div className="directory-feature-close"><img src={CloseCircle} alt="CloseCircle" width={30} onClick={() => setisState(false)} /></div>
                  <Row>
                    <Col sm={12} md={6} lg={6}>
                      State
                    </Col>
                    <Col sm={12} md={6} lg={6} className="text-end">
                      <button
                        className="btn-outline"
                        onClick={handleResetState}
                      >
                        Reset
                      </button>
                      <button
                        className="btn-primary mb-20"
                        onClick={handleApply}
                      >
                        Apply
                      </button>
                    </Col>
                  </Row>
                  <div className="d-flex feature-items">
                    <div className="multi-select-wrapper">
                      {stateData?.map((option) => (
                        <div key={option.id} className="multi-select-item">
                          <div
                            className={`multi-select-container ${selectedState.includes(option.id)
                                ? "selected"
                                : ""
                              }`}
                            onClick={() => handleStateChange(option.id)}
                          >
                            <input
                              type="checkbox"
                              id={`ambienceOption${option.id}`}
                              name="ambience"
                              value={option.id}
                              checked={selectedState.includes(option.id)}
                              onChange={() => handleStateChange(option.id)}
                              onClick={() =>
                                handleStateButtonclick(option.id)
                              }
                              style={{ display: "none" }} // Hide the checkbox
                            />
                            <label
                              htmlFor={`ambienceOption${option.id}`}
                              title={option.name}
                            >
                              {option.name.length > 15
                                ? `${option.name.substring(0, 15)}...`
                                : option.name}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

              )}
            </Col>

            <Col md={2} sm={12} lg={2} className="directory-map-col">
              <div className="search-box">
                <img src={SearchIcon} alt="SearchIcon" className="search-icon" />
                {/* <input type="text" placeholder="search" /> */}
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)} // Update search query on input change
                />
              </div>
            </Col>
          </Row>
          <div ref={mapRef} style={mapContainerStyle}></div>
        </section>
      </div>
    </>

  );
}

export default PublicCafeDirectory;
