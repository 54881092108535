import React from "react";
import "../Styles/AboutUs.css";
import { Row, Col } from "react-bootstrap";
import ClientasPartnerImg from "../Assets/Images/client-as-partner-img.png";
import Elipse1 from "../Assets/Images/join-our-family-elipse.png";
import JoinOurFamily from "../Assets/Images/our-family.png";
import Memeber1 from "../Assets/Images/Ellipse 86.png";
import Memeber2 from "../Assets/Images/Ellipse 82.png";
import Memeber3 from "../Assets/Images/Ellipse 73.png";
import Memeber4 from "../Assets/Images/Ellipse 72.png";
import Memeber5 from "../Assets/Images/Ellipse 83.png";
import Memeber6 from "../Assets/Images/Ellipse 78.png";
import Memeber7 from "../Assets/Images/Ellipse 85.png";
import Memeber8 from "../Assets/Images/Ellipse 80.png";
import Memeber9 from "../Assets/Images/Ellipse 84.png";
import Memeber10 from "../Assets/Images/Ellipse 77.png";
import ElipseOvelay from "../Assets/Images/elipse-overlay.png";
import Defaultimg from "../Assets/Images/default-page-icons-removebg-preview.png";
import LeftPatternWhatIntheBox from "../Assets/Images/what-sbox-left-pattern.svg";
import RightPatternWhatIntheBox from "../Assets/Images/what-sbox-right-pattern.svg";
import TeamleadershipImg from "../Assets/Images/team-leadership-image.png";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

function AboutUs() {
  return (
    <>
      <Helmet>
        <title>Clever Cafe | About Us - Australian Cafe Industry Experts</title>
        <meta name="description" content="Discover how Clever Cafe supports Australian cafes with expert guidance, meaningful connections, and sustainable growth strategies for the modern cafe operator." />
        <meta name="keywords" content="australian cafe entrepreneurship, australian cafe entrepreneurship ideas, how to run a successful cafe, opening a cafe business plan, smart cafe business plans australia" />
      </Helmet>
      <div className="about-us">
        <section className="section aboutus-baner">
          <div className="about-us-banner-content">
            <h2>ABOUT US</h2>
            <h4>We’re Tired of Seeing Cafes Close Their Doors</h4>
            <p>
              {/* While we call Sydney our home, we proudly serve the entire
            Australian cafe<span className="disply-blk"> industry. Great cafes can thrive anywhere, and
            geography does not limit our</span><span className="disply-blk"> support. That’s why we collaborate with
            people, businesses, and industry</span> <span className="disply-blk">partners across the nation,
            constantly seeking innovative ways to expand our </span>services, value,
            and impact. */}
              Our team has first hand experience across the hospitality industry.
              <span className="disply-blk">
                {" "}
                We’ve been chefs, founders, owners, managers, and workers for
                years,
              </span>
              <span className="disply-blk">
                {" "}
                and have felt the successes and failures that come along with it.
              </span>
            </p>
          </div>
        </section>
        <section className="section value-driven-sec">
          <h3>We Create Savvy Cafe Owners</h3>
          <p>

            All too often, we see small cafe businesses close due to avoidable mistakes, that the owner simply wasn't aware of,<span className="disply-blk">
              or didn’t know how to solve. So, we decided to share our experience with as many people as possible, and help cafe owners</span><span className="disply-blk">
              learn from our past mistakes and successes, rather than failing for themselves.
            </span>
          </p>
          <br />
          <h3>
            Running a Successful Cafe Is Easier Once You Know What to Look For
          </h3>
          <p>
            We offer accredited coaching and mentorship alongside learning
            resources in key industry areas, specifically structured to be easy to
            follow, <span className="disply-blk">and practical for your business. We give advice tailored for
              the Australian cafe industry, making sure the info you receive is</span><span className="disply-blk">
              relevant and up to date. We teach you how to construct profitable
              menus, manage costs, train staff, and make sure your cafe stays open.</span>
          </p>
        </section>
        <section className="section client-as-partner">
          <Row className="d-flex-center">
            <Col xs={12} sm={12} lg={7} md={7} className="div1">
              <h3 className="show-desktop">
                We Connect Cafe Owners and
              </h3>
              <h3 className="show-desktop">Industry Services</h3>
              <p>
                Through our database of cafe business, foodservice companies, and
                trade services, we find connections between what people need, and
                what people offer. Meaning we’ll match you with the supplier who
                has the right product, tradesperson with the right skillset, or
                cafe with the right menu for you. Being connected with the right
                people helps contribute to the overall growth and sustainability
                of the Australian cafe industry.
              </p>
            </Col>
            <Col xs={12} sm={12} lg={5} md={5}>
              <div className="d-flex img-div">
                <h3 className="show-mob">
                  We Connect Cafe Owners and Industry Services
                </h3>
                <img src={ClientasPartnerImg} alt="ClientasPartnerImg" />
              </div>
            </Col>
          </Row>
        </section>
        <section className="whats-the-box section">
          <Row>
            <Col xs={12} sm={12} lg={12} md={12}>
              <div className="content-div">
                <h3>So, what’s in the Box?</h3>
                <p>
                  It’s a fair question! Clever Cafe is a networking, growth, and
                  support platform tailored for cafe owners, foodservice
                  providers, and trades and services. You’ll automatically be
                  added to our directory to be found by other members, but we also
                  heavily promote our directory to cafe enthusiasts around the
                  country. Once you login, you’ll see specific recommended next
                  steps for your business, regular group coaching sessions,
                  optional 1:1 mentoring topics, downloadable resources, and
                  service providers matching your business needs.
                </p>

                <img
                  src={RightPatternWhatIntheBox}
                  alt="LeftPatternWhatIntheBox"
                  className="right-bg"
                />
                <img
                  src={LeftPatternWhatIntheBox}
                  alt="LeftPatternWhatIntheBox"
                  className="left-bg"
                />
              </div>
            </Col>
          </Row>
        </section>
        <section className="join-our-family">
          {/* <div class="members-of-family-desktop">
          <img
            src={JoinOurFamily}
            alt="Elipse"
            className="joinourfamily-elipse"
          />
        </div>
        <div class="members-of-family-mob">
          <h3>Sign Up for Clever Cafe</h3>
          <p>
            All new cafe memberships receive a free expert menu review, 
            instant exposure in our directory, PLUS a one-month free trial of premium membership. 
            Run your cafe with confidence, knowing we’ve got your back.
          </p>
        </div> */}
          <div className="join-our-family-content mob-hide">
            <div className="image-container">
              <img
                src={TeamleadershipImg}
                alt="TeamleadershipImg"
                className="ellipse-image"
              />
              <div className="text-overlay">
                <h3>Sign Up for Clever Cafe</h3>
                <p>
                  All new cafe memberships receive a free expert menu review,
                  instant exposure in our directory, PLUS a one-month free trial
                  of premium membership. Run your cafe with confidence, knowing
                  we’ve got your back.
                </p>
                <Link to="/free-trial-common-signup">
                  {" "}
                  <button type="button" className="btn-register-now">
                    Register Now
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="join-our-family-content-mob mob-show">
            <div className="image-container">
              <div className="text-overlay">
                <h3>Sign Up for Clever Cafe</h3>
                <p>
                  All new cafe memberships receive a free expert menu review,
                  instant exposure in our directory, PLUS a one-month free trial
                  of premium membership. Run your cafe with confidence, knowing
                  we’ve got your back.
                </p>
                <Link to="/free-trial-common-signup">
                  {" "}
                  <button type="button" className="btn-register-now">
                    Register Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AboutUs;
