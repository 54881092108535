import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/SignUp.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ElipseIMg from "../../../Assets/Images/trade-service-elispse.svg";
import CrossIcon from "../../../Assets/Images/cross-icon.svg";
import TickIcon from "../../../Assets/Images/tick-icon-green.svg";
import CrownStar from "../../../Assets/Images/crown-star.svg";
import AlertImg from "../../../Assets/Images/trade-alert-img.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import WhiteAlertImg from "../../../Assets/Images/alert-white.svg";
import BackIcon from "../../../Assets/Images/Back-icon.svg";
import ProcuctTypes from "./ProductTypes";
import validator from "validator";
import { useFreeTrialRegisterMutation } from "../../../Services/TradeserviceAPI";
import { useGetStatesQuery } from "../../../Services/CommonAPI";
import { useGetTradeServiceCategoryQuery } from "../../../Services/CcownerAPI";
import AlertBoxModalAlreadyLoggedIn from "../../../Components/AlertBoxAlreadySignedInMsg"
import TermsandCondition from "../../../Assets/pdf/Termsandconditions.pdf"
import PrivacyPolicy from "../../../Assets/pdf/Privacypolicy.pdf"

function CcOwnerSignUp() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [showProductTypes, setshowProductTypes] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    state: "",
    postCode: "",
    contactNumber: "",
    userName: "",
    password: "",
    confirmPassword: "",
    agreeTerms: false,
    onboardingAssistance: false,
  });
  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useFreeTrialRegisterMutation();

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Clear the error for the field being changed
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  useEffect(() => {
    const {
      firstName,
      lastName,
      email,
      confirmEmail,
      state,
      postCode,
      contactNumber,
      userName,
      password,
      confirmPassword,
      agreeTerms,
    } = formData;

    if (
      firstName &&
      lastName &&
      email &&
      confirmEmail &&
      state &&
      postCode &&
      contactNumber &&
      userName &&
      password &&
      confirmPassword &&
      agreeTerms
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formData]);
  const handleKeyPressNumersOnly = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };
  const { data: statesData } = useGetStatesQuery();


  const validateForm = () => {
    let formErrors = {};
    if (!formData.firstName) {
      formErrors.firstName = "First Name is required";
    } else {
      const regex = /^[a-zA-Z\s.]*$/;

      if (!regex.test(formData.firstName)) {
        formErrors.firstName =
          "Full name can only contain letters, dots, and spaces";
      } else if (formData.firstName.length > 50) {
        formErrors.firstName = "Full name cannot exceed 50 characters";
      }
    }

    if (!formData.lastName) {
      formErrors.lastName = "Last name is required";
    } else {
      const regex = /^[a-zA-Z\s.]*$/;

      if (!regex.test(formData.lastName)) {
        formErrors.lastName =
          "Last name can only contain letters, dots, and spaces";
      } else if (formData.lastName.length > 50) {
        formErrors.lastName = "Last name cannot exceed 50 characters";
      }
    }


    if (!formData.lastName) formErrors.lastName = "Last name is required";
    if (!formData.email) formErrors.email = "Email is required";
    if (formData.email !== formData.confirmEmail)
      formErrors.confirmEmail = "Emails do not match";
    if (!formData.state) formErrors.state = "State is required";
    if (!formData.postCode) formErrors.postCode = "Post Code is required";
    if (!formData.contactNumber) {
      formErrors.contactNumber = "Contact number is required";
    } else if (formData.contactNumber.length < 10) {
      formErrors.contactNumber =
        "Contact number must be at least 10 digits long";
    } else if (formData.contactNumber.length > 12) {
      formErrors.contactNumber = "Contact number cannot exceed 12 digits";
    }
    if (!formData.userName) formErrors.userName = "User Name is required";
    if (!formData.password) {
      formErrors.password = "Password is required";
    } else {
      const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,16}$/;

      if (formData.password.length > 16) {
        formErrors.password = "Password must not exceed 16 characters";
      } else if (!passwordRegex.test(formData.password)) {
        formErrors.password =
          "Password must be 8-16 characters long, and include at least one number and one special character.";
      }
    }
    if (formData.password !== formData.confirmPassword)
      formErrors.confirmPassword = "Passwords do not match";
    if (!formData.agreeTerms)
      formErrors.agreeTerms = "You must agree to the terms and conditions";

    if (!validator.isEmail(formData.email)) {
      formErrors.email = "Invalid email format.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (validateForm()) {
      let PostData = {
        username: formData.userName,
        email: formData.email,
        email2: formData.confirmEmail,
        contact_number: formData.contactNumber,
        password: formData.password,
        password2: formData.confirmPassword,
        first_name: formData.firstName,
        last_name: formData.lastName,
        state: formData.state,
        post_code: formData.postCode,
        agreed_to_terms_and_privacy_policy: formData.agreeTerms,
        opted_in_for_onboarding: formData.onboardingAssistance
      };
      try {
        const response = await submitForm(PostData).unwrap();

        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          confirmEmail: "",
          state: "",
          postCode: "",
          contactNumber: "",
          userName: "",
          password: "",
          confirmPassword: "",
          agreeTerms: false,
          onboardingAssistance: false,
        });
        const id = response?.user.id;
        const email = response?.user.email;
        const onboarding_option = formData.onboardingAssistance;
        const timer = setTimeout(() => {
          navigate("/trade-service-otp-free", {
            state: { id, email, onboarding_option },
          });
        }, 3000);
      } catch (err) {
        console.error("Failed to submit form:", err);
      }
    }
  };

  //
  const handleMouseEnter = () => {
    setshowProductTypes(true);
  };

  const tabs = [
    {
      label: "",
      heading: "Free Marketing & Exposure",
      content: (
        <ul>
          <li>Premium Directory Listings & Profiles</li>
          <li>Strategic Branding & Positioning</li>
          <li>Targeted Exposure for Established Cafes</li>
          <li>Real-Time Startup Exposure</li>
          <li>Complimentary Marketing Support</li>
          <li>Increased Customer Visibility</li>
          <li>Effective Event Promotion</li>
          <li>Positive Review Enhancement</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Automated Industry Networking ",
      content: (
        <ul>
          <li>24/7 Automated Ecosystem Development</li>
          <li>Enhanced Industry Education & Exposure</li>
          <li>Foodservice / Trades & Services Partners</li>
          <li>Direct Communication & Account Applications</li>
          <li>Detailed Profiles & Comprehensive Support</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Expansion & Multisite ",
      content: (
        <ul>
          <li>Financial Planning & Budgeting</li>
          <li>Scalability & Risk Management</li>
          <li>Supply Chain Optimisation</li>
          <li>Advanced Technology Integration</li>
          <li>Operational Efficiency Assessment</li>
          <li>Evaluating Adaptability</li>
          <li>Advanced Training & Development</li>
          <li>Market Research & Analysis</li>
          <li>Marketing Strategy Development</li>
          <li>Competitive Benchmarking</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Start-ups & Incubation",
      content: (
        <ul>
          <li>Early Industry & Public Visibility</li>
          <li>Preliminary Budgeting & Business Planning</li>
          <li>Initial Branding, Marketing & Menu Drafting</li>
          <li>Action Planning & Financial Modelling</li>
          <li>Expert Guidance & Industry Tools</li>
          <li>Accessible Products & Services</li>
          <li>Build Your Own Supportive Ecosystem</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Mentorship",
      content: (
        <ul>
          <li>Accredited Business Coaching</li>
          <li>Leading Hospitality Experts</li>
          <li>Vital Business Management</li>
          <li>80+ Areas of Mentorship</li>
          <li>Creative & Analytical Thinking</li>
          <li>Financial Literacy Coaching</li>
          <li>Crisis Management & Adaptability</li>
          <li>Effective Leadership Strategies</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Menu Development",
      content: (
        <ul>
          <li>Pricing, Profitability & Productivity</li>
          <li>Data-Driven Decision Alignment</li>
          <li>COGS Reduction</li>
          <li>Menu Development & Engineering</li>
          <li>Size, Variety, Seasonality & Simplicity</li>
          <li>Branding, Positioning & Crafting Specials</li>
          <li>Creating Catering Menus</li>
          <li>Executive Chef Mentorship</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Development Materials",
      content: (
        <ul>
          <li>Visual Learning Support</li>
          <li>Member Only Resources</li>
          <li>Operational Templates</li>
          <li>Editable Workbooks</li>
          <li>Editable Checklists</li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevTab) => (prevTab + 1) % tabs.length); // Change to the next tab
    }, 3000); // 3000 milliseconds = 3 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  const handlecloseProductModal = () => {
    setshowProductTypes(false);
  };

  const acccesstoken = localStorage.getItem('accessToken');
  useEffect(() => {
    if (acccesstoken) {
      setShowAlertModal(true)
    }

  }, [acccesstoken])
  const handleCloseAlertModal = () => {
    localStorage.clear();
    setShowAlertModal(false)
  }

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isError) {


      if (error?.status === 403) {
        setErrorMessage("Too many attempts. Please try again later.");
      } else {
        setErrorMessage(error?.data?.message || error?.data?.detail || error?.data?.error || "Failed to create an account");
      }
    }
  }, [isError, error]);

  return (
    <div className="td-owner-signup">
      <section className="section">
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className="image-div">
              <h3>
                <img src={ElipseIMg} alt="ElipseIMg" />
                <span> Trades & services</span>
                <img
                  src={WhiteAlertImg}
                  alt={WhiteAlertImg}
                  className="services-indication-icon"
                  onMouseEnter={handleMouseEnter}
                />
              </h3>
              <p className="sub-text">
                {/* <span>$15 </span>/monthly */}
              </p>
              <div className="features-box">
                <h5>Features</h5>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Professional directory listing
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Ecosystem integration
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  24/7 relevant connections
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Automated networking
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Local & national market reach
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Strengthen online presence
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Review boosting
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Media uploads
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Story sharing
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Brand awareness
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Service showcase
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Event promotion
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Direct account links
                </p>

              </div>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={12}>
            <div className="form-div">
              <Link to="/">
                {" "}
                <img src={BackIcon} alt="BackIcon" />
              </Link>
              <form onSubmit={handleSubmit}>
                <p className="main-head">Account holder details</p>
                <p className="sub-head">
                  To create an account, first enter the personal details of the
                  account holder.
                </p>
                <Row className="mb-16">
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <label>
                      Full name <span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="John"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && (
                      <p className="error">{errors.firstName}</p>
                    )}
                  </Col>
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <label>
                      Last name <span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="John"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName && (
                      <p className="error">{errors.lastName}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col xs={12} sm={12} lg={12} md={12}>
                    <label>
                      Email<span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="abcd123@gmail.com"
                      value={formData.email}
                      name="email"
                      onChange={handleChange}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col xs={12} sm={12} lg={12} md={12}>
                    <label>
                      Confirm email<span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="abcd123@gmail.com"
                      value={formData.confirmEmail}
                      name="confirmEmail"
                      onChange={handleChange}
                    />
                    {errors.confirmEmail && (
                      <p className="error">{errors.confirmEmail}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <label>
                      State<span className="star">*</span>
                    </label>
                    <br />
                    <select
                      name="state"
                      onChange={handleChange}
                      value={formData.state}
                    >
                      <option>Select state</option>
                      {statesData?.map((state) => (
                        <option value={state.id}>{state.name}</option>
                      ))}
                    </select>
                    {errors.state && <p className="error">{errors.state}</p>}
                  </Col>
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <label>
                      Post Code<span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="123 466"
                      name="postCode"
                      onChange={handleChange}
                      value={formData.postCode}
                    />
                    {errors.postCode && (
                      <p className="error">{errors.postCode}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col xs={12} sm={12} lg={12} md={12}>
                    <label>
                      Contact number<span className="star">*</span>
                    </label>
                    <br />
                    <div style={{ display: 'flex', alignItems: 'center' }} className="phone-input">
                      <span style={{ marginRight: '5px' }}>+61</span>
                      <input
                        type="text"
                        placeholder="XXXXXXXXXX"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleChange}
                        onKeyPress={handleKeyPressNumersOnly}
                      />
                    </div>
                    {errors.contactNumber && (
                      <p className="error">{errors.contactNumber}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col xs={12} sm={12} lg={12} md={12}>
                    <label>
                      User Name<span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="John"
                      name="userName"
                      value={formData.userName}
                      onChange={handleChange}
                    />
                  </Col>
                  {errors.userName && (
                    <p className="error">{errors.userName}</p>
                  )}
                </Row>
                <Row className="mb-16">
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <label>
                      Password<span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="password"
                      placeholder="********"
                      name="password"
                      onChange={handleChange}
                      value={formData.password}
                    />
                    {errors.password && (
                      <p className="error">{errors.password}</p>
                    )}
                  </Col>
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <label>
                      Confirm Password<span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="password"
                      placeholder="********"
                      name="confirmPassword"
                      onChange={handleChange}
                      value={formData.confirmPassword}
                    />
                    {errors.confirmPassword && (
                      <p className="error">{errors.confirmPassword}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-16 mt-30">
                  <Col sm={12} xs={12} md={12} lg={12} className="d-flex mb-10">
                    <input
                      type="checkbox"
                      className="checkbox"
                      name="agreeTerms"
                      onChange={handleChange}
                      checked={formData.agreeTerms}
                    />
                    <label class="form-check-label termsCondition-ts" for="checkbox1">
                      I have read and agree to the <a href={TermsandCondition} target="_blank">terms and conditions</a> and
                      <a href={PrivacyPolicy} target="_blank"> privacy policy.</a><span className="star">*</span>
                    </label>
                  </Col>
                  {errors.agreeTerms && (
                    <p className="error">{errors.agreeTerms}</p>
                  )}
                  <Col sm={12} xs={12} md={12} lg={12} className="d-flex">
                    <input type="checkbox" className="checkbox" name="onboardingAssistance" checked={formData.onboardingAssistance} onChange={handleChange} />
                    <label class="form-check-label" for="checkbox1">
                      Add free onboarding assistance?
                      <div class="tooltip-common">
                        <img src={AlertImg} alt="AlertImg" />
                        <span class="tooltip-commontext">
                          A team member will contact you to help create your
                          profile and listing
                        </span>
                      </div>
                    </label>
                  </Col>
                </Row>
                <Row className="mb-16">
                  <button type="submit" className={`send-msg-btn ${formValid ? " btn-primary-valid-trade" : ""
                    }`}>
                    Get Started
                  </button>
                  {isError && errorMessage && (
                    <p style={{ color: "red" }} className="mt-20">
                      Failed to Create Account:  {errorMessage}
                    </p>
                  )}
                  {isSuccess && (
                    <p style={{ color: "green" }} className="isSuceesMsg">
                      Creating an Account.
                    </p>
                  )}
                  {isLoading && <div class="loader-trade"></div>}
                </Row>
              </form>
            </div>
          </Col>
        </Row>
        {showProductTypes && (
          <ProcuctTypes handlecloseProductTypeModal={handlecloseProductModal} />
        )}
      </section>
      <AlertBoxModalAlreadyLoggedIn show={showAlertModal} handleCloseAlertModal={handleCloseAlertModal} />
    </div>
  );
}

export default CcOwnerSignUp;
