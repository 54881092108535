import React, { useState, useEffect, useRef } from "react";
import "../Styles/Contact.css";
import { Row, Col } from "react-bootstrap";
import AlertImg from "../Assets/Images/contact-alert.svg";
import Facebook from "../Assets/Images/facebook-black.svg";
import Linkdin from "../Assets/Images/linkedin-black.svg";
import Instagram from "../Assets/Images/instagram-black.svg";
import Youtube from "../Assets/Images/youtube-black.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSubmitContactFormMutation } from "../Services/WebsiteAPI";
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';

export default function Contact() {
  const location = useLocation();
  const navigate = useNavigate();

  const { fromLandingPopup } = location.state || {};
  const [isfindUsOtherChecked, setIsfindUsOtherChecked] = useState(false);
  const [isInterestOtherChecked, setIsInterestOtherChecked] = useState(false);
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phonenumber: "",
    found_us: "",
    other_find_us: "",
    interests: [],
    other_interest: "",
    message: "",
  });
  const recaptchaRef = useRef(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [verified, setVerified] = useState(false);


  const [industry, setIndustry] = useState("");
  const [interests, setInterests] = useState({
    freeMenuReview: false,
    educationMentorship: false,
    networkingExposure: false,
    businessShowcase: false,
    collaborationOpportunities: false,
    eventParticipation: false,
  });
  const [partnerCode, setPartnerCode] = useState("");
  const [anythingElse, setAnythingElse] = useState("");
  const [operationalStatus, setOperationalStatus] = useState([]);
  const [interest, setInterest] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({
    planning: false,
    open: false,
    exiting: false,
  });

  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useSubmitContactFormMutation();

  const [formValid, setFormValid] = useState(false);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Clear the error for the field being changed
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    let formErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.fullname) {
      formErrors.fullname = "Full name is required";
    } else {
      const regex = /^[a-zA-Z\s.]*$/;

      if (!regex.test(formData.fullname)) {
        formErrors.fullname =
          "Full name can only contain letters, dots, and spaces";
      } else if (formData.fullname.length > 50) {
        formErrors.fullname = "Full name cannot exceed 50 characters";
      }
    }

    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      // Check if email format is invalid
      formErrors.email = "Invalid email format";
    }
    if (!formData.phonenumber) {
      formErrors.phonenumber = "Phone number is reqyired";
    } else if (formData.phonenumber && formData.phonenumber.length < 10) {
      formErrors.phonenumber = "Phone number must be at least 10 digits long";
    } else if (formData.phonenumber && formData.phonenumber.length > 12) {
      formErrors.phonenumber = "Phone number cannot exceed 12 digits";
    }
    if (!formData.message) {
      formErrors.message = "Message is required";
    } else if (formData.message && formData.message.length > 200) {
      formData.message = "Message cannot exceed 200 characters";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  const handleKeyPressNumersOnly = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (fromLandingPopup) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        interests: prevFormData.interests?.includes("event_program")
          ? prevFormData.interests // Avoid duplicates
          : [...prevFormData.interests, "event_program"],
      }));
    }
  }, [fromLandingPopup]);

  const handleCheckboxInterestChange = (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => {
      if (prevFormData.interests.includes(value)) {
        // If already selected, remove the value
        return {
          ...prevFormData,
          interests: prevFormData.interests.filter((interest) => interest !== value),
        };
      } else {
        // If not selected, add the value
        return {
          ...prevFormData,
          interests: [...prevFormData.interests, value],
        };
      }
    });
  };

  useEffect(() => {
    const { fullname, email, phonenumber, message } = formData;

    if (fullname && email && phonenumber && email && message) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formData]);

  const handleChangeStatus = (event) => {
    const { value, checked } = event.target;

    setSelectedOptions((prevState) => ({
      ...prevState,
      [value]: checked, // Toggle the selected option
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!verified) {
      // alert("Please complete the reCAPTCHA verification.");
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please complete the reCAPTCHA verification.',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      return;
    }


    let operationalStatus = "";

    if (selectedOptions.planning) {
      operationalStatus = "planning";
    } else if (selectedOptions.open && selectedOptions.exiting) {
      operationalStatus = "open-exit";
    } else if (selectedOptions.open) {
      operationalStatus = "open";
    } else if (selectedOptions.exiting) {
      operationalStatus = "exit";
    }

    if (validateForm()) {
      let PostData = {
        full_name: formData.fullname,
        email: formData.email,
        phone_number: formData.phonenumber,
        message: formData.message,
      };

      if (formData.found_us) {
        PostData.found_us = formData.found_us;
      }

      if (formData.other_find_us) {
        PostData.other_find_us = formData.other_find_us;
      }
      if (formData.interests) {
        PostData.interests = formData.interests;
      }

      if (formData.other_interest) {
        PostData.other_interest = formData.other_interest;
      }

      if (industry) {
        PostData.industry_type = industry;
      }
      if (anythingElse) {
        PostData.other_interest = anythingElse;
      }
      if (partnerCode) {
        PostData.partnership_code = partnerCode;
      }

      if (operationalStatus) {
        PostData.operational_status = operationalStatus;
      }

      if (interest) {
        PostData.interests = interest;
      }

      try {
        const response = await submitForm(PostData).unwrap();
        if (response) {
          // Reset the form state before reloading
          setFormData({
            fullname: "",
            email: "",
            phonenumber: "",
            found_us: "",
            other_find_us: "",
            interests: "",
            other_interest: "",
            message: "",
          });

          // Trigger a page reload after form submission
          navigate(0);
        }
      } catch (err) {
        console.error("Failed to submit form:", err);
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setFormData({
        firstname: '',
        lastname: '',
        email: '',
      });
      recaptchaRef.current.reset();
      setVerified(false);
    }
  }, [isSuccess])

  const handleCaptcha = (value) => {
    setVerified(true);  // Set to true when successfully verified
  };


  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

  }, [isSuccess]);

  const handleCheckboxFindUsChange = (e) => {
    const { value } = e.target;

    // If 'Other' is selected
    if (value === "Other") {
      setIsfindUsOtherChecked(true);
      setFormData((prevData) => ({
        ...prevData,
        found_us: "", // Clear found_us when 'Other' is selected
      }));
    } else {
      // Clear 'Other' input if any other platform is selected
      setIsfindUsOtherChecked(false);
      setFormData((prevData) => ({
        ...prevData,
        found_us: value, // Set the clicked platform as found_us
        other_find_us: "", // Clear other_find_us
      }));
    }
  };

  // const handleCheckboxFindUsChange = (event) => {
  //   setIsfindUsOtherChecked(event.target.checked);
  // };

  // const handleCheckboxInterestChange = (e) => {
  //   const { value, checked } = e.target;

  //   setInterest(value);
  //   if (value === "Other") {
  //     setIsInterestOtherChecked(checked);
  //     if (!checked) {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         other_interest: "", 
  //       }));
  //     }
  //   } else {
  //     setIsInterestOtherChecked(false);
  //     if (checked) {
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         interests: value, 
  //         other_interest: "", 
  //       }));
  //     }
  //   }
  // };

  const handleKeyPressAllowschar = (e) => {
    const charCode = e.charCode;

    // Regex pattern: allows only letters, numbers, and spaces
    const pattern = /^[a-zA-Z0-9\s]$/;

    // Convert charCode to the character
    const charStr = String.fromCharCode(charCode);

    // Check if the input character matches the pattern
    if (!pattern.test(charStr)) {
      e.preventDefault(); // Prevent invalid characters from being entered
    }
  };



  return (
    <>
      <Helmet>
        <title>Clever Cafe | Contact Us - Connect with Australia's Cafe Experts</title>
        <meta name="description" content="Reach out to Clever Cafe’s team for tailored advice and solutions designed specifically for cafe businesses across Australia." />
        <meta name="keywords" content="cafe business planning in australia, starting a cafe business in australia, australian cafe entrepreneurship, cafe planning and modelling in australia" />
      </Helmet>
      <div className="contact-us">
        <section className="section contact-banner">
          <div className="content-div">
            <h3>Contact Us</h3>
            <p>
              While Sydney is our home, we proudly serve the entire Australian
              cafe industry.<span className="disply-blk"> We recognise that thriving as a cafe takes more than
                passion—it demands the right location,</span> strategy, and support.
            </p>
            <p>
              That’s why we collaborate with people, businesses, and industry
              partners nationwide, <span className="disply-blk">tailoring our services to help cafes succeed
                wherever they are.</span>
            </p>
          </div>
        </section>
        <section className="section get-in-touch">
          <Row>
            <Col sm={12} xs={12} lg={6} md={6}>
              <h3>Get In Touch</h3>
              <h3>With Us</h3>
              <p>
                Whether you have a question, need support, or want to explore how
                we can work together, we'd love to hear from you. Reach out to us
                through any of the methods below, and we'll get back to you as
                soon as possible.
              </p>
              <h4 className="mt-50">Contact Information</h4>
              <a href="mailto:hello@clevercafecompany.com.au" className="mailto">
                <p className="contact-information">
                  Email: hello@clevercafecompany.com.au
                </p>
              </a>
              <a href="tel:+61 468 557 420" className="mailto">
                <p className="contact-information">Phone: +61 468 557 420</p>
              </a>
              <a
                href="https://www.google.com/maps?q=11-17 York Street, Sydney New South Wales 2000"
                className="mailto"
                target="_blank"
              >
                <p className="contact-information">
                  Address: 11-17 York Street, Sydney New South Wales 2000
                </p>
              </a>
            </Col>
            <Col sm={12} xs={12} lg={6} md={6}>
              <div className="send-us-message">
                <h4>Send Us a Message:</h4>
                <p>
                  Have a specific question or request? Fill out the form below,
                  and we'll get back to you promptly.
                </p>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col sm={12} xs={12} md={12} lg={12}>
                      <div className="form-control">
                        <label>
                          Full name<span className="star"> *</span>
                        </label>
                        <input
                          type="text"
                          placeholder="John"
                          name="fullname"
                          value={formData.fullname}
                          send-msg-btn
                          mt-30
                          onChange={handleChange}
                        />
                        {errors.fullname && (
                          <p className="error">{errors.fullname}</p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} xs={12} md={6} lg={6}>
                      <div className="form-control">
                        <label>
                          Email<span className="star"> *</span>
                        </label>
                        <input
                          type="text"
                          name="email"
                          value={formData.email}
                          placeholder="abcd123@gmail.com"
                          onChange={handleChange}
                        />
                        {errors.email && <p className="error">{errors.email}</p>}
                      </div>
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={6}>
                      <div className="form-control">
                        <label>Phone number</label>
                        <input
                          type="text"
                          onKeyPress={handleKeyPressNumersOnly}
                          placeholder="XXXXXXXXXX"
                          name="phonenumber"
                          value={formData.phonenumber}
                          onChange={handleChange}
                        />
                        {errors.phonenumber && (
                          <p className="error">{errors.phonenumber}</p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <label className="bold-text">
                      How Did You Find Us?<span className="star"> *</span>
                    </label>
                    <label>
                      Let us know how you found us by selecting one of the options
                      below or typing in your own:
                    </label>
                    <Row className="mt-10">
                      <Col sm={4} xs={4} md={3} lg={3} className="d-flex">
                        <input
                          type="checkbox"
                          className="checkbox"
                          value="Facebook"
                          checked={formData.found_us === "Facebook"}
                          onChange={handleCheckboxFindUsChange}
                        />
                        <label class="form-check-label" for="checkbox1">
                          Facebook
                        </label>
                      </Col>
                      <Col sm={4} xs={4} md={3} lg={3} className="d-flex">
                        <input
                          type="checkbox"
                          className="checkbox"
                          value="YouTube"
                          checked={formData.found_us === "YouTube"}
                          onChange={handleCheckboxFindUsChange}
                        />
                        <label class="form-check-label" for="checkbox1">
                          YouTube
                        </label>
                      </Col>
                      {/* <Col sm={4} xs={4} md={3} lg={3} className="d-flex">
                      <input
                        type="checkbox"
                        className="checkbox"
                        value="Twitter"
                        checked={formData.found_us === "Twitter"}
                        onChange={handleCheckboxFindUsChange}
                      />
                      <label class="form-check-label" for="checkbox1">
                        Twitter
                      </label>
                    </Col> */}
                      <Col sm={4} xs={4} md={3} lg={3} className="d-flex">
                        <input
                          type="checkbox"
                          className="checkbox"
                          value="Instagram"
                          checked={formData.found_us === "Instagram"}
                          onChange={handleCheckboxFindUsChange}
                        />
                        <label class="form-check-label" for="checkbox1">
                          Instagram
                        </label>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={4} xs={4} md={3} lg={3} className="d-flex">
                        <input
                          type="checkbox"
                          className="checkbox"
                          value="LinkedIn"
                          checked={formData.found_us === "LinkedIn"}
                          onChange={handleCheckboxFindUsChange}
                        />
                        <label class="form-check-label" for="checkbox1">
                          LinkedIn
                        </label>
                      </Col>
                      <Col sm={4} xs={4} md={3} lg={3} className="d-flex">
                        <input
                          type="checkbox"
                          className="checkbox"
                          value="Other"
                          checked={isfindUsOtherChecked}
                          onChange={handleCheckboxFindUsChange}
                        />
                        <label class="form-check-label" for="checkbox1">
                          Other
                        </label>
                      </Col>
                    </Row>
                  </Row>
                  {isfindUsOtherChecked && (
                    <Row>
                      <Col sm={12} xs={12} md={12} lg={12}>
                        <div className="form-control">
                          <label>
                            Other <span className="star">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="How did you find us"
                            name="other_find_us"
                            value={formData.other_find_us}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                  <div>
                    <Row>
                      <label className="bold-text">
                        Select Your Industry <span className="star"> *</span>
                      </label>
                      <div className="select-industry">
                        <label className="mr-20">
                          <input
                            type="radio"
                            name="industry"
                            value="cafe"
                            onChange={() => setIndustry("CE")}
                          />
                          Cafe
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="industry"
                            value="trade_food_services"
                            onChange={() => setIndustry("FS_TS")}
                          />
                          Trade/Foodservices
                        </label>
                      </div>
                    </Row>

                    {industry === "CE" && (
                      <div>
                        <Row>
                          <label>Operational Status</label>
                          <br />
                          <div className="status-block">
                            <label
                              className={`label-for-radio-btn mr-20${selectedOptions.open || selectedOptions.exiting
                                ? "disabled-label"
                                : ""
                                }`}
                              htmlFor="planning"
                            >
                              <input
                                type="checkbox"
                                id="planning"
                                name="confirmation_status"
                                value="planning"
                                onChange={handleChangeStatus}
                                className="radio-btn"
                                checked={selectedOptions.planning}
                                disabled={
                                  selectedOptions.open || selectedOptions.exiting
                                }
                              />
                              Planning to open
                            </label>
                            <label
                              className={`label-for-radio-btn mr-20 ${selectedOptions.planning ? "disabled-label" : ""
                                }`}
                              htmlFor="open"
                            >
                              <input
                                type="checkbox"
                                id="open"
                                name="confirmation_status"
                                value="open"
                                onChange={handleChangeStatus}
                                className="radio-btn"
                                checked={selectedOptions.open}
                                disabled={selectedOptions.planning}
                              />
                              Open for business
                            </label>
                            <label
                              className={`label-for-radio-btn mr-20 ${selectedOptions.planning ? "disabled-label" : ""
                                }`}
                              htmlFor="exiting"
                            >
                              <input
                                type="checkbox"
                                id="exiting"
                                name="confirmation_status"
                                value="exiting"
                                onChange={handleChangeStatus}
                                className="radio-btn"
                                checked={selectedOptions.exiting}
                                disabled={selectedOptions.planning}
                              />
                              Looking to exit
                            </label>
                            {errors.confirmation_status && (
                              <p className="error">
                                {errors.confirmation_status}
                              </p>
                            )}
                          </div>
                        </Row>

                        <Row>
                          <label className="mt-20">
                            What Interests You
                            <span className="star"> *</span>
                          </label>

                          <Row className="mt-10">
                            <Col sm={12} xs={12} md={6} lg={6} className="d-flex">
                              <input
                                type="checkbox"
                                className="checkbox"
                                value="free_menu_reviewing"
                                checked={formData?.interests?.includes("free_menu_reviewing")} // Check if the value is in the array
                                onChange={handleCheckboxInterestChange}
                              />
                              <label className="form-check-label" for="checkbox1">
                                Free Menu Review & Listing
                              </label>
                            </Col>

                            <Col sm={12} xs={12} md={6} lg={6} className="d-flex">
                              <input
                                type="checkbox"
                                className="checkbox"
                                value="education_mentorship"
                                checked={formData?.interests?.includes("education_mentorship")} // Check if the value is in the array
                                onChange={handleCheckboxInterestChange}
                              />
                              <label className="form-check-label" for="checkbox1">
                                Education & Mentorship
                              </label>
                            </Col>

                            <Col sm={12} xs={12} md={6} lg={6} className="d-flex">
                              <input
                                type="checkbox"
                                className="checkbox"
                                value="Networking"
                                checked={formData?.interests?.includes("Networking")} // Check if the value is in the array
                                onChange={handleCheckboxInterestChange}
                              />
                              <label className="form-check-label" for="checkbox1">
                                Networking & Exposure
                              </label>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} className="d-flex">
                              <input
                                type="checkbox"
                                className="checkbox"
                                value="event_program"
                                checked={formData?.interests?.includes("event_program")}
                                onChange={handleCheckboxInterestChange}
                                disabled={fromLandingPopup}
                              />
                              <label className="form-check-label" for="checkbox1">

                                Event/Program Participation
                              </label>
                            </Col>
                          </Row>
                        </Row>

                        <Row>
                          <Col sm={12} xs={12} md={12} lg={12}>
                            <div className="form-control">
                              <label>Anything Else?</label>
                              <input
                                type="text"
                                placeholder="Enter details"
                                value={anythingElse}
                                onChange={(e) => setAnythingElse(e.target.value)}
                                onKeyPress={handleKeyPressAllowschar}
                              />
                            </div>
                          </Col>
                          <Col sm={12} xs={12} md={12} lg={12}>
                            <div className="form-control">
                              <label>Enter Partner Code (if applicable)</label>

                              <input
                                type="text"
                                placeholder="Enter Partner Code"
                                value={partnerCode}
                                onChange={(e) => setPartnerCode(e.target.value)}
                                onKeyPress={handleKeyPressAllowschar}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}

                    {industry === "FS_TS" && (
                      <div>
                        <Row>
                          <label className="mt-20">
                            What Interests You
                            <span className="star"> *</span>
                          </label>

                          <Row className="mt-10">
                            <Col sm={12} xs={12} md={6} lg={6} className="d-flex">
                              <input
                                type="checkbox"
                                className="checkbox"
                                value="business_showcase"

                                checked={formData?.interests?.includes("business_showcase")}
                                onChange={handleCheckboxInterestChange}
                              />
                              <label class="form-check-label" for="checkbox1">
                                Business Showcase
                              </label>
                            </Col>
                            <Col sm={12} xs={12} md={6} lg={6} className="d-flex">
                              <input
                                type="checkbox"
                                className="checkbox"
                                value="networking_exposure"
                                checked={formData?.interests?.includes("networking_exposure")}
                                onChange={handleCheckboxInterestChange}
                              />
                              <label class="form-check-label" for="checkbox1">
                                Networking & Exposure
                              </label>
                            </Col>

                            <Col sm={12} xs={12} md={6} lg={6} className="d-flex">
                              <input
                                type="checkbox"
                                className="checkbox"
                                value="collaboration_opportunities"

                                checked={formData?.interests?.includes("collaboration_opportunities")}
                                onChange={handleCheckboxInterestChange}
                              />
                              <label class="form-check-label" for="checkbox1">
                                Collaboration Opportunities
                              </label>
                            </Col>

                            <Col sm={12} xs={12} md={6} lg={6} className="d-flex">
                              <input
                                type="checkbox"
                                className="checkbox"
                                value="event_program"
                                checked={formData?.interests?.includes("event_program")}
                                disabled={fromLandingPopup}
                                onChange={handleCheckboxInterestChange}
                              />
                              <label class="form-check-label" for="checkbox1">
                                Event/Program Participation
                              </label>
                            </Col>
                          </Row>
                        </Row>

                        <Row>
                          <Col sm={12} xs={12} md={12} lg={12}>
                            <div className="form-control">
                              <label>Anything Else?</label>
                              <input
                                type="text"
                                placeholder="Enter details"
                                value={anythingElse}
                                onChange={(e) => setAnythingElse(e.target.value)}
                                maxLength={100}
                                onKeyPress={handleKeyPressAllowschar}
                              />
                            </div>
                          </Col>
                          <Col sm={12} xs={12} md={12} lg={12}>
                            <div className="form-control">
                              <label>Enter Partner Code (if applicable)</label>

                              <input
                                type="text"
                                placeholder="Enter Partner Code"
                                value={partnerCode}
                                onChange={(e) => setPartnerCode(e.target.value)}
                                maxLength={50}
                                onKeyPress={handleKeyPressAllowschar}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                  <Row className="mt-20">
                    <Col xs={12} lg={12} md={12} sm={12}>
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={handleCaptcha}
                        ref={recaptchaRef}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} xs={12} md={12} lg={12}>
                      <div className="mt-20">
                        <label htmlFor="">
                          Specific Questions or Requests{" "}
                          <span className="star">*</span>
                        </label>{" "}
                        <textarea
                          rows={5}
                          placeholder="type your message..."
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>{" "}
                        {errors.message && (
                          <p className="error">{errors.message}</p>
                        )}
                      </div>
                    </Col>
                  </Row>


                  <button
                    type="submit"
                    className={`send-msg-btn mt-20 ${formValid ? "btn-primary-valid" : ""
                      }`}
                  >
                    Connect With Us
                  </button>
                  {isError && (
                    <p style={{ color: "red" }} className="mt-20">
                      Error: {error?.data?.error || "Failed to submit form"}
                    </p>
                  )}
                  {isSuccess && (
                    <p style={{ color: "green" }} className="isSuceesMsg">
                      Form submitted successfully!
                    </p>
                  )}
                  {isLoading && <div class="loader"></div>}
                </form>
              </div>
            </Col>
          </Row>
        </section>
        <section className="connect-us section">
          {/* test */}
          <Row>
            <Col sm={12} xs={12} md={6} lg={6}>
              <h4>Connect with Us:</h4>
              <p>
                Stay updated with our latest news and updates by following us on
                social media:
              </p>
              <div className="d-flex">
                <a href="https://www.facebook.com/clevercafeau/ " target="_blank">
                  <img src={Facebook} alt="icon" className="connect-img" />
                </a>
                <a
                  href="https://www.linkedin.com/company/clevercafecompany/"
                  target="_blank"
                >
                  {" "}
                  <img src={Linkdin} alt="icon" className="connect-img" />
                </a>
                <a
                  href=" https://www.instagram.com/clevercafeau/ "
                  target="_blank"
                >
                  {" "}
                  <img src={Instagram} alt="icon" className="connect-img" />
                </a>
                <a href="https://www.youtube.com/@clevercafeau " target="_blank">
                  {" "}
                  <img src={Youtube} alt="icon" className="connect-img" />
                </a>
              </div>
            </Col>
            <Col sm={12} xs={12} md={6} lg={6}>
              <h4>Need Support?</h4>
              <p>
                For members needing support, please contact us through your
                account portal. If you have issues logging a ticket, please send
                us a message, and we will assist you directly.
              </p>
              <h4>Office Hours:</h4>
              <p className="mb-0 yellow-txt">
                Monday to Friday: 8:00 AM - 6:00 PM
              </p>
              <p className="yellow-txt">Saturday & Sunday: Closed</p>
            </Col>
          </Row>
          <Row>
            <p className="text-center btm-text">
              At Clever Cafe, your success is our priority. We look forward to
              connecting with you and helping your business thrive.
              {/* <span className="disply-blk">business thrive.</span> */}
            </p>
          </Row>
        </section>
      </div>
    </>

  );
}
