import React, { useState, useEffect, useRef } from "react";
import "../Styles/HomeAbout.css";
import Leftbg from "../Assets/Images/about-left-img.svg";
import RightImg1 from "../Assets/Images/about-right-1.svg";
import RightImg2 from "../Assets/Images/about-right-2.svg";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SeviceArrow from "../Assets/Images/service-arrow.svg";
import { Helmet } from 'react-helmet';

function HomeAbout() {
  const cardRefs = useRef([]);
  const sectionRef = useRef(null);
  const [visibleCards, setVisibleCards] = useState([]);
  const [buttonMoved, setButtonMoved] = useState(false);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       const entry = entries[0];
  //       if (entry.isIntersecting) {
  //         setButtonMoved(true);
  //       } else {
  //         setButtonMoved(false);
  //       }
  //     },
  //     { threshold: 0.3 }
  //   );

  //   if (sectionRef.current) {
  //     observer.observe(sectionRef.current);
  //   }

  //   return () => {
  //     if (sectionRef.current) {
  //       observer.unobserve(sectionRef.current);
  //     }
  //   };
  // }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Trigger only when the element reaches the visible threshold
          if (entry.isIntersecting) {
            setButtonMoved(true);
          } else {
            setButtonMoved(false);
          }
        });
      },
      { threshold: 0.3 } // Only trigger when 30% of the element is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    // Clean up the observer on unmount
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          
          if (entry.isIntersecting) {
            setVisibleCards((prev) => [...prev, entry.target.dataset.index]);
          }
        });
      },
      { threshold: 0.3 }
    );

    cardRefs.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => {
      cardRefs.current.forEach((card) => {
        if (card) observer.unobserve(card);
      });
    };
  }, []);

  return (
    <>
      {/* <Helmet>
    <title>Clever Cafe Services | Cafe Business Planning, Foodservice Solutions & Trade Support</title>
    <meta name="description" content="Providing comprehensive membership support for cafes at all stages. Collaborate with industry professionals, trades, and foodservice experts." />
    <meta name="keywords" content="cafe business planning in australia, starting a cafe business in australia, australian cafe entrepreneurship, australian cafe entrepreneurship ideas, how to run a successful cafe" />
  </Helmet> */}
  <section className="section home-about" ref={sectionRef}>
      <div className="content-div">
        <div className="about-main-head-desk">
          <h3>Want Happy Customers, Menus That </h3>
          <h3>Keep Them Coming Back, and a</h3>
          <h3>Motivated, Well-Trained Crew?</h3>
        </div>
        <div className="about-main-head-mob">
          <h3>Want Happy Customers, Menus That Keep Them Coming Back, and a Motivated, Well-Trained Crew?</h3>
        </div>

        <p>
          With decades of hands-on experience, we are the team behind the scenes
          that some of the most
          <span>
            {" "}
            successful cafes rely on to thrive. We simplify running a profitable
            cafe with loyal customers.
          </span>
        </p>

        <h4>
          Business Plans. Menu Development. Networking. Mentorship. Systems.
        </h4>
        {/* <Button className="btn_primary animate-btn1">Trades & services</Button> */}
        <div className="service-rep-btn">
          <Button
            className={`btn_primary animate-btn1 ${buttonMoved ? "move-to-bottom" : ""
              }`}
          >
            Trades & services
          </Button>
          <Button
            className={`btn_primary animate-btn2 ${buttonMoved ? "move-to-bottom" : ""
              }`}
          >
            Cafe Entrepreneurship
          </Button>

          <Button
            className={`btn_primary animate-btn3 ${buttonMoved ? "move-to-bottom" : ""
              }`}
          >
            Foodservice
          </Button>
        </div>
        {/* <Button className="btn_primary animate-btn3">Foodservice</Button> */}
      </div>
      <Row className="card-div">
        {[
          {
            subtitle: "I am a",
            title: (
              <>
                Foodservi<span className="foodservice-head">c</span>e company
              </>
            ),
            text: "We’ll match you with informed business owners who suit your offering and location.",
            link: "/service-food",
          },
          {
            subtitle: "I am a",
            title: (
              <>
                <span className="cafe-head">C</span>afe Entrepreneur
              </>
            ),
            text: "We’ll give you tailored advice and matchmade industry connections, saving you precious time and money.",
            link: "/service-cafe-startup",
          },
          {
            subtitle: "I am a",
            title: (
              <>
                Trade or Servi<span className="trade-service-head">c</span>e
                provider
              </>
            ),
            text: "We’ll match you directly with customers at the right time, who know what they need.",
            link: "/service-trade",
          },
        ].map((card, index) => (
          <Col key={index} xs={12} sm={12} md={4} lg={4} className="card-col">
         <Link to={card.link}>  <div
              className={`card card${index + 1} ${visibleCards.includes(`${index}`) ? "visible" : ""
                }`}
              ref={(el) => (cardRefs.current[index] = el)}
              data-index={index}
            >
              <div className="card-content">
                <h6 className="iam-a-text">{card.subtitle}</h6>
                <h5>{card.title}</h5>
                <p>{card.text}</p>
                <Link to={card.link} className="lintko-service">
                  Services <img src={SeviceArrow} alt="arrow" />
                </Link>
              </div>
            </div></Link> 
          </Col>
        ))}
      </Row>
      <img src={Leftbg} className="about-img-left" />
      <img src={RightImg1} className="about-img-right1" />
      <img src={RightImg2} className="about-img-right2" />
    </section>
    </>

  );
}

export default HomeAbout;
