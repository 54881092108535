import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../Styles/DirectoryPayment.css";
import {
  useGetCompletedBranchesQuery,
  useGetInCompletedBranchesQuery,
} from "../../../Services/TradeserviceAPI";
import Swal from 'sweetalert2';
import { useGettsAmountQuery } from "../../../Services/CommonAPI"
import { Link } from "react-router-dom";
function DashboardDirectoryListing() {
  const navigate = useNavigate();

  const { data: proamount } = useGettsAmountQuery();
  const [proprice, setProprice] = useState(0);
  useEffect(() => {
    if (proamount) {
      setProprice(proamount?.amount)

    }

  }, [proamount])

  // Initial state setup
  const locations = [
    {
      id: 1,
      name: "Victoria",
      address: "Mrs Smith 813 Howard Street Oswego, NY 13126 USA",
      payment: 47,
    },
    {
      id: 2,
      name: "Queensland",
      address: "Mrs Smith 813 Howard Street Oswego, NY 13126 USA",
      payment: 47,
    },
  ];

  const [selectedLocations, setSelectedLocations] = useState([]);

  const {
    data: unlistedLocations,
    isLoading,
    refetch: refetchinCompletedLOcationData,
  } = useGetInCompletedBranchesQuery();

  const {
    data: completedbranch,
    isLoading: isLoadingCompletedData,
    refetch: refetchCompletedLOcationData,
  } = useGetCompletedBranchesQuery();

  const handleCheckboxChange = (id, isPro) => {
    setSelectedLocations((prevSelected) => {
      // Find the location to be selected
      const location = unlistedLocations?.find((loc) => loc.id === id);

      // Deselect all other locations and select the new one
      return [
        {
          id,
          location: location?.location,
          address: location?.address,
          operational_status: location?.operational_status,
          payment: proprice,

        },
      ];
    });
  };

  const handlePaymentOptionChange = (id, isPro) => {
    setSelectedLocations((prevSelected) => {
      return prevSelected.map((loc) =>
        loc.id === id ? { ...loc, payment: isPro ? proprice : 0 } : loc
      );
    });
  };

  const calculateTotal = () => {
    return selectedLocations.reduce((sum, loc) => sum + loc.payment, 0);
  };

  const total = calculateTotal();
  const gst = total * 0.1;
  const subtotal = total + gst;

  const handleCompletePayment = () => {
    if (selectedLocations.length > 0) {
      const locations = selectedLocations;

      navigate("/trade-service-directory-payment-list", {
        state: {
          locations: locations,
          total: total,
          subtotal: subtotal,
          gst: gst
        },
      });
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Please choose a branch',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
  };

  useEffect(() => {
    refetchinCompletedLOcationData();
  }, [])
  return (
    <div className="dashboard-listing td-directory-payment">
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Payments & Invoicing</h4>
          <p className="sub-head">
            View past payments and invoices.
          </p>
          <p className="required-message-impoertant">Listings will not appear in the ecosystem until the business name and location address are correctly entered.</p>
        </Col>
      </Row>

      <div className="list-card">
        {unlistedLocations?.map((location) => (
          <div className="card" key={location.id}>
            <Row>
              <Col xs={12} sm={12} md={2} lg={2}>
                <div className="d-flex">
                  <div className="mr-proprice">
                    <input
                      type="radio"
                      onChange={() =>
                        handleCheckboxChange(
                          location.id,
                          selectedLocations.some(
                            (loc) =>
                              loc.id === location.id && loc.payment === proprice
                          )
                        )
                      }
                      checked={selectedLocations.some(
                        (loc) => loc.id === location.id
                      )}
                    />
                  </div>
                  <div>
                    <p className="location-head">
                      {" "}
                      {location.location ?? "Location"}{" "}
                    </p>
                    <p className="address">{location.address}</p>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4} className="text-end">
                <p className="payment-method">
                  <b>${proprice}</b>/ month
                </p>
              </Col>
            </Row>
          </div>
        ))}
      </div>

      <div className="payment-complete-box">
        <div>
          <p className="selected-loc">
            Selected <button>{selectedLocations.length} branches</button>
          </p>
        </div>
        <div className="d-flex align-space-btwn">
          <p className="head">Total</p>
          <p className="value">${total.toFixed(2)}</p>
        </div>
        <div className="d-flex align-space-btwn">
          <p className="head">GST (10%)</p>
          <p className="value">${gst.toFixed(2)}</p>
        </div>
        <hr />
        <div className="d-flex align-space-btwn">
          <p className="head">Subtotal</p>
          <p className="value">${subtotal.toFixed(2)}</p>
        </div>
        <Row className="action-btn">
          <Col xs={12} sm={12} lg={12} md={12} className="text-end">
            <Link to="/trade-service-directorypagelisting"> <button type="button" className="prev-btn">
              Cancel
            </button></Link>
            <button
              type="submit"
              className="btn-save-primary"
              onClick={handleCompletePayment}
            >
              Complete Payment
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default DashboardDirectoryListing;
