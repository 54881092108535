import { useEffect , useState} from "react";
import "../Styles/Article.css";
import { Row, Col } from "react-bootstrap";
import Blog1Img from "../Assets/Images/how-clever-cafe-incubator-helps.png";
import RightArrow from "../Assets/Images/right-arrow.svg";
import Blog1 from "../Assets/Images/blog-1.png";
import Blog2 from "../Assets/Images/blog-2.png";
import Article1 from "../Assets/Images/article-1.png";
import Article2 from "../Assets/Images/article-2.png";
import Article3 from "../Assets/Images/article-3.png";
import { Link } from "react-router-dom";
import SearchIcon from "../Assets/Images/search-icon.svg"
import { useGetArticleDataQuery, useGetRecentArticleDataQuery } from "../Services/ArticleAPI";
import { Helmet } from 'react-helmet';
import PageLoader from "../Assets/Images/page-loader.gif"

function Article() {
  const { data: article, isLoading, error } = useGetArticleDataQuery();
  const { data: recent } = useGetRecentArticleDataQuery();
  // const firstArticle = Array.isArray(recent) ? recent[0] : null;

  const [searchTerm, setSearchTerm] = useState("");

  // Filter articles based on search term
  const filteredArticles = Array.isArray(article)
    ? article.filter((article) =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

    const filteredRecent = Array.isArray(recent)
    ? recent.filter((article) =>
        article.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];
    const firstArticle = filteredRecent.length > 0 ? filteredRecent[0] : null;

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  if (isLoading) {
    // Render a loader if the data is still loading
    return (
      <div className="page-loader">
        <img src={PageLoader} alt="PageLoader" width={100} />
      </div>
    );
  }


  // Render loading or error states
  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error fetching articles</div>;

  return (
    <>
      <Helmet>
    <title>Clever Cafe | Articles - Cafe & Hospitality Industry Insights & Tips</title>
    <meta name="keywords" content="Cafe & Hospitality Industry Insights & Tips, hospitality industry trends, Coffee Shop Industry Trends, australian cafe entrepreneurship" />
    <meta name="description" content="Browse our expert articles for actionable advice, industry trends, and essential tips to enhance your cafe operations and profitability." />
  </Helmet>
  <div className="article">
      <section className="section article-banner">
        <div className="article-banner-content">
          <h2>Articles</h2>
          <p>Curated Insights & Resources for Cafe Aficionados</p>
        </div>
      </section>
      <section className="section recent-blog-posts">
        <Row>
          <Col sm={12} md={9} lg={9} xs={12}>
            <h4 className="recent-blog-posts-head">Recent articles</h4>
          </Col>
          <Col sm={12} md={3} lg={3} xs={12} className="text-end">
        <div className="search-box">
          <input
            type="text"
            name="search"
            placeholder="search"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <img src={SearchIcon} alt="SearchIcon" className="search-icon" />
        </div>
      </Col>
        </Row>

        <hr />
        <Row>
  {firstArticle ? (
    <Col sm={12} xs={12} lg={6} md={6}>
      <Link to={`/article-detail/${firstArticle.id}`}>
        <div className="blog-card">
          <div className="card-image">
            <img src={firstArticle?.cover_image} alt="blog-img" />
          </div>
          <div className="card-body">
            <p className="publish-date">
              {firstArticle?.category_name} • {firstArticle?.date}
            </p>
            <img src={RightArrow} alt="arrow" className="right-arrow" />
            <h3>{firstArticle?.title}</h3>
            <p>{firstArticle.description.slice(0, 200)}...</p>
          </div>
        </div>
      </Link>
    </Col>
  ) : (
    <Col sm={12} xs={12} lg={6} md={6}>
      <p>No articles found</p>
    </Col>
  )}

  <Col sm={12} xs={12} lg={6} md={6} className="old-blog-post">
    {Array.isArray(filteredRecent) && filteredRecent.length > 0 ? (
      filteredRecent.slice(1).map((recent, index) => (
        <Link to={`/article-detail/${recent.id}`} key={index}>
          <Row className="old-blog-card">
            <Col sm={12} xs={12} lg={6} md={6}>
              <div className="card-image">
                <img src={recent?.cover_image} alt="blog-img" />
              </div>
            </Col>
            <Col sm={12} xs={12} lg={6} md={6}>
              <div className="card-body">
                <p className="publish-date">
                  <span className="clr-blue">{recent?.category_name}</span> • {recent?.date}
                </p>
                <Link to={`/article-detail/${recent.id}`}>
                  <img src={RightArrow} alt="arrow" className="right-arrow" />
                </Link>
                <h3>{recent?.title}</h3>
                <p>{recent.description.slice(0, 80)}...</p>
              </div>
            </Col>
          </Row>
        </Link>
      ))
    ) : (
      <p>No articles found</p>
    )}
  </Col>
</Row>

      </section>
      <section className="all-articles">
        <h3>All Articles</h3>
        <hr />
        
        <Row className="all-article-blogs">
  {Array.isArray(filteredArticles) && filteredArticles.length > 0 ? (
    filteredArticles.map((article) => (
      <Col
        sm={12}
        xs={12}
        lg={4}
        md={4}
        className="all-article-blogs-card-div"
        key={article.id}
      >
        <Link to={`/article-detail/${article.id}`}>
          <div className="blog-card">
            <div className="card-image">
              <img src={article.cover_image} alt="blog-img" />
            </div>
            <div className="card-body">
              <p className="publish-date">
                {article.category_name} • {article.date}
              </p>
              <Link to={`/article-detail/${article.id}`}>
                <img src={RightArrow} alt="arrow" className="right-arrow" />
              </Link>
              <h4>{article.title}</h4>
              <p>{article.description.slice(0, 200)}...</p>
            </div>
          </div>
        </Link>
      </Col>
    ))
  ) : (
    <Col sm={12} xs={12} lg={12} md={12}>
      <p>No articles found</p>
    </Col>
  )}
</Row>

      </section>
    </div>
    </>
   
  );
}

export default Article;