// Services/TradeserviceAPI.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASEURL_SERVER;

export const tradeserviceapi = createApi({
  reducerPath: 'tradeserviceapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('accessToken');
     
      
      const noAuthEndpoints = ['submitForm', 'verifyRegisterOTP', 'resendRegisterOTP', 'submitLogin'];
      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    submitForm: builder.mutation({
      query: (newPost) => ({
        url: 'auth/trade-service/register/',
        method: 'POST',
        body: newPost,
      }),
    }),

    verifyRegisterOTP: builder.mutation({
      query: ({Postata, id}) => ({
        url: `auth/ts-verify-otp/${id}/`,
        method: 'PATCH',
        body: Postata,
      }),
    }),
    
    resendRegisterOTP: builder.mutation({
      query: ({id}) => ({
        url: `auth/resend-otp/${id}/`,
        method: 'PATCH',
      }),
    }),

    registerPaymentSubmit: builder.mutation({
      query: (postdata) => ({
        url: 'stripe/ts-subscription/create/',
        method: 'POST',
        body: postdata,
      }),
    }),

    getAccountHolderData: builder.query({
      query: () => 'auth/ts-user/get/edit/',
    }),


    submitAccountHolderData: builder.mutation({
      query: (postdata) => ({
        url: `auth/ts-user/get/edit/`,
        method: 'PATCH',
        body: postdata,
      }),
    }),

    saveBranches: builder.mutation({
      query: (postdata) => ({
        url: `trade/branches/`,
        method: 'POST',
        body: postdata,
      }),
    }),

    saveMultipleBranches: builder.mutation({
      query: (postdata) => ({
        url: `trade/branch/miltple/create/`,
        method: 'POST',
        body: postdata,
      }),
    }),


    branchEditWithBranchId :builder.mutation({
      query: ({postdata, branchId}) => ({
        url: `trade/branches/${branchId}/`,
        method: 'PATCH',
        body: postdata,
      }),
    }),

    getBranchDatawithAccountHolderId: builder.query({
      query: (id) => `trade/branches/${id}/`,
    }),
    addMultipleBranch:builder.mutation({
      query: (postData) => ({
        url: `/trade/branch/miltple/create/`,
        method: 'POST',
        body: postData,
      }),
    }),
    additionalDetailsEditWithAccountHolderId :builder.mutation({
      query: ({postData, branchId}) => ({
        url: `trade/additional-detail/branch/${branchId}/`,
        method: 'PATCH',
        body: postData,
      }),
    }),

    additionalDetailsAddWithAccountHolderId :builder.mutation({
      query: ({postData, branchId}) => ({
        url: `trade/additional-detail/branch/${branchId}/`,
        method: 'POST',
        body: postData,
      }),
    }),


    getadditionalDetailswithAccountHolderId: builder.query({
      query: (id) => `trade/additional-detail/branch/${id}/`,
    }),

    getFeatures:builder.query({
      query: () => "cafe/features/",
    }),
    getMenuHighlihts:builder.query({
      query: () => "cafe/menu-highlights/",
    }),
    getSpecialistDietryService:builder.query({
      query: () => "cafe/menu-highlights/",
    }),
    getAmbience:builder.query({
      query: () => "cafe/ambiences/",
    }),
    getTechStack:builder.query({
      query: () => "/cafe/tech-stacks/",
    }),
    getServiceType:builder.query({
      query: () => "food/service-categories/",
    }),
    getProductType:builder.query({
      query: () => "/food/product-types/",
    }),
    getfoodDeietryService:builder.query({
      query: () => "/food/specialist-dietary-services/",
    }),
   getServiceCategory:builder.query({
    query: () => "/trade/service-category/list/check/",
  }),
  uploadMediaFiles:builder.mutation({
    query: ({formData,branchId}) => ({
      url: `trade/branch/${branchId}/media-gallery/`,
      method: 'POST',
      body: formData,
    }),
  }),

  getMediaGallery:builder.query({
    query: (branchId) => `trade/branch/${branchId}/media-gallery/`,
  }),
  submitEcosystemCrietirabyBranchid :builder.mutation({
    query: ({postData, branchId}) => ({
      url: `trade/ecosystem-criteria/branch/${branchId}/`,
      method: 'POST',
      body: postData,
    }),
  }),

  submitEditEcosystemCrietirabyBranchid :builder.mutation({
    query: ({postData, branchId}) => ({
      url: `trade/ecosystem-criteria/branch/${branchId}/`,
      method: 'PATCH',
      body: postData,
    }),
  }),

  getEcosystemDetailswithAccountHolderId: builder.query({
    query: (id) => `trade/ecosystem-criteria/branch/${id}/`,
  }),

  getCompletedBranches:builder.query({
    query: () => "trade/completed/branches/",
  }),
  getInCompletedBranches:builder.query({
    query: () => "trade/unlisted/branches/",
  }),

  subcsriptionMultipleCreate: builder.mutation({
    query: (postdata) => ({
      url: 'stripe/ts-subscription/multiple/create/',
      method: 'POST',
      body: postdata,
    }),
  }),

  accountChangePassword: builder.mutation({
    query: (postdata) => ({
      url: 'auth/ts-change-password/',
      method: 'POST',
      body: postdata,
    }),
  }),
  
  accountChangeProfile: builder.mutation({
    query: (postdata) => ({
      url: 'auth/ts-profile-picture/update/',
      method: 'PATCH',
      body: postdata,
    }),
  }),
  
  accountDelete :builder.mutation({
    query: () => ({
      url: 'auth/ts-user/delete/with-subscription-cancel/',
      method: 'DELETE',
    }),
  }),
  getBranchList:builder.query({
    query: () => `trade/branches/`,
  }),
  verifyTradeContactNumber: builder.mutation({
    query: (postdata) => ({
      url: 'auth/send-sms-otp/',
      method: 'PATCH',
      body: postdata,
    }),
  }),
  
  verifysmsotpTrade: builder.mutation({
    query: (postdata) => ({
      url: 'auth/verify-sms-otp/',
      method: 'PATCH',
      body: postdata,
    }),
  }),
  userGetEdit: builder.query({
    query: () => `auth/ts-user/get/edit/`,
  }),

  userGetEditTs: builder.query({
    query: () => `auth/ts-user/get/edit/`,
  }),

  submitLogin: builder.mutation({
    query: (postdata) => ({
      url: `auth/ts-user/login/`,
      method: 'POST',
      body: postdata,
    }),
  }),
  getAllBranchDetails : builder.query({
    query: ({branchId}) => `trade/branch/${branchId}/detail/`,
  }),
  branchDelete :builder.mutation({
    query: (id) => ({
      url: `trade/branch/${id}/delete/with-subscription-cancel/`,
      method: 'DELETE',
    }),
  }),

  getEcosystemTradeSerice: builder.query({
    query: ({ id, features = [], states = [], menu_highlights=[],ambience=[],dietryCategory=[],recommendation = '', ordering = '' , delivery_range='', operationalStatus=[]}) => {
      const searchParams = new URLSearchParams();
      features.forEach(category => searchParams.append('features', category));
      menu_highlights.forEach(category => searchParams.append('menu_highlights', category));
      operationalStatus.forEach(operationalStatus => searchParams.append('operational_status', operationalStatus));
      states.forEach(state => searchParams.append('states', state));
      if (ordering) {
        searchParams.append('ordering', ordering);
      }
      if (recommendation) {
        searchParams.append('recommendation', recommendation);
      }
      // if (delivery_range) {
      //   searchParams.append('delivery_range', delivery_range);
      // }
      return `trade/ecosystem-criteria/ce/matches/${id}/?${searchParams.toString()}`;
    }
  }),

  tradeserviceLike: builder.mutation({
    query: ({postdata, branchId}) => ({
      url: `trade/ce-branch/like/toggle/${branchId}/`,
      method: 'POST',
      body: postdata,
    }),
  }),

  

  FlagbranchAddWithAccountHolderId :builder.mutation({
    query: (postdata) => ({
      url: `trade/branches/`,
      method: 'POST',
      body: postdata,
    }),
  }),

getFlagshipBranches : builder.query({
query: () => `trade/headquarter-branches/`,
}),


editFlagshipBranch :builder.mutation({
query: ({flagshipData, flagshipid}) => ({
  url: `trade/branches/${flagshipid}/`,
  method: 'PATCH',
  body: flagshipData,
}),
}),

editMultipleBrancg :builder.mutation({
  query: ({postdata}) => ({
    url: `trade/branch/miltple/create/`,
    method: 'PATCH',
    body: postdata,
  }),
}),

tsacceptcafe :builder.mutation({
  query: ({postdata, currentbranchbranchId}) => ({
    url: `trade/ce-branch-decision/toggle/${currentbranchbranchId}/`,
    method: 'POST',
    body: postdata,
  }),
}),
getpreviewbranches: builder.query({
  query: () => `trade/branch/lt/lg/`,
}),
DeclinedList: builder.query({
  query: (branchId) => `trade/branches/${branchId}/declined-ce-branches/`,
}),
getEcoenquirylist:builder.query({
  query: (CurrentBranch) => `enquiry/conversations/trade_service_branch/${CurrentBranch}/`,
}),
NotificationMarkAllRead :builder.mutation({
  query: () => ({
    url: `trade/portal-notifications/mark-all-read/`,
    method: 'POST',
  }),
}),

freeTrialRegister: builder.mutation({
  query: (newPost) => ({
    url: 'auth/trade-service/free-trial/register/',
    method: 'POST',
    body: newPost,
  }),
}),

freeTrialverifyRegisterOTP: builder.mutation({
  query: ({Postata, id}) => ({
    url: `auth/ts/free-trial/verify-otp/${id}/`,
    method: 'PATCH',
    body: Postata,
  }),
}),

emailNotificationStatus: builder.mutation({
  query: ({Postata}) => ({
    url: `trade/email-notification-status/get/edit/`,
    method: 'PATCH',
    body: Postata,
  }),
}),

getEmailNotificationStatus: builder.query({
  query: () => `trade/email-notification-status/get/edit/`,
}),

getAllBranchDetailsForallUsers : builder.query({
  query: ({branchId}) => `trade/branch/${branchId}/detail/for-all-authed-users/`,
}),

getTsSubscriptionList : builder.query({
  query: () => `stripe/ts-subscriptions/list/`,
}),

getTsInvoice: builder.query({
  query: (recordId) => `stripe/ts-subscription/${recordId}/invoice/`,
}),

SubmitStarred : builder.mutation({
  query: (Postata) => ({
    url: `enquiry/favorite/toggle/`,
    method: 'POST',
    body: Postata,
  }),
}),
getStareedMessages :builder.query({
  query: (branchId) => `enquiry/favorites/trade_service_branch/${branchId}/`,
}),
getpreviewbranchesMatches: builder.query({
  query: (id) => `trade/branch/${id}/lt/lg/`,
}),
getMediaGallerymatchpreview:builder.query({
  query: (branchId) => `trade/branch/${branchId}/media-gallery/approved/list/`,
}),

  }),
});


export const {
  useSubmitFormMutation,
  useVerifyRegisterOTPMutation,
  useResendRegisterOTPMutation,
  useRegisterPaymentSubmitMutation,
  useGetAccountHolderDataQuery,
  useSubmitAccountHolderDataMutation,
  useSaveBranchesMutation,
  useBranchEditWithBranchIdMutation,
  useGetBranchDatawithAccountHolderIdQuery,
  useAddMultipleBranchMutation,
  useAdditionalDetailsEditWithAccountHolderIdMutation,
  useGetadditionalDetailswithAccountHolderIdQuery,
  useGetFeaturesQuery,
useGetMenuHighlihtsQuery,
useGetSpecialistDietryServiceQuery,
useGetAmbienceQuery,
useGetTechStackQuery,
useGetServiceTypeQuery,
useGetProductTypeQuery,
useGetfoodDeietryServiceQuery,
useGetStatesQuery,
useGetTradeServiceCategoryQuery,
useGetServiceCategoryQuery,
useUploadMediaFilesMutation,
useGetMediaGalleryQuery,
useAdditionalDetailsAddWithAccountHolderIdMutation,
useSubmitEditEcosystemCrietirabyBranchidMutation,
useSubmitEcosystemCrietirabyBranchidMutation,
useGetCompletedBranchesQuery,
useGetInCompletedBranchesQuery,
useSubcsriptionMultipleCreateMutation,
useAccountChangePasswordMutation,
useAccountChangeProfileMutation,
useAccountDeleteMutation,
useGetBranchListQuery,
useVerifyTradeContactNumberMutation,
useVerifysmsotpTradeMutation,
useUserGetEditQuery,
useSubmitLoginMutation,
useGetAllBranchDetailsQuery,
useBranchDeleteMutation,
useGetEcosystemTradeSericeQuery,
useTradeserviceLikeMutation,
useGetEcosystemDetailswithAccountHolderIdQuery,
useSaveMultipleBranchesMutation,
useEditFlagshipBranchMutation,
useFlagbranchAddWithAccountHolderIdMutation,
useGetFlagshipBranchesQuery,
useEditMultipleBrancgMutation,
useTsacceptcafeMutation,
useGetpreviewbranchesQuery,
useDeclinedListQuery,
useGetEcoenquirylistQuery,
useNotificationMarkAllReadMutation,
useFreeTrialRegisterMutation,
useFreeTrialverifyRegisterOTPMutation,
useEmailNotificationStatusMutation,
useGetEmailNotificationStatusQuery,
useGetAllBranchDetailsForallUsersQuery,
useUserGetEditTsQuery,
 useGetTsInvoiceQuery,
 useGetTsSubscriptionListQuery,
 useGetStareedMessagesQuery,
 useSubmitStarredMutation,
 useGetpreviewbranchesMatchesQuery,
 useGetMediaGallerymatchpreviewQuery
} = tradeserviceapi;
