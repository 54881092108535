// ModalComponent.jsx
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "../Styles/AddAnotherLocation.css";
import { Link, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../Styles/AddNewNotice.css"
import ArrowDownImg from "../Assets/Images/arrow-down-icon.svg"
import { useStoreNoticesMutation, useGetNoticeCategoryQuery } from "../Services/CommunityAPI"
import { useGetBranchListQuery } from "../Services/CcownerAPI"

const ModalComponent = ({ show, handleCloseAddNewModal, jobListingRefetch, announcementRefetch, NewsRefetch, MynoticeRefetch }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    job_title: "",
    directory_listing: "",
    category: "",
    description: "",
  });

  const { data: listedLocations } = useGetBranchListQuery();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useStoreNoticesMutation();
  const { data: noticecategory } = useGetNoticeCategoryQuery();

  const handlecloseModal = () => {
    handleCloseAddNewModal();
    setErrors({});
    setFormData({
      job_title: "",
      directory_listing: "",
      category: "",
      description: "",
    });
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const formErrors = {};
    if (!formData.job_title) formErrors.job_title = "Title is required.";
    if (!formData.directory_listing) formErrors.directory_listing = "Directory listing is required.";
    if (!formData.category) formErrors.category = "Category is required.";
    if (!formData.description) {
      formErrors.description = "Description is required.";
    } else {
      // Split the description into words and count them
      const wordCount = formData.description.trim().split(/\s+/).length;

      if (wordCount > 200) {
        formErrors.description = "Maximum allowed length is 200 words.";
      }
    }
    // }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };


  const handleSubmit = async (e) => {
    const accountHolderId = localStorage.getItem("accountHolderId");
    const subscription_type = localStorage.getItem("subscription_type_current_selected");

    e.preventDefault();
    if (validateForm()) {
      let postData = {
        title: formData.job_title,
        category_id: formData.category,
        directory_listing_id: formData.directory_listing,
        content: formData.description,
        user_id: accountHolderId,
        subscription_type: subscription_type
      }
      try {
        const response = await submitForm(postData).unwrap();
        setFormData({
          job_title: "",
          directory_listing: "",
          category: "",
          description: "",
        });

        const timer = setTimeout(() => {
          navigate("/notice-board");
          handleCloseAddNewModal();
        }, 1000);
        jobListingRefetch();
        announcementRefetch();
        NewsRefetch();
        MynoticeRefetch();
      } catch (err) {
        console.error("Failed to submit form:", err);
      }

    }
  };

  useEffect(() => {
    if (isSuccess) {
      setShowSuccessMessage(true);

      // Clear the success message after 3 seconds
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 1000);

      // Cleanup the timer on unmount
      return () => clearTimeout(timer);
    }

  }, [isSuccess])

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseAddNewModal}
        backdrop="true"
        dialogClassName="add-new-modal"
      >
        <Modal.Header>
          <button
            type="button"
            className="close-icon"
            aria-label="Close"
            onClick={handlecloseModal}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          <h2>Add New Notice</h2>
          <p className="sub-head">
            All submissions are reviewed and approved before being published.
          </p>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>
                    Title <span className="red-star">*</span>
                  </label><br />
                  <input placeholder="Enter title" name="job_title" onChange={handleChange} />
                  {errors.job_title && (
                    <p className="error">{errors.job_title}</p>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>
                    Category <span className="red-star">*</span>
                  </label><br />
                  <img src={ArrowDownImg} alt="ArrowDownImg" className="arrow-down" />
                  <select name="category" onChange={handleChange}>
                    <option value="" disabled selected>
                      Select your category
                    </option>
                    {noticecategory?.data?.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  {errors.category && (
                    <p className="error">{errors.category}</p>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>
                    Choose your listing <span className="red-star">*</span>
                  </label><br />
                  <img src={ArrowDownImg} alt="ArrowDownImg" className="arrow-down" />
                  <select name="directory_listing" onChange={handleChange}>
                    <option value="" disabled selected>Select your listing</option>
                    {listedLocations?.map((loc) => (<option value={loc.id}>{loc.location}</option>))}
                  </select>
                  {errors.directory_listing && (
                    <p className="error">{errors.directory_listing}</p>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>
                    Description <span className="red-star">*</span>
                  </label><br />
                  <textarea rows={4} placeholder="Add your description" onChange={handleChange} name="description">

                  </textarea>
                  <p className="max-word">Maximum of 200 words</p>
                  {errors.description && (
                    <p className="error">{errors.description}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="action-btn">
              <Col xs={12} sm={12} lg={12} md={12} className="text-end">
                <button
                  type="button"
                  className="prev-btn btn-64t35ne"
                  onClick={handlecloseModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-save-primary">
                  Add
                </button>
              </Col>
              {isError && (
                <p style={{ color: "red" }}>
                  Error: {error?.data?.error || "Failed to submit form"}
                </p>
              )}
              {isSuccess && showSuccessMessage && (
                <p style={{ color: "green" }} className="isSuceesMsg">
                  Notice  added successfully!
                </p>
              )}
              {isLoading && <div class="loader"></div>}
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComponent;
