import React, { useState, useCallback, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import FileUpload from "../../../Assets/Images/file-upload.svg";
import DropdownImg from "../../../Assets/Images/Dropdown-green.svg";
import MUltiSelectAdd from "../../../Assets/Images/Add-category-green.svg";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import AlertImg from "../../../Assets/Images/alert-icon-green.svg";
import SearchIcon from "../../../Assets/Images/search-icon.svg"
import DeleteList from "../../../Assets/Images/delete-branch-food-img.svg"
import {
  useAdditionalDetailsEditWithAccountHolderIdMutation,
  useGetadditionalDetailswithAccountHolderIdQuery,
  useGetServiceCategoryQuery,
  useGetServiceTypeQuery,
  useGetProductTypeFoodQuery,
  useGetDietryServiceFoodQuery,
  useGetServiceTypeFoodQuery,
  useAdditionalDetailsAddWithAccountHolderIdMutation,
  useGetfoodDeliveryPartnerQuery
} from "../../../Services/FoodserviceAPI";
import FacebookImg from "../../../Assets/Images/facebook_review_food.svg"
import TrustpioltRemoveImg from "../../../Assets/Images/food-trustpiolt-delete.svg";
import GoogleReviewsImg from "../../../Assets/Images/food-google-review-delete.svg";
import ArrowDownImg from "../../../Assets/Images/arrow-down-icon.svg";
import Select from "react-select";
import CafeInvitationModal from "../Components/CafeInvitationModal"
function Additionaldetails({ onNext, handlePrevious, BranchId }) {
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedMenu, setselectedMenu] = useState(null);
  const [selectedImage, setselectedImage] = useState(null);
  const [selectedCoverImage, setselectedCoverImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState({ yes: false, no: false });
  const [events, setEvents] = useState([{ title: "", link: "" }]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [value, setValue] = useState("10:00");
  const [errors, setErrors] = useState({});
  const [formValid, setFormValid] = useState(false);
  const [additionalData, setAdditionalData] = useState({});
  const [dayAvailability, setdayAvailability] = useState({});
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [serviceType, setServiceType] = useState({
    remote: false,
    onsite: false,
  });

  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedMenuHighlights, setSelectedMenuHighlights] = useState([]);
  const [selectedDietaryServices, setSelectedDietaryServices] = useState([]);
  const [selectedAmbience, setSelectedAmbience] = useState([]);
  const [selectedTechStack, setSelectedTechStack] = useState([]);

  const { data: listedLocations } = useGetfoodDeliveryPartnerQuery();

  const [query, setQuery] = useState('');              // search input
  const [results, setResults] = useState([]);           // fetched suggestions
  const [showDropdown, setShowDropdown] = useState(false);

  const accountHolderId = localStorage.getItem("fdserviceaccountHolderId");
  const branchId = BranchId;
  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useAdditionalDetailsEditWithAccountHolderIdMutation();
  const [submitAddForm, { isAddLoading, isAddError, isAddSuccess, Adderror }] =
    useAdditionalDetailsAddWithAccountHolderIdMutation();

  const { data: additionalDataFetch, refetch } =
    useGetadditionalDetailswithAccountHolderIdQuery(branchId);
  useEffect(() => {
    refetch();
  }, [])

  useEffect(() => {
    if (additionalDataFetch) {
      setAdditionalData({
        logo: additionalDataFetch.logo || "",
        menu: additionalDataFetch.menu || "",
        minibio: additionalDataFetch.min_bio || "",
        cafe_registation: additionalDataFetch.cafe_registration || "",
        cover_image: additionalDataFetch.cover_image || "",
        delivery_available: additionalDataFetch.delivery_available || false,
        delivery_range: additionalDataFetch.delivery_range || 0,
      });

      setSelectedFeatures(additionalDataFetch.service_categories || []);
      setSelectedMenuHighlights(additionalDataFetch.product_types || "")
      setSelectedDietaryServices(additionalDataFetch.specialist_dietary_services || "")
      setDeliveryRange(additionalDataFetch.delivery_range || 0);
      setEvents(additionalDataFetch.events.length ? additionalDataFetch.events : [{ title: "", link: "" }]);
      setReviews(additionalDataFetch.reviews || [{ platform_name: "", review_link: "" }]);
      const distributionPartnerIds = additionalDataFetch.distribution_partners || [];

      // Find corresponding business names for the IDs
      const initialPartners = distributionPartnerIds.map((id) => {
        const foundLocation = listedLocations.find(location => location.id === id);
        return foundLocation ? { label: foundLocation.business_name, value: foundLocation.id } : null;
      }).filter(Boolean); // Filter out any null values

      setSelectedDeliveryPartner(initialPartners);
      setProgress(additionalDataFetch.delivery_range || 0)
      const fetchedData = days.map((day) => {
        const availability = additionalDataFetch[`${day.name.toLowerCase()}_availability`];
        const openingTime = additionalDataFetch[`${day.name.toLowerCase()}_opening_time`];
        const closingTime = additionalDataFetch[`${day.name.toLowerCase()}_closing_time`];

        return availability
          ? {
            day,
            openingTime: openingTime || "",
            closingTime: closingTime || "",
            availability,
          }
          : null;
      }).filter(Boolean);

      setFormData(fetchedData.length ? fetchedData : [{ day: null, openingTime: "", closingTime: "", availability: false }]);
    }

  }, [additionalDataFetch]);


  const { data: serviceData } = useGetServiceTypeFoodQuery();

  const { data: producttype } = useGetProductTypeFoodQuery();

  const { data: specialdietryServiceData } = useGetDietryServiceFoodQuery();

  const [isOpentime, setIsOpentime] = useState(false);

  const handleClickTime = () => {
    setIsOpentime(!isOpen);
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setAdditionalData({
      ...additionalData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const [progress, setProgress] = useState(0); // Initialize progress to 0%
  const [deliveryRange, setDeliveryRange] = useState("0km"); // Initialize delivery range to 0km
  const barRef = useRef(null); // Reference to the progress bar container

  const updateProgress = useCallback((clientX) => {
    if (!barRef.current) return;

    // Calculate new progress based on mouse position
    const barRect = barRef.current.getBoundingClientRect();
    const barWidth = barRect.width;
    const offsetX = clientX - barRect.left;
    const newProgress = Math.min(Math.max((offsetX / barWidth) * 100, 0), 100); // Range from 0 to 100 km
    setProgress(newProgress);
    setDeliveryRange(`${Math.round(newProgress)}km`); // Update delivery range
  }, []);

  const handleMouseDown = (e) => {
    updateProgress(e.clientX);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", handleMouseMove);
      },
      { once: true }
    );
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (e.buttons > 0) {
        // Check if mouse is pressed
        updateProgress(e.clientX);
      }
    },
    [updateProgress]
  );

  const transformedLocations = listedLocations?.map((location) => ({
    label: location.business_name || "No data found", // Fallback in case location is null
    value: location.id,
  }));


  const [selectedDeliveryPartner, setSelectedDeliveryPartner] = useState([]);
  const handleMultiSelectChange = (options) => {
    setSelectedDeliveryPartner(options);

    // Check if options are selected and remove the validation error
    if (options && options.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        directory_listing: "", // Clear the specific validation error
      }));
    }
    else{
     
    }
  };


  const handleOptionChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleFeatureChange = (id) => {
    setSelectedFeatures((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle Menu Highlights Change
  const handleMenuHighlightChange = (id) => {
    setSelectedMenuHighlights((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((highlightId) => highlightId !== id)
        : [...prevSelected, id]
    );
  };
  const handleDietaryServiceChange = (id) => {
    setSelectedDietaryServices((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleBack = (e) => {
    handlePrevious();
  };

  const handleChangefield = (index, field, value) => {
    const newEvents = [...events];
    newEvents[index][field] = value;
    setEvents(newEvents);
  };

  const addEvent = () => {
    setEvents([...events, { title: "", link: "" }]);
  };
  const handleDeleteEvent = (index) => {
    const newEvents = events.filter((_, i) => i !== index);
    setEvents(newEvents);
  };

  const handleChangedelevery = (value) => {
   
    if (value == "no") {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };
  const [formData, setFormData] = useState([
    { day: "", openingTime: "", closingTime: "" },
  ]);

  const days = [
    { id: 1, name: "Sunday" },
    { id: 2, name: "Monday" },
    { id: 3, name: "Tuesday" },
    { id: 4, name: "Wednesday" },
    { id: 5, name: "Thursday" },
    { id: 6, name: "Friday" },
    { id: 7, name: "Saturday" },
  ];
  const getSelectedDays = () => {
    return formData.map((data) => data.day?.id).filter(Boolean);
  };
  const toggleDropdown = (index) => {
    const newFormData = [...formData];
    newFormData[index].dropdownOpen = !newFormData[index].dropdownOpen;
    setFormData(newFormData);
  };

  const handleDayChange = (index, selectedDay) => {
    const newFormData = [...formData];
    newFormData[index] = {
      ...newFormData[index],
      day: selectedDay,
      dropdownOpen: false,
    };
    setFormData(newFormData);
  };
  const removeTimeRow = (index) => {
    const newFormData = formData.filter((_, i) => i !== index);
    setFormData(newFormData);
  };
  const handleChange = (index, field, value) => {
    const newFormData = [...formData];
    newFormData[index] = { ...newFormData[index], [field]: value };
    setFormData(newFormData);
  };

  const addRow = () => {
    setFormData([...formData, { day: "", openingTime: "", closingTime: "" }]);
  };

  const removeRow = (index) => {
    const newFormData = formData.filter((_, i) => i !== index);
    setFormData(newFormData);
  };

  const handleFileValidation = (file, allowedTypes) => {
    const fileType = file?.type?.split("/")[1].toLowerCase();
    return allowedTypes.includes(fileType);
  };
  const validateImageDimensions = (file, width, height, callback) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      if (img.width === width && img.height === height) {
        callback(true);
      } else {
        callback(false);
      }
    };
  };

  const handlelogoChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 0.3 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      alert("File size should not exceed 300kb.");
      setSelectedLogo("");
      return;
    }
    if (handleFileValidation(file, ["jpg", "png", "svg", "jpeg"])) {
      setSelectedLogo(file);
    } else {
      alert("Invalid file type. Only .jpg, .png, .svg, and .jpeg files are allowed.");
      setSelectedLogo("");
    }
  };

  const handlemenuChange = (e) => {
    const file = e.target.files[0];
    setselectedMenu(file);
    if (handleFileValidation(file, ["jpg", "png", "jpeg", "svg", "pdf"])) {
      setselectedMenu(file);
    } else {
      alert(
        "Invalid file type. Only .jpg, .png, and .svg .pdf files are allowed."
      );
      setselectedMenu("");
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
  
    if (files.length < 4) {
      alert("Please select at least 4 images.");
      return;
    }
  
    if (files.length > 10) {
      alert("You can only upload a maximum of 10 images.");
      return;
    }
  
    const validFiles = files.filter((file) =>
      ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"].includes(file.type)
    );
  
    if (validFiles.length !== files.length) {
      alert("Only .jpg, .png, and .svg files are allowed.");
      return;
    }
  
    const sizeValidFiles = validFiles.filter((file) => file.size <= 600 * 1024); // 600KB in bytes
  
    if (sizeValidFiles.length !== validFiles.length) {
      alert("Each image must be less than 600kb.");
      return;
    }
  
    setselectedImage(sizeValidFiles);
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    setselectedCoverImage(file);
    const maxSizeInBytes = 0.5 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      alert("File size should not exceed 500kb.");
      setselectedCoverImage("");
      return;
    }
    if (handleFileValidation(file, ["jpg", "png", "svg"])) {
      validateImageDimensions(file, 1080, 960, (isValid) => {
        // if (isValid) {
        //   setselectedCoverImage(file);
        // } else {
        //   alert("Invalid image dimensions. The image should be 1080x960.");
        //   setselectedCoverImage("");
        // }
        setselectedCoverImage(file);
      });
    } else {
      alert("Invalid file type. Only .jpg, .png, and .svg files are allowed.");
      setselectedCoverImage("");
    }
  };
  const validateForm = () => {
    const newErrors = {};
    if (!additionalData.minibio) newErrors.minibio = "Minibio is required.";

    return newErrors;
  };

  const handleAmbienceChange = (id) => {
    setSelectedAmbience((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleTechStackChange = (id) => {
    setSelectedTechStack((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const postData = new FormData();

      // Append files
      if (selectedLogo) postData.append("logo", selectedLogo);
      if (selectedMenu) postData.append("menu", selectedMenu);
      if (selectedCoverImage)
        postData.append("cover_image", selectedCoverImage);
      if (selectedImage) {
        selectedImage.forEach((item) => postData.append("images", item));
      }

      if (additionalData.minibio) {
        postData.append("min_bio", additionalData.minibio || "");
      }

      // postData.append("delivery_available", checked ? "true" : "false");
      // if(progress){
      //   postData.append("delivery_range", Math.round(progress));
      // }
      postData.append("cafe_registration", additionalData.cafe_registation)

      if (events && events.length > 0) {
        events.forEach((item) => {
          if (item.title.trim() !== "" || item.link.trim() !== "") {
            postData.append("events", JSON.stringify(item));
          }
        });
      }
      if (reviews) {
        reviews.forEach((item) =>
          postData.append("reviews", JSON.stringify(item))
        );
      }

      selectedFeatures.forEach((item) =>
        postData.append("service_categories", item)
      );
      selectedMenuHighlights.forEach((item) =>
        postData.append("product_types", item)
      );
      selectedDietaryServices.forEach((item) =>
        postData.append("specialist_dietary_services", item)
      );

      const directoryListingIds = selectedDeliveryPartner.map((item) => item.value);
      directoryListingIds.forEach((item) => postData.append('distribution_partners', item));

      // Handle availability
      const days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];

      days.forEach((day) => {
        const data = formData.find(
          (item) => item?.day?.name?.toLowerCase() === day
        );

        const formatTime = (time) => {
          if (!time) return null;

          const [hours, minutes, seconds = "00"] = time.split(":");
          return `${hours.padStart(2, "0")}:${minutes.padStart(
            2,
            "0"
          )}:${seconds.padEnd(2, "0")}`;
        };

        // Append availability
        if (data) {
          const isAvailable = !!(data.openingTime && data.closingTime);
          postData.append(`${day}_availability`, isAvailable);

          // Append opening and closing times only if they exist
          if (isAvailable) {
            if (data.openingTime) {
              postData.append(
                `${day}_opening_time`,
                formatTime(data.openingTime)
              );
            }
            if (data.closingTime) {
              postData.append(
                `${day}_closing_time`,
                formatTime(data.closingTime)
              );
            }
          }
        }
      });

      try {
        const response = await submitForm({ postData, branchId }).unwrap();
        if (response) {
          onNext(response?.branch);
        }
      } catch (err) {
        console.error("Failed to submit form:", err.status);
        // onNext(postData);
        if (err.status == 404) {
          try {
            const response = await submitAddForm({
              postData,
              branchId,
            }).unwrap();
            if (response) {
              onNext(response?.branch);
            }
          } catch (err) { }
        }
      }
    }
  };

  const handlePlatformSelect = (event) => {
    const selectedPlatform = event.target.value;
    if(selectedPlatform){
    if (!selectedPlatforms.includes(selectedPlatform)) {
      setSelectedPlatforms([...selectedPlatforms, selectedPlatform]);
      setReviews([
        ...reviews,
        { platform_name: selectedPlatform, review_link: "" },
      ]);
    }
  }
  };

  const handleLinkChange = (index, value) => {
    const updatedReviews = [...reviews];
    updatedReviews[index].review_link = value;
    setReviews(updatedReviews);
  };

  const handleDeleteReview = (index) => {
    const updatedReviews = reviews.filter((_, i) => i !== index);
    setReviews(updatedReviews);

    const platformToRemove = reviews[index].platform_name;
    const updatedPlatforms = selectedPlatforms.filter(
      (platform) => platform !== platformToRemove
    );
    setSelectedPlatforms(updatedPlatforms);
  };
  const getImageForPlatform = (platform) => {
    if (platform === "Trustpilot") {
      return TrustpioltRemoveImg;
    } else if (platform === "Google Reviews") {
      return GoogleReviewsImg;
    }
    else if (platform === "Facebook Reviews") {
      return FacebookImg;
    }
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (query.trim()) {
        try {

        } catch (error) {
          console.error("Error fetching search results:", error);
        }
      } else {
        setResults([]);
        setShowDropdown(false);
      }
    };

    // Optional debounce to limit API calls
    const debounceFetch = setTimeout(fetchData, 300);
    return () => clearTimeout(debounceFetch);
  }, [query]);

  // Handle input change
  const handleInputChangeSearch = (e) => {
    setQuery(e.target.value);
  };

  const handleSelect = (item) => {
    setQuery(item);                                  
    setShowDropdown(false);                     
  };

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     boxShadow: state.isFocused ? 'none' : provided.boxShadow,
  //     borderColor: state.isFocused ? 'inherit' : provided.borderColor, 
  //     '&:hover': {
  //       borderColor: state.isFocused ? 'inherit' : provided.borderColor,
  //     },
  //   }),
  // };
  const [showInvitationMOdal, setshowInvitationMOdal] = useState(false)
const handleShowCafeinvitationPopup = ()=>{
  setshowInvitationMOdal(true);
}
const handleCloseInvitationModal = ()=>{
  setshowInvitationMOdal(false)
}
  return (
    <div className="add-details add-additional-details">
      <h4>Additional Details</h4>
      <hr />
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} sm={12} lg={6} md={6}>
            <label>Logo</label>
            <div className="file-upload-container d-flex">
              <label className="file-upload-label">
                <input
                  type="file"
                  className="file-upload-input"
                  onChange={handlelogoChange}
                />
                <div>
                  <img src={FileUpload} alt="FilecUpload" />
                </div>
                <div>
                  <span className="file-upload-text">
                    {selectedLogo ? (
                      selectedLogo.name
                    ) : additionalDataFetch?.logo ? (
                      <img
                        src={additionalDataFetch.logo}
                        width="50px"
                        height="20px"
                        alt="Logo"
                      />
                    ) : (
                      "Please select a file"
                    )}
                  </span>
                  <span className="format-text">
                  (Only supports .jpg, .png and .svg files & File size should be less than 300kb)
                  </span>
                </div>
              </label>
            </div>
          </Col>
          {/* <Col xs={12} sm={12} lg={6} md={6}>
            <label>Menu</label>
            <div className="file-upload-container d-flex">
              <label className="file-upload-label">
                <input
                  type="file"
                  className="file-upload-input"
                  onChange={handlemenuChange}
                />
                <div>
                  <img src={FileUpload} alt="FilecUpload" />
                </div>
                <div>
                  <span className="file-upload-text">
                  {selectedMenu ? (
                      selectedMenu.name
                    ) : additionalDataFetch?.menu ? (
                      <img
                        src={additionalDataFetch.menu}
                        width="50px"
                        height="20px"
                        alt="Logo"
                      />
                    ) : (
                      "Please select a file"
                    )}
                  </span>
                  <span className="format-text">
                    (Only supports .jpg, .png and .svg files)
                  </span>
                </div>
              </label>
            </div>
          </Col> */}
          <Col xs={12} sm={12} lg={6} md={6}>
            <label>Images</label>
            <div className="file-upload-container d-flex">
              <label className="file-upload-label">
                <input
                  type="file"
                  className="file-upload-input"
                  onChange={handleImageChange}
                  multiple
                />
                <div>
                  <img src={FileUpload} alt="FilecUpload" />
                </div>
                <div>
                  <span className="file-upload-text">
                    {selectedImage?.length > 0
                      ? `${selectedImage?.length} files selected`
                      : "Please select files"}
                  </span>
                  <span className="format-text">
                    Max of 10 images and min of 4
                  </span><br />
                  <span className="format-text">
                  File size should be less than 600kb
                  </span>
                </div>
              </label>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <label>Cover image</label>
            <div className="file-upload-container d-flex">
              <label className="file-upload-label">
                <input
                  type="file"
                  className="file-upload-input"
                  onChange={handleCoverImageChange}
                />
                <div>
                  <img src={FileUpload} alt="FilecUpload" />
                </div>
                <div>
                  <span className="file-upload-text">
                    {selectedCoverImage ? (
                      selectedCoverImage.name
                    ) : additionalDataFetch?.cover_image ? (
                      <img
                        src={additionalDataFetch.cover_image}
                        width="50px"
                        height="20px"
                        alt="Logo"
                      />
                    ) : (
                      "Please select a file"
                    )}
                  </span>
                  <span className="format-text">
                    Image length should be 1080x960
                  </span><br />
                  <span className="format-text">File size should be less than 300kb</span>
                </div>
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} lg={12} md={12}>
            <div className="form-control">
              <label>
                Mini Bio<span className="red-star">*</span>
              </label>
              <br />
              <textarea
                rows={4}
                placeholder="About your company"
                name="minibio"
                maxLength="200"
                value={additionalData.minibio || ""}
                onChange={handleInputChange}
              ></textarea>
              {errors.minibio && (
                <div className="error-message">{errors.minibio}</div>
              )}
              <br />
              <span className="max-letter">Maximum of 200 words</span>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                Cafe registration{" "}
                <div class="tooltip-common">
                  <img src={AlertImg} alt="AlertImg" />
                  <span class="tooltip-commontext">
                  Provide the link for cafe members to sign up or create an account with you.
                  </span>
                </div>
              </label>
              <br />
              <input type="text" placeholder="John" onChange={handleInputChange} name="cafe_registation" value={additionalData.cafe_registation || ""} maxLength="100" />
            </div>
          </Col>
        </Row>
        {formData.map((data, index) => (
          <Row key={index}>
            <Col xs={12} sm={12} lg={2} md={2}>
              <div className="form-control">
                <label>Day</label>
                <br />
                <div className="custom-dropdown">
                  <div
                    className="selected-day"
                    onClick={() => toggleDropdown(index)}
                  >
                    {data.day ? data.day.name : "Select a day"}
                    <img
                      src={DropdownImg}
                      alt="Dropdown"
                      className="arrow-dropdown-img"
                    />
                  </div>
                  {data.dropdownOpen && (
                    <ul className="dropdown-list">
                      {days.map((day) => (
                        <li
                          key={day.id}
                          onClick={() => handleDayChange(index, day)}
                          className={getSelectedDays().includes(day.id) ? 'disabled' : ''}
                          style={{
                            pointerEvents: getSelectedDays().includes(day.id)
                              ? "none"
                              : "auto",
                            opacity: getSelectedDays().includes(day.id) ? 0.5 : 1,
                          }}
                        >
                          {day.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} lg={2} md={2}>
              <div className="form-control">
                <label>Opening Time</label>
                <br />
                {/* <img
                      src={DropdownImg}
                      alt="Dropdown"
                      className="arrow-dropdown-time"
                    /> */}
                <input
                  type="time"
                  value={data.openingTime}
                  onChange={(e) =>
                    handleChange(index, "openingTime", e.target.value)
                  }
                />
                {/* <input
        type="text"
        onClick={handleClickTime}
        readOnly
      />
      {isOpentime && (
        <TimePicker
        value={value}
        onChange={setValue}
          onClickOutside={() => setIsOpen(false)}
          clockClassName="custom-clock"
        />
      )} */}
              </div>
            </Col>
            <Col xs={12} sm={12} lg={2} md={2}>
              <div className="form-control">
                <label>Closing Time</label>
                <br />
                {/* <img
                      src={DropdownImg}
                      alt="Dropdown"
                      className="arrow-dropdown-time"
                    /> */}
                <input
                  type="time"
                  value={data.closingTime}
                  onChange={(e) =>
                    handleChange(index, "closingTime", e.target.value)
                  }
                />
              </div>
            </Col>
            <Col xs={12} sm={12} lg={2} md={2} className="d-flex">
              <img src={DeleteList} alt={DeleteList} onClick={() => removeTimeRow(index)} />
              {index === formData.length - 1 && (
                <button type="button" onClick={addRow} className="add-new-btn">
                  Add
                </button>
              )}
            </Col>
            {/* <Col xs={12} sm={12} lg={2} md={2}>
            <div className="form-control">
              <button type="button" onClick={() => removeRow(index)}>Remove</button>
            </div>
          </Col> */}
          </Row>
        ))}

        {/* 
        <Row>
          <Col xs={12} sm={12} lg={12} md={12}>
            {checked && (
              <Col xs={4} sm={4} lg={4} md={4}>
                <div className="form-control">
                  <div className="d-flex align-flex-end">
                    <label>
                      Delivery Range<span className="red-star">*</span>
                    </label>
                    <span className="delivery-range"> {deliveryRange}</span>
                  </div>
                  <div
                    className="progress-bar-container"
                    ref={barRef}
                    onMouseDown={handleMouseDown}
                  >
                    <div
                      className="progress-bar"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>
              </Col>
            )}
          </Col>
        </Row> */}
        <Row>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                Select your distribution partner<span className="red-star">*</span>
              </label>
              <br />
              <div className="distribution-partner-div">


                <img
                  src={SearchIcon}
                  alt="SearchIcon"
                  className="search-icon-distribution-partner"
                />
                <Select
                  isMulti
                  value={selectedDeliveryPartner}
                  onChange={handleMultiSelectChange}
                  options={transformedLocations}
                  placeholder="Select your distribution partner"
                  name="directory_listing"
                 
                />
              </div>
              <br />

              <span className="max-letter">Maximum of 200 words</span>

              
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}><button type="button" className="add-new-btn mt-30" onClick={handleShowCafeinvitationPopup}>Invite</button></Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label className="choose-label">
              Select Your Relevant Service Categories
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {serviceData?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${selectedFeatures.includes(option.id) ? "selected" : ""
                        }`}
                      onClick={() => handleFeatureChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`option${option.id}`}
                        name="services"
                        value={option.id}
                        checked={selectedFeatures.includes(option.id)}
                        onChange={() => handleFeatureChange(option.id)}
                        style={{ display: "none" }}
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label
                        htmlFor={`option${option.id}`}
                        title={option.name} // Show full name on hover
                      >
                        {option.name.split(":")[0]}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label className="choose-label">
              Products You Offer
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {producttype?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${selectedMenuHighlights.includes(option.id)
                        ? "selected"
                        : ""
                        }`}
                      onClick={() => handleMenuHighlightChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`option${option.id}`}
                        name="products"
                        value={option.id}
                        checked={selectedMenuHighlights.includes(option.id)}
                        onChange={() => handleMenuHighlightChange(option.id)}
                        style={{ display: "none" }} // Hide the checkbox
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label
                        htmlFor={`option${option.id}`}
                        title={option.name} // Show full name on hover
                      >
                        {option.name.length > 20
                          ? `${option.name.substring(0, 20)}...`
                          : option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="see-more">See more..</div> */}
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label className="choose-label">
                Specialist Dietary Services
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {specialdietryServiceData?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${selectedDietaryServices.includes(option.id)
                        ? "selected"
                        : ""
                        }`}
                      onClick={() => handleDietaryServiceChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`dietaryOption${option.id}`}
                        name="dietaryServices"
                        value={option.id}
                        checked={selectedDietaryServices.includes(option.id)}
                        onChange={() => handleDietaryServiceChange(option.id)}
                        style={{ display: "none" }} // Hide the checkbox
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label
                        htmlFor={`option${option.id}`}
                        title={option.name} // Show full name on hover
                      >
                        {option.name.length > 20
                          ? `${option.name.substring(0, 20)}...`
                          : option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="see-more">See more..</div> */}
          </Col>
        </Row>

        <h4>Events</h4>
        <hr />
        {events.map((event, index) => (
          <Row key={index}>
            <Col xs={12} sm={12} lg={5} md={5}>
              <div className="form-control">
                <label>Event Title</label>
                <br />
                <input
                  type="text"
                  placeholder="Enter event title"
                  value={event.title}
                  onChange={(e) =>
                    handleChangefield(index, "title", e.target.value)
                  }
                />
              </div>
            </Col>
            <Col xs={12} sm={12} lg={5} md={5}>
              <div className="form-control">
                <label>Event Link</label>
                <br />
                <input
                  type="text"
                  placeholder="Add link"
                  value={event.link}
                  onChange={(e) =>
                    handleChangefield(index, "link", e.target.value)
                  }
                />
              </div>
            </Col>

            <Col xs={12} sm={12} lg={2} md={2} className="d-flex">
              {events.length > 1 && index !== events.length - 1 && (
                <img src={DeleteList} alt={DeleteList} onClick={() => handleDeleteEvent(index)} />
              )}
              {index === events.length - 1 && (
                <button
                  type="button"
                  onClick={addEvent}
                  className="add-new-btn"
                >
                  Add
                </button>
              )}
            </Col>
          </Row>
        ))}
        <h4>Reviews</h4>
        <hr />

        <Row>
          <Col xs={12} sm={12} lg={5} md={5}>
            <div className="form-control">
              <label>Review Platform</label>
              <br />
              <select onChange={handlePlatformSelect}>
                <option value="">Select your review platform</option>
                <option value="Google Reviews">Google Reviews</option>
                <option value="Trustpilot">Trustpilot</option>
                <option value="Facebook Reviews">Facebook Reviews</option>
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          {reviews.map((review, index) => (
            <Col xs={12} sm={12} lg={4} md={4} key={index}>
              <div className="form-control">
                {/* <label>{review.platform_name} Link</label> */}
                <label onClick={() => handleDeleteReview(index)}>
                  {" "}
                  {getImageForPlatform(review.platform_name) && (
                    <img
                      src={getImageForPlatform(review.platform_name)}
                      alt={`${review.platform_name} logo`}
                    />
                  )}
                </label>
                <br />
                <input
                  type="text"
                  placeholder={`Enter your ${review.platform_name} link`}
                  value={review.review_link}
                  onChange={(e) => handleLinkChange(index, e.target.value)}
                />
              </div>
            </Col>
          ))}
        </Row>

        <Row className="action-btn">
          <Col xs={12} sm={12} lg={12} md={12} className="text-end">
            <button
              type="button"
              className="prev-btn btn-64t35ne"
              onClick={handleBack}
            >
              Back
            </button>
            <button type="submit" className="btn-save-primary">
              Save and continue
            </button>
          </Col>
          {/* {isError && (
            <p style={{ color: "red" }}>
              Error: {error?.data?.error || "Failed to submit form"}
            </p>
          )} */}
          {isSuccess && (
            <p style={{ color: "green" }} className="isSuceesMsg">
              Form submitted successfully!
            </p>
          )}
          {isLoading && <div class="loader-food"></div>}
        </Row>
      </form>
      <CafeInvitationModal show={showInvitationMOdal} handleCloseAddNewModal={handleCloseInvitationModal}/>
    </div>
  );
}

export default Additionaldetails;
