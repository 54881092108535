import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../Styles/DirectoryPayment.css";
import {
  useGetCompletedBranchesQuery,
  useGetInCompletedBranchesQuery,
  useSubmitLiteLocationDataMutation,
} from "../Services/CcownerAPI";

import { useGetceAmountQuery } from "../Services/CommonAPI"
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";

function DashboardDirectoryListing() {
  const navigate = useNavigate();
  const [proprice, setProprice] = useState(0);
  // Initial state setup
  const locations = [
    {
      id: 1,
      name: "Victoria",
      address: "Mrs Smith 813 Howard Street Oswego, NY 13126 USA",
      payment: proprice,
    },
    {
      id: 2,
      name: "Queensland",
      address: "Mrs Smith 813 Howard Street Oswego, NY 13126 USA",
      payment: proprice,
    },
  ];
  const {
    data: unlistedLocations,
    isLoading,
    refetch: refetchIncompleteData,
  } = useGetInCompletedBranchesQuery();

  const { data: proamount } = useGetceAmountQuery();


  useEffect(() => {
    if (proamount) {
      setProprice(proamount?.amount)
    }

  }, [proamount])

  const { data: completedbranch, isLoading: isLoadingCompletedData } =
    useGetCompletedBranchesQuery();
  const [selectedLocations, setSelectedLocations] = useState([]);

  const [submitForm, { }] = useSubmitLiteLocationDataMutation();

  const handleCheckboxChange = (id, isPro) => {
    setSelectedLocations((prevSelected) => {
      // Find the location to be selected
      const location = unlistedLocations?.find((loc) => loc.id === id);

      // Deselect all other locations and select the new one
      return [
        {
          id,
          location: location?.location,
          address: location?.address,
          operational_status: location?.operational_status,
          payment: isPro ? proprice : 0,
          subscription_type: isPro ? "pro" : "lite",
        },
      ];
    });
  };


  useEffect(() => {
    refetchIncompleteData();
  }, []);
  const handlePaymentOptionChange = (id, isPro) => {
    setSelectedLocations((prevSelected) => {
      return prevSelected.map((loc) =>
        loc.id === id
          ? {
            ...loc,
            payment: isPro ? proprice : 0,
            subscription_type: isPro ? "pro" : "lite", // Update subscription type
          }
          : loc
      );
    });
  };
  const calculateTotal = () => {
    return selectedLocations.reduce((sum, loc) => sum + loc.payment, 0);
  };

  const total = calculateTotal();
  const gst = total * 0.1;
  const subtotal = total + gst;

  const handleCompletePayment = async (event) => {
    if (selectedLocations?.length > 0) {
      const liteLocations = selectedLocations.filter(
        (loc) => loc.subscription_type === "lite"
      );
      const proLocations = selectedLocations.filter(
        (loc) => loc.subscription_type === "pro"
      );



      const liteLocationIds = liteLocations.map((loc) => loc.id);
      if (proLocations.length > 0) {
        navigate("/directory-payment-list", {
          state: {
            liteLocations: liteLocations,
            proLocations: proLocations,
            total: total,
            subtotal: subtotal,
          },
        });
        if (liteLocations.length > 0) {
          const postData = {
            branch_ids: liteLocationIds,
          };
          try {
            const response = await submitForm(postData).unwrap();
          } catch (err) {
            console.error("Failed to submit form:", err);
          }
        }
      } else {
        if (liteLocations.length > 0) {
          const postData = {
            branch_ids: liteLocationIds,
          };
          try {
            const response = await submitForm(postData).unwrap();
            if (response) {
              alert("Succesfully Updated");
            }
            const timer = setTimeout(() => {
              navigate("/directory-listing");
            }, 1000);
          } catch (err) {
            console.error("Failed to submit form:", err);
            alert("Failed");
          }
        }
      }
    }
    else {
      Swal.fire({
        icon: 'error',
        text: 'Please choose a location',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
  };

  return (
    <div className="dashboard-listing directory-payment">
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Payments & Invoicing</h4>
          <p className="sub-head">
            View past payments and invoices.
          </p>
          <p className="required-message-impoertant">Cafe Listings will not appear on the public directory map until the business name and location address are correctly entered.</p>
        </Col>
      </Row>
      {unlistedLocations?.length > 0 ? (
        <div>
          <div className="list-card">
            {unlistedLocations?.map((location) => (
              <div className="card" key={location.id}>
                <Row>
                  <Col xs={12} sm={12} md={5} lg={5}>
                    <div className="d-flex">
                      <div className="mr-15">
                        <input
                          type="radio" // Use radio for single selection
                          onChange={() =>
                            handleCheckboxChange(
                              location.id,
                              selectedLocations.some(
                                (loc) =>
                                  loc.id === location.id && loc.payment === proprice
                              )
                            )
                          }
                          checked={selectedLocations.length === 1 && selectedLocations[0].id === location.id} // Only the selected location is checked
                        />
                      </div>
                      <div>
                        <p className="location-head">
                          {location.location ?? "Location"}
                        </p>
                        {location.operational_status.length > 0 ? (
                          location.operational_status.map((status) => (
                            <button
                              key={status.id}
                              className="open-for-business-btn"
                            >
                              {status.name}
                            </button>
                          ))
                        ) : (
                          <button className="open-for-business-btn">
                            Unknown status
                          </button>
                        )}
                        <p className="address">
                          {location.address ?? "Address"}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={4} md={4} lg={4} className="text-end">
                    <label className="switch-payment-box">
                      <span className="lite-text">Lite</span>
                      <input
                        type="checkbox"
                        onChange={() =>
                          handlePaymentOptionChange(
                            location.id,
                            !selectedLocations.some(
                              (loc) =>
                                loc.id === location.id && loc.payment === proprice
                            )
                          )
                        }
                        checked={selectedLocations.some(
                          (loc) => loc.id === location.id && loc.payment === proprice
                        )}
                      />
                      <span className="slider round"></span>
                      <span className="pro-text-payment">Pro</span>
                    </label>
                    <p className="payment-method">
                      <b>
                        $
                        {selectedLocations.some(
                          (loc) => loc.id === location.id && loc.payment === proprice
                        )
                          ? proprice
                          : "0"}{" "}
                        /{" "}
                      </b>
                      month
                    </p>
                  </Col>
                </Row>
              </div>
            ))}
          </div>


          <div className="payment-complete-box">
            <div>
              <p className="selected-loc">
                Selected <button>{selectedLocations.length} location</button>
              </p>
            </div>
            <div className="d-flex align-space-btwn">
              <p className="head">Total</p>
              <p className="value">${total.toFixed(2)}</p>
            </div>
            <div className="d-flex align-space-btwn">
              <p className="head">GST (10%)</p>
              <p className="value">${gst.toFixed(2)}</p>
            </div>
            <hr />
            <div className="d-flex align-space-btwn">
              <p className="head">Subtotal</p>
              <p className="value">${subtotal.toFixed(2)}</p>
            </div>
            <Row className="action-btn">
              <Col xs={12} sm={12} lg={12} md={12} className="text-end">
                <Link to="/directory-listing"> <button type="button" className="prev-btn">
                  Cancel
                </button></Link>
                <button
                  type="submit"
                  className="btn-save-primary"
                  onClick={handleCompletePayment}
                >
                  Continue
                </button>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="no-payment-details">
          {" "}
          <p>
            No listing(s) found!
          </p>
        </div>
      )}
    </div>
  );
}

export default DashboardDirectoryListing;
