import React, { useEffect, useState, useRef } from "react";
import "../Styles/EcosystemNotification.css";
import { Row, Col } from "react-bootstrap";
import { formatDistanceToNow } from 'date-fns';

function Ecosystemnotification() {


  const [notifications, setNotifications] = useState([]);
  const token = localStorage.getItem('accessToken');
  const websocketUrl = `wss://${process.env.REACT_APP_BASEURL_SERVER_ENQUIRY}ws/cafe/portal-notifications/?token=${token}`; // Pass the token in the URL

  const [notificationsR, setNotificationsR] = useState([]);
  const socketRefR = useRef(null); // Ref to store WebSocket instance
  const tokenR = localStorage.getItem("accessToken");
  const websocketUrlR = `wss://${process.env.REACT_APP_BASEURL_SERVER_ENQUIRY}ws/cafe/portal-notifications/?token=${tokenR}`;

  // Establish WebSocket connection
  useEffect(() => {
    // Create a new WebSocket connection
    socketRefR.current = new WebSocket(websocketUrlR);

    // Handle connection open
    socketRefR.current.onopen = () => {
      console.log("WebSocket connection established");
    };

    // Handle incoming messages
    socketRefR.current.onmessage = (event) => {
      const notificationR = JSON.parse(event.data);

      // Update or append notifications while keeping the correct order
      setNotificationsR((prevNotificationsR) => {
        const existingIndex = prevNotificationsR.findIndex(
          (notif) => notif.id === notificationR.id
        );

        if (existingIndex !== -1) {
          // Update existing notification
          const updatedNotifications = [...prevNotificationsR];
          updatedNotifications[existingIndex] = notificationR;
          return updatedNotifications;
        }

        // Add new notification
        return [...prevNotificationsR, notificationR];
      });
    };

    // Handle connection close
    socketRefR.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    // Clean up the socket connection on unmount
    return () => {
      if (socketRefR.current) {
        socketRefR.current.close();
      }
    };
  }, [websocketUrlR]); // Reconnect if websocketUrlR changes

  const markAsRead = (notificationId) => {
    const data = {
      action: "mark_as_read",
      notification_id: notificationId,
    };

    if (socketRefR.current && socketRefR.current.readyState === WebSocket.OPEN) {
      socketRefR.current.send(JSON.stringify(data)); // Send action to WebSocket server
      console.log(`Notification ${notificationId} marked as read`);

      // Update `is_read` status locally
      setNotificationsR((prevNotifications) =>
        prevNotifications.map((notif) =>
          notif.id === notificationId ? { ...notif, is_read: true } : notif
        )
      );
    } else {
      console.error("WebSocket is not open. Cannot mark notification as read.");
    }
  };

  useEffect(() => {
    // Mark notifications as read if they are unread
    notificationsR.forEach((notif) => {
      if (!notif.is_read) {
        markAsRead(notif.id);
      }
    });
  }, [notificationsR]); // Run when notifications array changes

  // #########################################################################


  useEffect(() => {
    // Create a new WebSocket connection
    const socket = new WebSocket(websocketUrl);

    // Handle connection open
    socket.onopen = () => {

    };

    // Handle incoming messages
    socket.onmessage = (event) => {
      const notification = JSON.parse(event.data);

      setNotifications((prevNotifications) => [notification, ...prevNotifications]); // Prepend new notifications
    };

    // Handle connection close
    socket.onclose = () => {

    };

    // Clean up the socket connection on unmount
    return () => {
      socket.close();
    };
  }, [websocketUrl]);

  return (
    <div className="ecosystem-notification">
      <h4>Notifications</h4>
      <p className="sub-head">Stay updated with the latest alerts and messages.</p>
      {notifications?.map((notification) => (<div className="notification-list">
        <div className="card">
          <h6>
            {notification?.title}
          </h6>
          {notification?.additional_data?.category && (
            <p className="paragraph">
              {`${notification?.additional_data?.category || ""} > ${notification?.additional_data?.industry_update || ""}`}
            </p>
          )}
          {/* <p>
          {notification?.message ? notification?.message.replace(/<[^>]+>/g, '') : ''}
          </p> */}
          <p
            className="paragraph"
            dangerouslySetInnerHTML={{ __html: notification?.message }}
          >

          </p>
          <div className="text-end">
            <p className="time">{formatDistanceToNow(new Date(notification?.created_at))}</p>
          </div>
        </div>
      </div>))}
    </div>
  );
}

export default Ecosystemnotification;
