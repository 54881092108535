import React, { useState, useRef, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  useBranchEditWithBranchIdMutation,
  useGetBranchDatawithAccountHolderIdQuery,
  useUserGetEditQuery

} from "../../../Services/FoodserviceAPI";
import {
  GoogleMap,
  useLoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { Row, Col } from "react-bootstrap";
const libraries = ["places"];

const ModalComponent = ({  show, handleCloseLocationModal,refetchCompletedLOcationData,refetchinCompletedLOcationData,CurrentBranchId}) => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    businessName: "",
    location: "",
    address: "",
    lat: null,
    lng: null,
    confirmation_status: [],
  });
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const { data: userData } = useUserGetEditQuery();
  const { data: branchData ,refetch:refetchData} = useGetBranchDatawithAccountHolderIdQuery(CurrentBranchId);
  const [submitForm, { isLoading, isError, isSuccess, error }] =
  useBranchEditWithBranchIdMutation();


  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAP_API_LOCATION,
    libraries,
  });

  const searchBoxRef = useRef(null);

  // Pre-fill form values when branch data is available
  useEffect(() => {
    if (branchData) {
      setFormValues({
        businessName: branchData.business_name || "",
        location: branchData.location || "",
        address: branchData.address || "",
        lat: null,
        lng: null,
        confirmation_status: [],
      });
      refetchData();
    }
  }, [branchData]);

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formValues.businessName) {
      isValid = false;
      newErrors.businessName = "Please enter your business name.";
    }

    if (!formValues.location) {
      isValid = false;
      newErrors.location = "Please enter your location.";
    }

    if (!formValues.address) {
      isValid = false;
      newErrors.address = "Please enter your address.";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const postdata = {
        id: CurrentBranchId,
        business_name: formValues.businessName,
        location: formValues.location,
        address: formValues.address,
        // latitude: formValues.lat,
        // longitude: formValues.lng,
        // operational_status: formValues.confirmation_status,
      };
      if(formValues.lat){
        postdata.latitude =formValues.lat
      }
      if(formValues.lat){
        postdata.longitude =formValues.lng
      }
      try {
        const response = await submitForm({postdata, branchId:CurrentBranchId}).unwrap();
        if (response) {
          if(userData?.free_trial == "active"){
            navigate("/food-service-directorypagelisting");
            handleCloseLocationModal();
            refetchinCompletedLOcationData();
            refetchCompletedLOcationData()
          }
          else{
            navigate("/food-service-directory-payment");
          }
         
        }
      } catch (err) {
        console.error("Failed to submit form, please enter a correct place:", err);
      }
    }
  };

  const handleChange = (field, value) => {
    let formattedValue = value;

    // Validation for specific fields
    if (field === "businessName" && !/^[A-Za-z\s]*$/.test(value)) {
      setErrors({ ...errors, businessName: "Only letters and spaces are allowed." });
      return;
    }

    if (field === "address") {
      formattedValue = value.replace(/[^A-Za-z0-9\s,.-]/g, "");
      if (value !== formattedValue) {
        setErrors({ ...errors, address: "Special characters removed." });
      } else {
        setErrors({ ...errors, address: "" });
      }
    }

    setFormValues({ ...formValues, [field]: formattedValue });
    setErrors({ ...errors, [field]: "" });
  };

  const handleChangeStatus = (value) => {
    setSelectedOptions((prev) => ({
      [value]: !prev[value],
    }));

    const updatedStatus = [];
    if (value === "planning") updatedStatus.push(1);
    if (value === "open") updatedStatus.push(2);
    if (value === "exiting") updatedStatus.push(3);

    setFormValues((prev) => ({
      ...prev,
      confirmation_status: updatedStatus,
    }));
  };

  const onPlacesChanged = () => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      if (places && places.length > 0) {
        const selectedPlace = places[0];
        const locationName = selectedPlace.name || "";
        let lat = null,
          lng = null;

        if (selectedPlace.geometry && selectedPlace.geometry.location) {
          lat = selectedPlace.geometry.location.lat().toFixed(6);
          lng = selectedPlace.geometry.location.lng().toFixed(6);
        }

        setFormValues({
          ...formValues,
          location: locationName,
          lat,
          lng,
        });
      }
    }
  };



  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseLocationModal}
        backdrop="true"
        dialogClassName="modal-dialog-centered add-another-branch-modal show-create-list"
      >
        <Modal.Body>
          <h2>Create listing</h2>
          <p>
          Complete directory listings amplify engagement. Ensure your business
          shines by filling in all criteria.
          </p>
          <form onSubmit={handleSubmit}>

            <div>


              {/* <label>
                Confirm your operational status
                <span className="star">*</span>
              </label>
              <br />
              <Row className="status-block">
                <Col sm={12} xs={12} md={4} lg={4}>
                  <input
                    type="checkbox"
                    value="planning"
                    checked={selectedOptions.planning || false}
                    onChange={() => handleChangeStatus("planning")}
                     disabled={selectedOptions.open || selectedOptions.exiting}
                  />
                  <label
                    className={`label-for-radio-btn ${selectedOptions.open || selectedOptions.exiting
                        ? "disabled-label"
                        : ""
                      }`}
                    htmlFor="planning"
                  >
                    Planning to Open 
                  </label>
                </Col>
                <Col sm={12} xs={12} md={4} lg={4}>
                  <input
                    type="checkbox"
                    value="open"
                    checked={selectedOptions.open || false}
                    onChange={() => handleChangeStatus("open")}
                    disabled={selectedOptions.planning}
                    
                  />
                   <label
                    className={`label-for-radio-btn ${selectedOptions.planning ? "disabled-label" : ""
                      }`}
                    htmlFor="open"
                  >
                    Open for Business
                  </label>
                </Col>
                <Col sm={12} xs={12} md={4} lg={4}>
                  <input
                    type="checkbox"
                    value="exiting"
                    checked={selectedOptions.exiting || false}
                    disabled={selectedOptions.planning}
                    onChange={() => handleChangeStatus("exiting")}
                  />
                 <label
                    className={`label-for-radio-btn ${selectedOptions.planning ? "disabled-label" : ""
                      }`}
                    htmlFor="exiting"
                  >
                    Looking to Exit
                  </label>
                </Col>

              </Row> */}

              <Row className="mt-16">
                <Col xs={12} sm={12} lg={6} md={6}>
                  <div className="form-control">
                    <label>
                      Business name<span className="red-star">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      value={formValues.businessName}
                      onChange={(e) => handleChange("businessName", e.target.value)}
                    />
                    {errors.businessName && <p className="error">{errors.businessName}</p>}
                  </div>
                </Col>
                <Col xs={12} sm={12} lg={6} md={6}>
                  <div className="form-control">
                    <label>
                      Branch<span className="red-star">*</span>
                    </label>
                    <br />
                    {/* <input
                        type="text"
                        placeholder="Enter your location"
                        value={formValues[location.id]?.location || ""}
                        onChange={(e) =>
                          handleInputChange(
                            location.id,
                            "location",
                            e.target.value
                          )
                        }
                      /> */}
                    <StandaloneSearchBox
                      onLoad={(ref) => (searchBoxRef.current = ref)}
                      onPlacesChanged={onPlacesChanged}
                    >
                      <input
                        type="text"
                        value={formValues.location}
                        placeholder="Enter Branch"
                        onChange={(e) => handleChange("location", e.target.value)}
                      />
                    </StandaloneSearchBox>
                    {errors.location && <p className="error">{errors.location}</p>}
                  </div>
                </Col>
                <Col xs={12} sm={12} lg={6} md={6}>
                  <div className="form-control">
                    <label>
                      Address<span className="red-star">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      value={formValues.address}
                      onChange={(e) => handleChange("address", e.target.value)}
                    />
                    {errors.address && <p className="error">{errors.address}</p>}
                  </div>
                </Col>
              </Row>


            </div>

            <Row className="action-btn">

              <Col xs={12} sm={12} lg={6} md={6} className="text-end">
                <button
                  type="button"
                  className="prev-btn btn-64t35ne"
                  onClick={handleCloseLocationModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-save-primary-food">
                  Continue
                </button>
              </Col>
              {isError && (
                <p style={{ color: "red" }}>
                  Error: {error?.data?.error || "Failed to submit form"}
                </p>
              )}
              {isSuccess && (
                <p style={{ color: "green" }} className="isSuceesMsg">
                  Form submitted successfully!
                </p>
              )}
              {isLoading && <div class="loader-food"></div>}
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComponent;
