import React, { useState, useEffect, useRef } from "react";
import DirectoryListingNavbar from "../Components/DirectoryListingNavbar";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import DReamCfeImg from "../Assets/Images/dream-cafe-img.png";
import MailIcon from "../Assets/Images/foodservice-email.svg";
import LinkIcon from "../Assets/Images/food-service-link.svg";
import PhoneIcon from "../Assets/Images/foodservice-phone.svg";
import Facebook from "../Assets/Images/fs-facebook.svg";
import Linkdin from "../Assets/Images/fs-linkdin.svg";
import Instagram from "../Assets/Images/fs-instagram.svg";
import RSS from "../Assets/Images/connect-rss.svg";
import Googleplus from "../Assets/Images/connect-googleplus.svg";
import Twitter from "../Assets/Images/fs-twitter.svg";
import Youtube from "../Assets/Images/fs-youtube.svg";
import CafeImg1 from "../Assets/Images/cafe-img1.png";
import CafeImg2 from "../Assets/Images/cafe-img2.png";
import CafeImg3 from "../Assets/Images/cafe-img3.png";
import CafeImg4 from "../Assets/Images/cafe-img4.png";
import CafeImg5 from "../Assets/Images/cafe-img5.png";
import OtherLocationElipseImg from "../Assets/Images/other-location-elipse-img.svg";
import FeatureImg from "../Assets/Images/cafe-features.png";
import MenuImg from "../Assets/Images/cafe-menu.png";
import Ambience from "../Assets/Images/cafe-ambience.png";
import CafeDietry from "../Assets/Images/cafe-dietry.png";
import GoogleImg from "../Assets/Images/Google.svg";
import Trustpilot from "../Assets/Images/Trustpilot_ Reviews & Ratings.svg";
import TechStack from "../Assets/Images/tech-stack.png";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import ServiceCategory from "../Assets/Images/servicve-category.png";
import ExtraSupportImg from "../Assets/Images/food_additional_extra_support.png";
import WorkSupport from "../Assets/Images/trades-work-with.png";
import StateSupprt from "../Assets/Images/cafe-state.png";
import MenuSupport from "../Assets/Images/food_additional_dietry.png";
import Sendbtn from "../Assets/Images/send-btn.svg";
import Elipse1 from "../Assets/Images/tradesservice-elipse1.svg";
import Elipse2 from "../Assets/Images/tradesservice-elispe2.svg";
import Elipse3 from "../Assets/Images/tradesservice-elispe3.svg";
import Elipse4 from "../Assets/Images/tradesservice-elipse4.svg";
import LikesImg from "../Assets/Images/likes-img.svg";
import ProducttypeImg from "../Assets/Images/fs-producttype.png";
import FlagImg from "../Assets/Images/flag-icon.svg";
import CloseIcon from "../Assets/Images/close.svg";
import EditIcon from "../Assets/Images/directory-edit.svg";
import SpecialDietryImg from "../Assets/Images/fs-special-dietry.png";
import defaultcoverImage from "../Assets/Images/default-cover-image.png";
import img3 from "../Assets/Images/gallery-img3.svg";
import Playbtn from "../Assets/Images/vido-play-btn.svg";
import FacebookImg from "../Assets/Images/facebook-color.svg";
import {
  useGetAllBranchDetailsForallUserQuery,
  useGetBranchListQuery,
  useGetpreviewbranchesMatchesQuery,
  useGetMediaGallerymatchpreviewQuery,

} from "../Services/FoodserviceAPI";
import {
  useFsacceptcafeMutation,
  useGetFoodviewMoreDesicionQuery,
  useCafeacceptfdMutation,
  useCaferejectfdMutation,
  useTdacceptcafeMutation,
  useTdrejectcafeMutation,
} from "../Services/CcownerAPI";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import FlagTicket from "../Components/FoodServices/Components/FlagTicket";
import DefaultLogo from "../Assets/Images/default-preview-logo.png"
import InactiveBUtton from "../Assets/Images/Inactive Button.svg";
import ActiveButton from "../Assets/Images/food-service-accept-btn.svg";
import {
  useGetRefreshTokenMutation
} from "../Services/CommonAPI"
import Swal from 'sweetalert2';

import BlackBg from "../Assets/Images/default_video_img.png"
import DefaultPdfImg from "../Assets/Images/defaultpdf-img.png"
import DefaultpdfiMGColor from "../Assets/Images/pdf_default_img1.jpg"

const mapContainerStyle = {
  height: "600px",
  width: "100%",
  borderRadius: "20px",
};

const center = {
  lat: 52.3676, // Latitude for Amsterdam, Netherlands
  lng: 4.9041, // Longitude for Amsterdam, Netherlands
};

function PublicDirectory() {
  const [isActive, setIsActive] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { activeTab } = location.state || {};
  const { id } = useParams();
  const branchId = id;
  const { data, isLoading } = useGetAllBranchDetailsForallUserQuery({ branchId });
  const [showFlagTicket, setShowfalgTicket] = useState(false);
  const { data: mediagalleryData } = useGetMediaGallerymatchpreviewQuery(branchId);
  const branchId_current_selected = localStorage.getItem("branchId_current_selected")
  const { data: decisionData } = useGetFoodviewMoreDesicionQuery({ csbranchId: branchId_current_selected, fsBranchid: id })
  const [cstofsaccept, { }] = useCafeacceptfdMutation();
  const [cstofsdeclined, { }] = useCaferejectfdMutation();
  const convertTo12HourFormat = (time) => {
    if (!time) return ""; // Handle null or empty time values

    let [hours, minutes] = time.split(":");
    let suffix = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12; // Convert 0 hours to 12 for 12 AM/PM
    return `${hours}:${minutes} ${suffix}`;
  };

  // Function to render the time or "Holiday"
  const renderTime = (isAvailable, openingTime, closingTime) => {
    if (isAvailable) {
      return `${convertTo12HourFormat(openingTime)} - ${convertTo12HourFormat(
        closingTime
      )}`;
    } else {
      return "Closed";
    }
  };

  const { data: locations, error } = useGetpreviewbranchesMatchesQuery(id);
  const mapRef = useRef(null);
  useEffect(() => {
    // Load the Google Maps script
    const script = document.createElement("script");
    script.src =
      `https://maps.googleapis.com/maps/api/js?key=` +
      process.env.REACT_APP_GOOGLEMAP_API;
    script.async = true;
    script.onload = () => {
      // Initialize the map
      const australia = { lat: -25.2744, lng: 133.7751 };
      const map = new window.google.maps.Map(mapRef.current, {
        zoom: 4,
        center: australia,
      });

      // Check if locations is defined and is an array
      if (locations && Array.isArray(locations)) {
        // Add markers
        locations.forEach((location) => {
          new window.google.maps.Marker({
            position: location.position,
            map: map,
            title: location.title,
          });
        });
      } else {
        console.error("Locations data is unavailable or not an array.");
      }
    };
    document.head.appendChild(script);
  }, [locations]);


  const { data: branchListData } = useGetBranchListQuery();
  const goBackToTab = () => {
    navigate(-1);
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleClose = () => {
    toggleMenu();
    navigate(-1);
  };
  const handleShowTicket = () => {
    setShowfalgTicket(true);
  }
  const handleclose = () => {
    setShowfalgTicket(false);
  }

  const [submitRefreshToken, { isSuccessRefreshToken, isError: isErrorRefreshToken }] =
    useGetRefreshTokenMutation();
  const [refreshToken, setRefreshToken] = useState("");
  const [refreshtokenNew, setRefreshTokenNew] = useState("")
  const [decision, setDesicion] = useState(false);



  useEffect(() => {
    setDesicion(decisionData?.status);
  }, [decisionData])

  const handleSubmitRefreshToken = async () => {
    const RefreshToken = localStorage.getItem("refreshToken")
    const postData = { refresh: RefreshToken };
    try {
      const response = await submitRefreshToken(postData).unwrap();
      if (response) {

        localStorage.setItem("accessToken", response.access);
        localStorage.setItem("refreshToken", response.refresh);
      }
    } catch (err) {
      console.error("Failed to refresh token:", err);
    }
  };

  useEffect(() => {

    if (isErrorRefreshToken) {
      navigate("/cafe-login");
      localStorage.clear();
    }
  }, [isErrorRefreshToken])

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSubmitRefreshToken();
    }, 15000); // 10 seconds

    return () => clearInterval(intervalId);
  }, [refreshToken, refreshtokenNew]);

  const handleRedirectoChat = () => {
    navigate("/eco-enquiry", {
      state: {
        sender_type: "food_servive",
        sender_id: branchId
      },
    });
  }

  const handleInactiveClick = async () => {
    const postdata = {
      fs_branch_id: id,
      status: "declined",
    };
    const currentbranchbranchId = branchId_current_selected;
    try {
      const response = await cstofsaccept({
        postdata,
        currentbranchbranchId
      }).unwrap();
      if (response) {

        // alert("Listing removed from matches and added to declined list.");
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Listing removed from matches and added to declined list.",
        });
      }
    } catch (err) {
      // alert("Failed to decline the listing");
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to decline the listing",
      });
    }
  };

  const handleActiveClick = async () => {
    const postdata = {
      fs_branch_id: id,
      status: "accepted",
    };
    const currentbranchbranchId = branchId_current_selected;
    try {
      const response = await cstofsdeclined({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {
        setIsActive(true);
        setIsHidden(false);

      }
    } catch (err) {
      // alert("Failed to Accept");
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to Accept",
      });
    }
  };

  return (
    <>
      {/* <DirectoryListingNavbar /> */}
      <nav className="navbar public-directory-nav">
        <div className={`nav-links ${isOpen ? "open" : ""}`}>

          <a onClick={goBackToTab}>
            <img src={CloseIcon} alt={CloseIcon} /> Close
          </a>
          {/* <a href="/directory-listing"><img src={CloseIcon} alt={CloseIcon} /> Close</a> */}
        </div>
        <div className="nav-toggle" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </nav>
      <div className="public-directory trades-service foodervice">
        <section
          className="public-directory-banner"
          style={{
            backgroundImage: `url(${data?.additional_detail.cover_image || defaultcoverImage
              })`,
          }}
        >
          {/* <div>
            <img src={LikesImg} alt="LikesImg" className="like-img" />
            <div className="like-count"> {data?.branch?.likes_count}</div>
          </div> */}
          <div className="ecosystem-recommendation-tooltip">
            <div class="tooltip-common">
              <img src={LikesImg} alt="LikesImg" className="like-img" />
              <div className="like-count"> {data?.branch?.likes_count}</div>
              <span class="tooltip-commontext">
                Ecosystem Recommendations
              </span>
            </div>
          </div>
        </section>
        <section className="about-trades-service">
          <Row>
            <Col sm={12} md={8} lg={8}>
              <div className="left-sec">
                <Row>
                  <Col md={6} lg={6} sm={12} className="text-start">
                    <div className="d-flex">
                      <div>
                        <img
                          src={data?.additional_detail?.logo || DefaultLogo}
                          alt="DReamCfeImg"
                          className="priview-logo"
                        />
                      </div>
                      <div>
                        <h3>{data?.branch?.business_name}</h3>
                        <p>{data?.branch.address}</p>
                        <p>{data?.branch.location}</p>
                        <p>{data?.branch.abn}</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <div className="text-end about-right">
                      {data?.branch.email && <p className="email">
                        <img src={MailIcon} alt="MailIcon" />{" "}
                        {data?.branch.email}
                      </p>}
                      {data?.branch.contact_number && <p className="phonum">
                        <img src={PhoneIcon} alt="PhoneIcon" />
                        {data?.branch.contact_number}
                      </p>}
                      {data?.branch.linkedin && <p>
                        <img src={LinkIcon} alt="LinkIcon" />
                        {data?.branch.linkedin}
                      </p>}
                    </div>
                  </Col>
                </Row>
                <Row className="bottom-sec">
                  <h5>About</h5>
                  <p>{data?.additional_detail?.min_bio}</p>
                  <Row className="align-flex-end">
                    <Col sm={12} lg={9} md={9}>
                      <div className="social-media-icons mt-24">
                        <a href={data?.branch?.facebook} target="_blank">
                          {" "}
                          <img src={Facebook} alt="Facebook" />
                        </a>
                        <a href={data?.branch?.twitter} target="_blank">
                          {" "}
                          <img src={Twitter} alt="Twitter" />
                        </a>
                        <a href={data?.branch?.linkedin} target="_blank">
                          {" "}
                          <img src={Linkdin} alt="Linkdin" />
                        </a>
                        <a href={data?.branch?.youtube} target="_blank">
                          {" "}
                          <img src={Youtube} alt="Youtube" />
                        </a>
                        <a href={data?.branch?.instagram} target="_blank">
                          {" "}
                          <img src={Instagram} alt="Instagram" />
                        </a>
                      </div>
                    </Col>
                    <Col sm={12} lg={3} md={3} className="text-end p-0">
                      {decision !== 'accepted' && (
                        <div className="d-flex justify-content-end">

                          {!isActive ? (
                            <>
                              <button onClick={handleInactiveClick} className="accept-reject-btn">
                                <img src={InactiveBUtton} alt="InactiveButton" />
                              </button>
                              <button onClick={handleActiveClick} className="accept-reject-btn">
                                <img src={ActiveButton} alt="ActiveButton" />

                              </button>
                            </>
                          ) : (
                            <button className="msg-send-btn" onClick={handleRedirectoChat}>
                              <img src={Sendbtn} alt="Sendbtn" />
                              Message
                            </button>
                          )}
                        </div>
                      )}
                      {decision === 'accepted' && (
                        <button className="msg-send-btn" onClick={handleRedirectoChat}>
                          <img src={Sendbtn} alt="Sendbtn" />
                          Message
                        </button>
                      )}
                    </Col>
                  </Row>
                </Row>
                <img src={Elipse1} alt="elipse" className="elipse1" />
                <img src={Elipse2} alt="elipse" className="elipse2" />
                <img src={Elipse3} alt="elipse" className="elipse3" />
                <img src={Elipse4} alt="elipse" className="elipse4" />
              </div>
            </Col>
            <Col sm={12} md={4} lg={4}>
              <div className="service-category">
                <div className="service-data">
                  <img src={ServiceCategory} alt="ServiceCategory" />
                  <div className="categories">
                    {data?.additional_detail?.service_categories?.map(
                      (category) => (
                        <button>{category.name}</button>
                      )
                    )}
                  </div>
                  {/* <button className="see-all"> See all</button> */}
                </div>
              </div>
            </Col>
          </Row>
        </section>
        {data?.user_branches?.length > 0 && <section className="other-location service-states">
          <h4>Servicing states</h4>
          <Row>
            {data?.user_branches?.map((branch) => (
              <Col sm={12} lg={3} md={3}>
                <div className="card">
                  <h5> {branch.location} </h5>
                  <p> {branch.address} </p>
                </div>
              </Col>
            ))}
          </Row>
          {/* <Row className="text-center">
            <button className="see-all-btn">See all</button>
          </Row> */}
          <img
            src={OtherLocationElipseImg}
            alt="OtherLocationElipseImg"
            className="other-location-elipseimg"
          />
        </section>}
        <section className="media-gallery">
          <Tabs defaultActiveKey="images" id="cafe-gallery-tabs">
            <Tab eventKey="images" title="Images">
              <Row>
                {data?.additional_detail?.images
                  .slice(0, 2)
                  .map((img, index) => (
                    <Col sm={12} md={6} lg={6} key={img.id}>
                      <img
                        src={img.image}
                        alt={`Cafe Image ${index + 1}`}
                        className="cafe-images"
                      />
                    </Col>
                  ))}
              </Row>
            </Tab>
            <Tab eventKey="media" title="Media Gallery">
              {/* <Row>
                <Col sm={12} md={6} lg={6}>
                  <img src={CafeImg1} alt="Cafe 1" className="cafe-images" />
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <img src={CafeImg2} alt="Cafe 2" className="cafe-images" />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={12} md={6} lg={6}>
                  <img src={CafeImg3} alt="Cafe 3" className="cafe-images" />
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <img src={CafeImg5} alt="Cafe 5" className="cafe-images" />
                </Col>
              </Row> */}
              <Row>
                {mediagalleryData && mediagalleryData.length > 0 ? (mediagalleryData?.map((file, index) => (<Col sm={12} md={6} lg={6}>
                  <a href={file.file} target="_blank">
                <div className="card-container">
                  <div className="card">


                    {file.file_type === "pdf" ? (
                      <>
                        <div className="card-image-overlay-approved">

                        </div>
                        <div className="media-gallery-card-text">

                          <p className="gallery-title-approved-pdf">{file.file_name}</p>

                        </div>
                        <div className="pdf-icon-div">

                          {/* <iframe
                       src={file.file}
                       frameBorder="0"
                       style={{
                         
                         height: "100%",
                         border: "none",
                       }}
                       scrolling="no"
                     ></iframe> */}
                          <img src={DefaultpdfiMGColor} className="pdf-icon-media-gallery" />

                        </div>
                      </>



                    ) : file.file_type === "video" ? (
                      <>
                        <div className="card-image-overlay-approved">

                        </div>
                        <div className="media-gallery-card-text">

                          <p className="gallery-title-approved">{file.file_name}</p>

                        </div>
                        <div style={{ overflow: "hidden", height: "500px", width: "100%" }}>
                          <img
                            src={BlackBg}
                            alt="Video thumbnail"
                            className="card-image"
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </a>
                </Col>))) : null}
              </Row>
            </Tab>
          </Tabs>
        </section>
        <section className="restaurant-details">
          <h5>Timing</h5>
          <div className="table-div">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Monday</th>
                  <th scope="col">Tuesday</th>
                  <th scope="col">Wednesday</th>
                  <th scope="col">Thursday</th>
                  <th scope="col">Friday</th>
                  <th scope="col">Saturday</th>
                  <th scope="col">Sunday</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {renderTime(
                      data?.additional_detail?.monday_availability,
                      data?.additional_detail?.monday_opening_time,
                      data?.additional_detail?.monday_closing_time
                    )}
                  </td>
                  <td>
                    {renderTime(
                      data?.additional_detail?.tuesday_availability,
                      data?.additional_detail?.tuesday_opening_time,
                      data?.additional_detail?.tuesday_closing_time
                    )}
                  </td>
                  <td>
                    {renderTime(
                      data?.additional_detail?.wednesday_availability,
                      data?.additional_detail?.wednesday_opening_time,
                      data?.additional_detail?.wednesday_closing_time
                    )}
                  </td>
                  <td>
                    {renderTime(
                      data?.additional_detail?.thursday_availability,
                      data?.additional_detail?.thursday_opening_time,
                      data?.additional_detail?.thursday_closing_time
                    )}
                  </td>
                  <td>
                    {renderTime(
                      data?.additional_detail?.friday_availability,
                      data?.additional_detail?.friday_opening_time,
                      data?.additional_detail?.friday_closing_time
                    )}
                  </td>
                  <td>
                    {renderTime(
                      data?.additional_detail?.saturday_availability,
                      data?.additional_detail?.saturday_opening_time,
                      data?.additional_detail?.saturday_closing_time
                    )}
                  </td>
                  <td>
                    {renderTime(
                      data?.additional_detail?.sunday_availability,
                      data?.additional_detail?.sunday_opening_time,
                      data?.additional_detail?.sunday_closing_time
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr></hr>
          <Row className="menu-download">
            <Col sm={12} md={12} lg={12}>
              <h5>Delivery details</h5>
              <p>
                {data?.additional_detail?.delivery_available ? (
                  <>
                    Delivery <span>Available</span> Range
                    <span>
                      0km to {data?.additional_detail?.delivery_range}km
                    </span>
                  </>
                ) : (
                  <>
                    Delivery <span>Not Available</span>
                  </>
                )}
                Service type<span>Remote</span>
              </p>
            </Col>
          </Row>
        </section>

        <section className="additional-details">
          <h4>Additional Details</h4>
          <div className="d-flex additional-data">
            <div className="left-sec">
              <img src={ProducttypeImg} alt={ProducttypeImg} />
            </div>
            <div className="right-sec">
              {data?.additional_detail?.product_types?.map((type) => (
                <button>{type.name}</button>
              ))}
            </div>
          </div>

          <div className="d-flex additional-data">
            <div className="left-sec">
              <img src={SpecialDietryImg} alt={SpecialDietryImg} />
            </div>
            <div className="right-sec">
              {data?.additional_detail?.specialist_dietary_services?.map(
                (type) => (
                  <button>{type.name}</button>
                )
              )}
            </div>
          </div>
        </section>

        <section className="additional-details">
          <h4>Interests</h4>
          <div className="d-flex additional-data">
            <div className="left-sec">
              <img src={ExtraSupportImg} alt={ExtraSupportImg} />
            </div>
            <div className="right-sec">
              {data?.ecosystem_criteria?.ce_features?.map((type) => (
                <button>{type.name}</button>
              ))}
            </div>
          </div>


          <div className="d-flex additional-data">
            <div className="left-sec">
              <img src={WorkSupport} alt={WorkSupport} />
            </div>
            <div className="right-sec">
              {data?.ecosystem_criteria?.ce_operational_status?.map((type) => (
                <button>{type.name}</button>
              ))}
            </div>
          </div>
          <div className="d-flex additional-data">
            <div className="left-sec">
              <img src={MenuSupport} alt={MenuSupport} />
            </div>
            <div className="right-sec">
              {data?.ecosystem_criteria?.ce_specialist_dietary_services?.map((type) => (
                <button>{type.name}</button>
              ))}
            </div>
          </div>
          {/* <div className="d-flex additional-data">
            <div className="left-sec">
              <img src={StateSupprt} alt={StateSupprt} />
            </div>
            <div className="right-sec">
              {data?.ecosystem_criteria?.ce_state?.map((type) => (
                <button>{type.name}</button>
              ))}
            </div>
          </div> */}
        </section>

        <section className="evets-reviews">
          <h4>Events & Reviews</h4>
          <Row>
            <Col md={6} lg={6} sm={12}>
              <h5>Upcoming events</h5>
              <hr></hr>
              <hr></hr>
              {data?.additional_detail?.events?.map((event, index) => (
                <div
                  key={event.id}
                  className={index % 2 === 0 ? "div1" : "div2"}
                >
                  <p>
                    <a
                      href={event.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {event.title}
                    </a>
                  </p>
                </div>
              ))}
            </Col>
            <Col md={6} lg={6} sm={12}>
              <h5> Online reviews</h5>
              <hr></hr>
              {data?.additional_detail?.reviews?.map((review, index) => (
                <div
                  key={review.id}
                  className={index % 2 === 0 ? "div1" : "div2"}
                >
                  <p>
                    <img
                      src={
                        review.platform_name === "Google Reviews"
                          ? GoogleImg
                          : review.platform_name === "Facebook Reviews"
                            ? FacebookImg
                            : Trustpilot
                      }
                      alt={review.platform_name}
                    />
                    <a
                      href={review.review_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {review.platform_name}
                    </a>
                  </p>
                </div>
              ))}
            </Col>
          </Row>
        </section>
        <FlagTicket
          show={showFlagTicket}
          handleCloseAddNewModal={handleclose}
        />
        <section className="googlemap-location">
          <h4>Location</h4>
          <div ref={mapRef} style={mapContainerStyle}></div>
        </section>
      </div>
    </>
  );
}

export default PublicDirectory;
