import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import Logo from "../Assets/Images/logo-white.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "../Styles/Footer.css";
import Facebook from "../Assets/Images/facebook black.1.svg";
import Twitter from "../Assets/Images/twitter black.1.svg";
import Linkdin from "../Assets/Images/linkedin black.1.svg";
import Instagram from "../Assets/Images/instagram black.1.svg";
import Youtube from "../Assets/Images/youtube color.1.svg";
import Googleplus from "../Assets/Images/googleplus black.1.svg";
import Rss from "../Assets/Images/rss black.1.svg";
import star from "../Assets/Images/star.svg";
import Greenstar from "../Assets/Images/green-star.svg";
import { useSubmitInviteaFriendMutation } from "../Services/CommonAPI";
import PartnerShipLogo from "../Assets/Images/partnership-logo.png"
import AssociationwithLogo from "../Assets/Images/square_img.png"

import TermsandCondition from "../Assets/pdf/Termsandconditions.pdf"
import PrivacyPolicy from "../Assets/pdf/Privacypolicy.pdf"
import { useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import { HashLink } from "react-router-hash-link";

function Footer() {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    email: "",
  });

  const [submitForm, { isLoading, isError, isSuccess, error }] =
  useSubmitInviteaFriendMutation();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const formErrors = {};
    if (!formData.email) formErrors.email = "Email is required.";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        const yOffset = -100; // Offset to leave 50px space above the element
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let postData = {
        email: formData.email,
      };
      try {
        const response = await submitForm(postData).unwrap();
       
        Swal.fire({
          icon: 'success',
          title: 'Submission Successful!',
          text: 'Invitation sent successfully',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        setFormData({
          email: "",
        });
      } catch (err) {
        console.error("Failed to submit form:", err);
        alert("Failed to submit");
      }
    }
  };


  const scrollWithOffset = (el) => {
    const yOffset = -100; // Adjust this value based on your header height
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };
  

  return (
    <footer className="section">
      <Row className="text-center footer-logo">
        <img src={Logo} />
        <p>
          Clever Cafe is a specialised networking, growth, & support platform
          tailored for cafe entrepreneurs, foodservice providers, and trades &
          services, empowering the Australian cafe sector. Our services are
          ethical, responsive, & authentic, delivered by hospitality experts
          with firsthand industry experience and a diverse range of passionate
          individuals dedicated to making a significant impact in Australian
          hospitality.
        </p>
        <p>
          Clever Cafe acknowledges the traditional owners of the land on which
          we work and live. We pay our respects to Elders past, present and
          emerging and celebrate the food, stories and culture of all First
          Nations, Aboriginal and Torres Strait communities.
        </p>
        <p className="excellent-text">
          <b>Excellent</b>
          <span className="excellent-star">
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} className="half-bg-star" />
          </span>
          <b>14 </b>reviews on{" "}
          <img src={Greenstar} className="green-star" alt="star" />
          <a href="https://www.trustpilot.com/review/clevercafecompany.com.au" target="_blank"><b>Trustpilot</b></a>
        </p>
      </Row>
      <div className="share-email">
        <Row>
          <Col sm={12} md={6} lg={6}>
            <p>
              Know someone who’d love Clever Cafe? Invite them to join our{" "}
              <span className="disply-blk">community!</span>
            </p>
          </Col>
          <Col sm={12} md={6} lg={6} className="text-end">
            <form onSubmit={handleSubmit}>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="abcd123@gmail.com"
                  onChange={handleChange}
                  value={formData.email}
                />

                <button type="submit" disabled={isLoading}>{isLoading? "Submitting" : "Invite a Friend"}</button>
              </div>
              {errors.email && <p className="error">{errors.email}</p>}
            </form>
          </Col>
        </Row>
      </div>

      <Row className="footer-links">
        <Col sm={12} md={3} lg={3} className="text-left">
          <ul>
            <h5>Company</h5>
            <Link to="/about-us">
              <li> About Us</li>
            </Link>
            <Link to="/articles">
              <li>Articles</li>
            </Link>

            <Link to="/contact-us">  <li>Contact</li></Link>
            <a href={TermsandCondition} target="_blank"><li>Terms and Conditions</li></a>
            <a href={PrivacyPolicy} target="_blank">  <li>Privacy Policy</li></a>
          </ul>
        </Col>
        <Col sm={12} md={3} lg={3} className="text-left">
          <ul>
            <h5>Foodservices</h5>
            <HashLink to="/service-food#section1" scroll={scrollWithOffset}>
              <li>Directory & Ecosystem</li>
            </HashLink>
            <HashLink to="/service-food#section2" scroll={scrollWithOffset}>
              <li>Easy Networking</li>
            </HashLink>
            <HashLink to="/service-food#section3" scroll={scrollWithOffset}>
              {/* <li>Marketing & Exposure</li> */}
              <li>Review Boosting</li>
            </HashLink>
            <HashLink to="/service-food#section4" scroll={scrollWithOffset}> <li>Customer Education</li></HashLink>
            <HashLink to="/service-food#section5" scroll={scrollWithOffset}><li>Event Promotion</li></HashLink>
            <HashLink to="/service-food#section6" scroll={scrollWithOffset}><li>Cafe Account Application</li></HashLink>
          </ul>
        </Col>
        <Col sm={12} md={3} lg={3} className="text-left">
          <ul>
            <h5>Cafe Entrepreneurs</h5>
            <HashLink to="/service-cafe-startup#section1" scroll={scrollWithOffset}><li >Cafe Marketing & Exposure</li></HashLink>
            <HashLink to="/service-cafe-startup#section2" scroll={scrollWithOffset}> <li>Cafe Startups</li></HashLink>
            <HashLink to="/service-cafe-startup#section3" scroll={scrollWithOffset}> <li>Cafe Profitability</li></HashLink>
            <HashLink to="/service-cafe-startup#section4" scroll={scrollWithOffset}>  <li>Cafe Expansion</li></HashLink>
            <HashLink to="/service-cafe-startup#section5" scroll={scrollWithOffset}><li>Cafe Business Planning</li></HashLink>
            <HashLink to="/service-cafe-startup#section6" scroll={scrollWithOffset}> <li>Cafe Mentorship</li></HashLink>
            <HashLink to="/service-cafe-startup#section7" scroll={scrollWithOffset}> <li>Cafe Menu Development</li></HashLink>
            <HashLink to="/service-cafe-startup#section8" scroll={scrollWithOffset}>   <li>Cafe Foodservice Partners</li></HashLink>
            <HashLink to="/service-cafe-startup#section9" scroll={scrollWithOffset}>   <li>Cafe Trades & Services Partners</li></HashLink>
            <HashLink to="/service-cafe-startup#section10" scroll={scrollWithOffset}>   <li>Cafe Resources</li></HashLink>
          </ul>
        </Col>
        <Col sm={12} md={3} lg={3} className="text-left">
          <ul>
            <h5>Trades & Services</h5>
            <HashLink to="/service-trade#section1" scroll={scrollWithOffset}><li>Directory & Ecosystem</li></HashLink>
            <HashLink to="/service-trade#section2" scroll={scrollWithOffset}><li>Easy Networking</li></HashLink>
            <HashLink to="/service-trade#section3" scroll={scrollWithOffset}><li>Review Boosting</li></HashLink>
            <HashLink to="/service-trade#section4" scroll={scrollWithOffset}> <li>Training & Education</li></HashLink>
            <HashLink to="/service-trade#section5" scroll={scrollWithOffset}> <li>Event Promotion</li></HashLink>
            <HashLink to="/service-trade#section6" scroll={scrollWithOffset}>  <li>Service Account Application</li></HashLink>
          </ul>
        </Col>
      </Row>

      <div className="footer-bottom" id="footer">
        <Row className="align-items-center">
          <Col sm={12} md={4} lg={4} className="text-start">
            <a href="https://www.linkedin.com/company/clevercafecompany/" target="_blank"><img
              src={Linkdin}
              alt="social-media-icon"
              className="social-media-icons"
            /></a>
            <a href="https://www.facebook.com/clevercafeau/" target="_blank"><img
              src={Facebook}
              alt="social-media-icon"
              className="social-media-icons"
            /></a>
            <a href="https://www.instagram.com/clevercafeau/ " target="_blank" ><img
              src={Instagram}
              alt="social-media-icon"
              className="social-media-icons"
            /></a>
            <a href="https://www.youtube.com/@clevercafeau" target="_blank">
              <img
                src={Youtube}
                alt="social-media-icon"
                className="social-media-icons"
              />
            </a>
          </Col>
          <Col sm={12} md={4} lg={4} className="text-center in-partnership">
            <a href="https://squareup.com/au/en/point-of-sale/coffee-shop" target="_blank">
              <div className="d-flex align-items-center placement-content-center">

                <h6 className="p-0"> Preferred Payments & POS Partner</h6>
                <img src={AssociationwithLogo} alt="PartnerShipLogo" width={90} />

              </div>
            </a>
          </Col>
          <Col sm={12} md={4} lg={4} className="text-end in-partnership">
          <a href="https://www.rca.asn.au/" target="_blank">
            <div className="d-flex align-items-center placement-content-end">
              <h6>In Partnership With</h6>
              <img src={PartnerShipLogo} alt="PartnerShipLogo" width={90} />
            </div>
            </a>
          </Col>

        </Row>
        <Row>
          <Col sm={12} md={12} lg={12} className="text-center mt-40">
            <p>© 2024 Clever Cafe Company. All rights reserved.</p>
          </Col>
        </Row>
      </div>
    </footer>
  );
}

export default Footer;
