import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Star from "../Assets/Images/trustpioletstar.svg";
import PartnerShipLogo from "../Assets/Images/partnership-logo.png";
import AssociationwithLogo from "../Assets/Images/square_img.png";
import { useSubmitInviteaFriendMutation } from "../Services/CommonAPI";
import { Color } from "pspdfkit";

function Footer() {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    email: "",
  });
  const [visibleDiv, setVisibleDiv] = useState(null);
  const [submitForm, { isLoading }] = useSubmitInviteaFriendMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const formErrors = {};
    if (!formData.email) formErrors.email = "Email is required.";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const toggleDiv = (divName) => {
    if (visibleDiv === divName) {
      setVisibleDiv(null);
    } else {
      setVisibleDiv(divName);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await submitForm({ email: formData.email }).unwrap();
        Swal.fire({
          icon: "success",
          title: "Submission Successful!",
          text: "Invitation sent successfully",
          confirmButtonText: "OK",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        setFormData({ email: "" });
      } catch (err) {
        console.error("Failed to submit form:", err);
        Swal.fire({
          icon: "error",
          title: "Submission Failed",
          text: "Please try again later.",
          confirmButtonText: "OK",
        });
      }
    }
  };

  return (
    <footer className="footer-dashboard mt-auto py-3">
      <div className={window.innerWidth >= 768 ? "container-fluid" : ""}>

        <div className="d-flex portal-footer">
          <div
            className={`partners ${visibleDiv === "partners" ? "visible" : ""}`}
            style={{ display: visibleDiv === "partners" || window.innerWidth >= 768 ? "block" : "none" }}
          >
            <div className="d-flex footer-item-logos portal-footer-btm-logo">
              <a
                href="https://squareup.com/au/en/point-of-sale/coffee-shop"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="d-flex align-items-center placement-content-center">
                  <h6 className="p-0">Preferred Payments & POS Partner</h6>
                  <img src={AssociationwithLogo} alt="PartnerShipLogo" width={30} 
                  style={{marginRight:"10px"}}
                  />
                </div>
              </a>
              <a
                href="https://www.rca.asn.au/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="d-flex align-items-center placement-content-end">
                  <h6>In Partnership With</h6>
                  <img src={PartnerShipLogo} alt="PartnerShipLogo" width={30} />
                </div>
              </a>
            </div>
          </div>

          <div
           className={`invite ${visibleDiv === "invite" ? "visible" : ""}`}
           style={{ display: visibleDiv === "invite" || window.innerWidth >= 768 ? "block" : "none" }}
          >
            <div className="left-sec d-flex align-row">
              <p>
                Know someone who’d love Clever Cafe? Invite them to join our
                community!
              </p>
              <form onSubmit={handleSubmit} className="form-inline">
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    placeholder="abcd123@gmail.com"
                    onChange={handleChange}
                    value={formData.email}
                    className="email-input"
                  />
                  <button type="submit" disabled={isLoading} className="invite-btn">
                    {isLoading ? "Submitting" : "Invite a Friend"}
                  </button>
                </div>
                {errors.email && <p className="error">{errors.email}</p>}
              </form>
            </div>
          </div>

          <div className="d-flex web-links">
            <div className="right-sec">
            <span
              className="action toggle-mobile text-white"
              onClick={() => toggleDiv("invite")}
              style={{
                marginRight: "5px",
                fontSize: "11px",
                marginLeft: "-6px",
              }}
            >
              <span style={{ paddingRight: "3px" }}>Invite a Friend</span>

              {/* Conditionally render up/down chevron for invite */}
              {visibleDiv === "invite" ? (
                <svg
                style={{ width: "12px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-down"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                />
              </svg>
              ) : (
                <svg
                  style={{ width: "12px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-chevron-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                  />
                </svg>
              )}
            </span>

            <span
              className="action toggle-mobile text-white"
              onClick={() => toggleDiv("partners")}
              style={{
                marginRight: "5px",
                fontSize: "11px",
              }}
            >
              <span style={{ paddingRight: "3px" }}>Our Partners</span>

              {/* Conditionally render up/down chevron for partners */}
              {visibleDiv === "partners" ? (

                <svg
                style={{ width: "12px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-down"
                viewBox="0 0 16 16"
                >
                <path
                  fillRule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                />
                </svg>
                
              ) : (
                
              <svg
                style={{ width: "12px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-up"
                viewBox="0 0 16 16"
              >
              <path
                fillRule="evenodd"
                d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
              />
              </svg>
              )}
            </span>


              <Link to="/articles" target="_blank" className="style-q">
                Articles
              </Link>
              <Link to="/contact-us" target="_blank" className="style-q">
                Contact Us
              </Link>
              <a
                href="https://www.trustpilot.com/review/clevercafecompany.com.au"
                target="_blank"
                rel="noopener noreferrer"
                className="style-q"
              >
                <img
                  src={Star}
                  alt="Star"
                  className="trust-piolt-white-star"
                  style={{ width: "10px", height: "auto" }}
                />
                Trustpilot
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

