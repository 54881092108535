import React, { useState, useEffect } from "react";
import "../Styles/ServiceCafe.css";
import { Row, Col } from "react-bootstrap";
import KeyPointImg from "../Assets/Images/key-points.svg";
import ElipseBlue from "../Assets/Images/service-item-blue.svg";
import ElipsePink from "../Assets/Images/service-item-pink.svg";
import ElipseYellow from "../Assets/Images/service-item-ylw.svg";
import CafeMarketing from "../Assets/Images/cafe-marketing.png";
import CafeProfitability from "../Assets/Images/cafe-profitability.png";
import CafeExpansion from "../Assets/Images/cafe-expansion.png";
import CafeFoodservicePartner from "../Assets/Images/cafe-foodservice-partner.png";
import Caferesource from "../Assets/Images/cafe-resource.png";
import DirectoryEcoSystem from "../Assets/Images/direcory-ecosystem.png";
import TsDirectory from "../Assets/Images/service-images/TS Directory.png";
import TsMediaGallery from "../Assets/Images/service-images/TS - Media gallery.png";
import TsReviewBoosting from "../Assets/Images/service-images/TS Directory Bottom Section.png";
import TSMatches from "../Assets/Images/cafe-ts-match.png";
import TSAccountApplication from "../Assets/Images/ts-account-application.jpg";
import TSWasyNetworking from "../Assets/Images/service-images/TS Matches - Filter.png";
import EventPromotionIMg from "../Assets/Images/service-images/Eco enquiry - TS.png"
// import TsReviewBoosting from "../Assets/Images"
import Whatwedo from "../Assets/Images/trade-what-we-do.jpg";
import LeftElipse from "../Assets/Images/collabrator-leftellipse.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import LandingPagePopup from './LandingPagePopup'

function Services() {
  const location = useLocation();

  const [showLandingModal, setShowLandingModal] = useState(false);
  const handleCloseLandingModal = () => {
    setShowLandingModal(false);
  }

  useEffect(()=>{
    setTimeout(() => {
      setShowLandingModal(true);
    }, 4000);
  }, [])
  
  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
    zIndex: 999, // Ensures the overlay is on top
  };


  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        const yOffset = -100; // Offset to leave 50px space above the element
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [location]);


  return (
    <>
      <Helmet>
    <title>Clever Cafe | Your Online Hospitality Trade Expo | Expert Trades & Services Wanted for Australian Cafes</title>
    <meta name="description" content="Clever Cafe’s hospitality trade expo connects cafes with expert trades and services. Access live platform integrations, automated tools, and listings, and engage with industry leaders to enhance operations." />
    <meta name="keywords" content="food service australia, food expo australia, cafe business planning in australia, starting a cafe business in australia, australian cafe entrepreneurship" />
  </Helmet>
  <div className="service-cafe service-trade">
      <section className="service-banner">
        <div className="service-banner-content">
          <h2>
            Matching Trades & Services with New Cafe Customers at The Right Time
          </h2>
          <p>
            Leveraging our network of industry experts, we match the specialised
            skills of trades and service providers to the needs of start-up and
            established cafes. Just by joining our directory, we will start
            directing customers to you.
          </p>
          <p>
            Watch this <b><a href="https://youtu.be/3ML6A294uhM" target="_blank">quick video </a></b> to see how we create value from the moment
            you sign up for your free trial. Find more details below.
          </p>
          {/* <p>Watch this <a href="/" target="_blank">short video </a>to see how we add value by saving you time, money, and effort. </p> */}
        </div>
      </section>
      <section>
        <div className="home-what-we-do">
          <div className="what-we-do">
            <Row>
              <Col sm={12} lg={8} md={8}>

                <div className="head-desk">
                  <h3>Let us connect you with the right</h3>
                  <h3>cafe owners at the right time.</h3>
                </div>
                <div className="head-mob">
                  <h3>Let us connect you with the right cafe owners at the right time.</h3>
                </div>

                <Row>
                  <Col sm={12} lg={6} md={6}>
                    <div className="cotent-element">
                      <h4>01</h4>
                      <h4>Save Time</h4>
                      <p>
                        We’ll match you directly with business owners in your
                        location who need your services. These customers are
                        educated and realistic about their needs.
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6} md={6}>
                    <div className="cotent-element">
                      <h4>02</h4>
                      <h4> Don’t Miss Opportunities</h4>
                      <p>
                        Have a direct line to both start-up businesses in their
                        pre-launch stage, as well as established businesses
                        optimising their cafe. Have customers reach out to you
                        directly, or look for potential leads within the
                        directory.
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6} md={6}>
                    <div className="cotent-element">
                      <h4>03</h4>
                      <h4>Less Risk</h4>
                      <p className="mb-50">
                        Customers you match with via Clever Cafe will be
                        well-educated in the industry, have an expertly audited
                        menu, and a vetted business model that is likely to
                        succeed and continue paying their suppliers on time.
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6} md={6}>
                    <div className="cotent-element">
                      <h4>04</h4>
                      <h4>Easy to Follow</h4>
                      <p>
                        Simply enter your business details and we’ll start
                        sending well-suited customers your way. You can even
                        create a digital showcase alongside your listing to
                        display what your business offers.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} lg={4} md={4}>
                <img src={Whatwedo} alt="Whatwedo" className="what-we-do-img" />
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <section className="service-items" id="section1">
        <div className="card">
          <h3>Directory & Ecosystem</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                This isn’t your typical listing. It’s crafted to showcase your
                business in a more impactful way, cutting through the noise and
                highlighting what makes you stand out. Update your listing
                anytime to ensure your customers always have the latest
                information.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                “Clever Cafe is making an impactful presence in the trades and
                services sector within the hospitality industry, providing small
                businesses with incredible opportunities to network and grow.”
              </p>
              <p className="testimonial-owner">John</p>
              <p className="testimonial-owner">Director,</p>
              <p className="testimonial-owner">Good Time Clean Pty Ltd.</p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} /> make a powerful first impression
                </li>
                <li>
                  <img src={KeyPointImg} /> target specific cafe owners & states
                </li>
                <li>
                  <img src={KeyPointImg} /> showcase your unique brand
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid" />
          <img src={ElipseYellow} className="elipse-yellow img-fluid" />
          <div className="boost-visibility">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={TsDirectory}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>Join our ecosystem & become easily discoverable </h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Join our ecosystem
                  </button>{" "}
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section2">
        <div className="card">
          <h3>Easy Networking</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                Expos are valuable, but if you're looking for consistent,
                day-to-day support, our platform is the solution. You'll save
                time and resources with tailored matches in your ad-free space,
                providing continuous opportunities throughout the year.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                "I am excited about the new connections Clever Cafe will create
                for more synergy and a positive impact that supports small
                hospitality businesses."
              </p>
              <p className="testimonial-owner">Linda Kim</p>
              <p className="testimonial-owner">Director,</p>
              <p className="testimonial-owner">Founder of Spazii</p>
              <p className="testimonial-owner">Space & Catering Platform</p>
              <p className="testimonial-owner">Sydney, NSW</p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} /> high-quality partnerships, daily
                  support
                </li>
                <li>
                  <img src={KeyPointImg} /> automated criteria-based networking
                </li>
                <li>
                  <img src={KeyPointImg} /> 24/7 relevant connections
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid" />
          <img src={ElipseYellow} className="elipse-yellow img-fluid" />
          <div className="boost-visibility trade-easy-networking">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={TSWasyNetworking}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>Simplify networking with our online community.</h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">Connect now</button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      {/* <section className="service-items">
        <div className="card">
          <h3>Marketing & Exposure</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                Joining a targeted directory can build credibility and trust
                with your audience. Our focused cafe foodservice business
                directory helps with targeted advertising and exposure, making
                it easier to find cafe entrepreneurs who need your help at
                crucial times in their business journey. By improving your
                organic SEO, we ensure that our foodservice members are
                discovered by the ideal audience, powering menus while boosting
                customer and industry engagement. This enhances your local and
                national visibility, solidifying your role as a key partner in
                the cafe industry.
              </p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} /> boost your visibility across both
                  local and national markets
                </li>
                <li>
                  <img src={KeyPointImg} /> engage with cafe entrepreneurs at
                  crucial times
                </li>
                <li>
                  <img src={KeyPointImg} /> enhance your online presence and
                  build industry connections
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid" />
          <img src={ElipseYellow} className="elipse-yellow img-fluid" />
          <div className="boost-visibility">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={DirectoryEcoSystem}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Boost your visibility & connect with the right cafe
                  entrepreneurs.
                </h4>
                <button className="btn-boost-visibility">
                  Enhance visibility
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </section> */}

      <section className="service-items" id="section3">
        <div className="card">
          <h3>Review Boosting</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                Strengthen visibility & credibility with ecosystem
                recommendations & integrated review links. Attract cafe
                operators by simplifying customer feedback & engagement.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                “For years, I have been waiting for an innovative ecosystem that
                can ethically help cafe owners build profitability and an
                entrepreneurial mindset. Finally, someone has done it! I am
                confident that Clever Cafe will make a tangible difference to a
                part of the business community that truly needs help.”
              </p>
              <p className="testimonial-owner">Emmanuel Martin, CPA</p>
              <p className="testimonial-owner">General Manager</p>
              <p className="testimonial-owner">BREED Australia</p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  boost visibility with ecosystem recommendations
                </li>
                <li>
                  <img src={KeyPointImg} /> seamlessly connect reviews &
                  customer sharing
                </li>
                <li>
                  <img src={KeyPointImg} /> elevate digital credibility &
                  influence
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid" />
          <img src={ElipseYellow} className="elipse-yellow img-fluid" />
          <div className="boost-visibility">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={TsReviewBoosting}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Boost online credibility with our comprehensive ecosystem
                  features.
                </h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Increase reviews
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section4">
        <div className="card">
          <h3>Training & Education</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                Showcase your products and services by uploading brochures,
                price lists, and promotional materials that highlight special
                offers. Use the digital showcase to present your offerings,
                attract attention, and enhance interaction.
              </p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  highlight exclusive offers & drive sales
                </li>
                <li>
                  <img src={KeyPointImg} /> clarify your value & attract
                  customers
                </li>
                <li>
                  <img src={KeyPointImg} />
                  inclusive business showcase interview upon signup
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid" />
          <img src={ElipseYellow} className="elipse-yellow img-fluid" />
          <div className="boost-visibility mt-150">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={TsMediaGallery}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Supercharge your business awareness with our media gallery
                </h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Upload content
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section5">
        <div className="card">
          <h3>Event Promotion</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                Sharing your events, whether in-person or virtual, strengthens
                community and boosts engagement. Promote your activities, and we
                will regularly invite you to participate in relevant programs
                that align with your business.
              </p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  deepen customer relationships
                </li>
                <li>
                  <img src={KeyPointImg} /> increase visibility with streamlined
                  promotion
                </li>
                <li>
                  <img src={KeyPointImg} /> engage your ideal audience
                  effectively
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid" />
          <img src={ElipseYellow} className="elipse-yellow img-fluid" />
          <div className="boost-visibility mt-150">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={EventPromotionIMg}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>Promote your events effortlessly & boost engagement.</h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Promote your event
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section6">
        <div className="card">
          <h3>Service Account Application</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                We empower entrepreneurs with vetted business plans, audited
                menus, and advanced business management skills, fostering
                lasting relationships with trade and service partners. You can
                add links for cafe members to apply for service accounts,
                allowing direct registration when ready.
              </p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  educated, aware & savvy applicants
                </li>
                <li>
                  <img src={KeyPointImg} /> enhanced B2B alignment for clear
                  expectations
                </li>
                <li>
                  <img src={KeyPointImg} /> extra onboarding option for more
                  applicants
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid" />
          <img src={ElipseYellow} className="elipse-yellow img-fluid" />
          <div className="boost-visibility mt-150">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={TSAccountApplication}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Access assistance with onboarding from well-prepared cafe
                  applicants.
                </h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Streamline onboarding
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      {showLandingModal && (
        <div style={overlayStyle}></div>
      )}
      <LandingPagePopup showLandingModal={showLandingModal} handleCloseLandingModal={handleCloseLandingModal} />
    </div>
    </>
 
  );
}

export default Services;
