// import React, { useState } from "react";
// import "../Styles/HomeNewsLetter.css";
// import { Row, Col } from "react-bootstrap";
// import Gif1 from "../Assets/Images/home-team-gif1.gif";
// import Gif2 from "../Assets/Images/home-team-gif-2.gif";
// import { useMailchimpMutation } from "../Services/ArticleAPI";

// function HomeNewsLetter() {
//   const [formData, setFormData] = useState({
//     firstname: '',
//     lastname: '',
//     email: '',
//   });

//   const [errors, setErrors] = useState({}); // State for error messages

//   const [mailchimp, { isLoading, isError, isSuccess, error }] = useMailchimpMutation();

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });

//     // Clear any error message for the field being updated
//     setErrors({ ...errors, [name]: '' });
//   };

//   const validateForm = () => {
//     const formErrors = {};

//     // Required field validations
//     if (!formData.firstname) formErrors.firstname = "First Name is required.";
//     if (!formData.lastname) formErrors.lastname = "Last Name is required.";
//     if (!formData.email) formErrors.email = "Email is required.";

//     // Maximum length validations
//     if (formData.firstname.length > 50) {
//       formErrors.firstname = "Maximum length is 50 characters.";
//     }
//     if (formData.lastname.length > 50) {
//       formErrors.lastname = "Maximum length is 50 characters.";
//     }
//     if (formData.email.length > 100) {
//       formErrors.email = "Maximum length is 100 characters.";
//     }

//     setErrors(formErrors);
//     return Object.keys(formErrors).length === 0; // Returns true if no errors
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Validate the form
//     if (!validateForm()) {
//       return; // Prevent submission if there are errors
//     }

//     try {
//       const response = await mailchimp(formData).unwrap();
//       alert('Successfully subscribed to the newsletter!');
//     } catch (error) {
//       alert('Subscription failed. Please try again.');
//     }
//   };

//   const handleKeyPress = (e) => {
//     const char = String.fromCharCode(e.which);
//     // Allow only letters and spaces
//     if (!/^[a-zA-Z\s]*$/.test(char)) {
//       e.preventDefault(); // Prevent the default action if the character is not allowed
//     }
//   };


//   return (
//     <section className="section home-news-letter">
//       <img src={Gif1} alt="Gif1" className="gif-image1" />
//       <div className="news-letter-content">
//         <Row className="content-row">
//           <Col xs={12} lg={5} md={5} sm={12}>
//             <h4>
//               Subscribe to<span className="disply-blk">our newsletter</span>
//             </h4>
//             <p>
//               We'll serve up all the latest from Clever Cafe and the industry.
//             </p>
//           </Col>
//           <Col xs={12} lg={5} md={5} sm={12}>
//             <form onSubmit={handleSubmit}>
//               <Row>
//                 <Col xs={12}>
//                   <input
//                     type="text"
//                     placeholder="First Name"
//                     name="firstname"
//                     value={formData.firstname}
//                     onChange={handleInputChange}
//                     required
//                     onKeyPress={handleKeyPress}
//                   />
//                   {errors.firstname && <p className="error">{errors.firstname}</p>}
//                 </Col>
//                 <Col xs={12}>
//                   <input
//                     type="text"
//                     placeholder="Last Name"
//                     name="lastname"
//                     value={formData.lastname}
//                     onChange={handleInputChange}
//                     required
//                     onKeyPress={handleKeyPress}
//                   />
//                   {errors.lastname && <p className="error">{errors.lastname}</p>}
//                 </Col>
//                 <Col xs={12}>
//                   <input
//                     type="email"
//                     placeholder="Email"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleInputChange}
//                     required
//                   />
//                   {errors.email && <p className="error">{errors.email}</p>}
//                 </Col>
//                 <Col xs={12}>
//                   <button type="submit" disabled={isLoading}>
//                     {isLoading ? 'Submitting...' : 'Submit'}
//                   </button>
//                 </Col>
//               </Row>
//             </form>
//             {isError && <p>Error: {error?.data?.message || "An error occurred"}</p>}
//             {isSuccess && <p>Subscription successful!</p>}
//           </Col>
//         </Row>
//       </div>
//       <img src={Gif2} alt="Gif2" className="gif-image2" />
//     </section>
//   );
// }

// export default HomeNewsLetter;


import React, { useState, useEffect, useRef } from "react";
import "../Styles/HomeNewsLetter.css";
import { Row, Col } from "react-bootstrap";
import Gif1 from "../Assets/Images/home-team-gif1.gif";
import Gif2 from "../Assets/Images/home-team-gif-2.gif";
import { useMailchimpMutation } from "../Services/ArticleAPI";
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';

function HomeNewsLetter() {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
  });
  const [verified, setVerified] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [mailchimp, { isLoading, isError, isSuccess, error }] = useMailchimpMutation();
  const recaptchaRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!verified) {
      // alert("Please complete the reCAPTCHA verification.");
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please complete the reCAPTCHA verification.',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      return;
    }

    try {
      const response = await mailchimp({ ...formData, recaptchaToken }).unwrap();
      // alert('Successfully subscribed to the newsletter!');
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Successfully subscribed to the newsletter!',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      setFormData({
        firstname: '',
        lastname: '',
        email: '',
      });
      recaptchaRef.current.reset();
      setVerified(false);
    } catch (error) {
      console.log("error", error)
      // alert('Subscription failed. Please try again.');
      Swal.fire({
        icon: 'error',
        title: error?.data?.error?.title,
        text: error?.data?.error?.title === "Member Exists" 
          ? "This email is already subscribed to the mailing list." 
          : error?.data?.error?.detail,
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      
    }
  };
  useEffect(()=>{
if(isSuccess){
  setFormData({
    firstname: '',
    lastname: '',
    email: '',
  });
  recaptchaRef.current.reset();
  setVerified(false);
}
  },[isSuccess])

  const handleCaptcha = (value) => {
    setVerified(true);  // Set to true when successfully verified
  };


  return (
    <>
      {/* <Helmet>
    <title>Clever Cafe | Newsletter - No Filler, Just Essential Cafe & Hospitality Insights</title>
    <meta name="keywords" content="food service australia, food expo australia, cafe business planning in australia, australian cafe entrepreneurship ideas, how to run a successful cafe" />
    <meta name="description" content="Subscribe for industry-specific insights, practical tips, and updates designed to keep your cafe competitive in a rapidly changing marketplace." />
  </Helmet> */}
  <section className="section home-news-letter">
      <img src={Gif1} alt="Gif1" className="gif-image1" />
      <div className="news-letter-content">
        <Row className="content-row">
          <Col xs={12} lg={5} md={5} sm={12}>
            <h4>
              Subscribe to<span className="disply-blk">our newsletter</span>
            </h4>
            <p>We'll serve up all the latest from Clever Cafe and the industry.</p>
          </Col>
          <Col xs={12} lg={5} md={5} sm={12}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} lg={12} md={12} sm={12}>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleInputChange}
                    required
                  />
                </Col>
                <Col xs={12} lg={12} md={12} sm={12}>
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleInputChange}
                    required
                  />
                </Col>
                <Col xs={12} lg={12} md={12} sm={12}>
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Col>
                <Col xs={12} lg={12} md={12} sm={12}>
                <div className="recaptcha-container">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={handleCaptcha}
                    ref={recaptchaRef}
                  />
                </div>
                 
                </Col>
                <Col xs={12} lg={12} md={12} sm={12}>
                  <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Submitting...' : 'Submit'}
                  </button>
                </Col>
              </Row>
            </form>
            {isError && <p>Error: {error?.data?.message || "An error occurred"}</p>}
            {/* {isSuccess && <p>Subscription successful!</p>} */}
          </Col>
        </Row>
      </div>
      <img src={Gif2} alt="Gif2" className="gif-image2" />
    </section>
    </>

  );
}

export default HomeNewsLetter;