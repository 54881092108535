import React from "react";
import { Row, Col } from "react-bootstrap";
import Whatwedo from "../Assets/Images/cafe-membership-img.jpg";
import LeftElipse from "../Assets/Images/collabrator-leftellipse.svg"

function HomeWhatwedo() {
  return (
    <div className="home-what-we-do">
      <img src={LeftElipse} alt="LeftElipse" className="left-pattern-what-we-do" />
      <div className="what-we-do">
        <Row>
          <Col sm={12} lg={8} md={8}>
            {/* <h3>We do what our </h3>
            <h3>competitors do—before </h3>
            <h3 className="mb-40">breakfast (and better).</h3> */}
            <div className="head-desk">
              <h3>When you sign up for a </h3>
              <h3> Clever Cafe membership,</h3>
              <h3> you'll get a one-month </h3>
              <h3 className="mb-40"> free trial with instant benefits. </h3>
            </div>
            <div className="head-mob">
              <h3>When you sign up for a Clever Cafe membership,  you'll get a one-month free trial with instant benefits. </h3>
            </div>
            <Row>
              <Col sm={12} lg={6} md={6}>
                <div className="cotent-element">
                  <h4>01</h4>
                  <h4>More Value</h4>
                  <p>
                    Enjoy free onboarding, a menu review, and a directory listing - whether you're
                    just starting out or already established.
                  </p>
                </div>
              </Col>
              <Col sm={12} lg={6} md={6}>
                <div className="cotent-element">
                  <h4>02</h4>
                  <h4>More Time</h4>
                  <p>
                    Easily connect with the right food & trade service providers to streamline your business.
                  </p>
                </div>
              </Col>
              <Col sm={12} lg={6} md={6}>
                <div className="cotent-element">
                  <h4>03</h4>
                  <h4>Less Risk</h4>
                  <p className="mb-50">
                    Learn how to lower your expenses, improve your menu, optimise your operations,
                    and build a dedicated, motivated team.
                  </p>
                </div>
              </Col>
              <Col sm={12} lg={6} md={6}>
                <div className="cotent-element">
                  <h4>04</h4>
                  <h4>More Money</h4>
                  <p>
                    Discover how to build and run a sustainable cafe using
                    the same strategies as industry veterans.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={12} lg={4} md={4}>
            <img src={Whatwedo} alt="Whatwedo" className="what-we-do-img img-fluid" />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default HomeWhatwedo;
