import React, { useState ,useRef} from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAddMultipleBranchMutation, useUserGetEditQuery } from "../../../Services/FoodserviceAPI";
import "../Styles/AddAnotherBranch.css";

import {
  GoogleMap,
  useLoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";

const libraries = ["places"];

const ModalComponent = ({ show, handleCloseLocationModal,refetchCompletedLOcationData,refetchinCompletedLOcationData}) => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState([{ id: 1 }]);
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const [submitForm, { isLoading, isError, isSuccess, error }] =
  useAddMultipleBranchMutation();
    const { data: userData } = useUserGetEditQuery();


  const handleAddNew = () => {
    setLocations([...locations, { id: locations.length + 1 }]);
  };

  const handleInputChange = (id, field, value) => {
    let formattedValue = value;

    // Validation for businessName: only letters and spaces allowed
    if (field === "businessName") {
      const isValid = /^[A-Za-z\s]*$/.test(value);
      if (!isValid) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: {
            ...prevErrors[id],
            businessName: "Only letters and spaces are allowed.",
          },
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: {
            ...prevErrors[id],
            businessName: "",
          },
        }));
      }
    }

    // Validation for address: remove special characters except common address symbols
    if (field === "address") {
      formattedValue = value.replace(/[^A-Za-z0-9\s,.-]/g, ""); // Allows letters, numbers, spaces, commas, and periods
      if (value !== formattedValue) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: {
            ...prevErrors[id],
            address: "Special characters removed.",
          },
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: {
            ...prevErrors[id],
            address: "",
          },
        }));
      }
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: {
        ...prevValues[id],
        [field]: formattedValue,
      },
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    locations.forEach((location) => {
      const values = formValues[location.id] || {};
      // if (!operationalStatus.length) {
      //   isValid = false;
      //   newErrors[location.id] = {
      //     ...newErrors[location.id],
      //     confirmation_status: "Please select a status.",
      //   };
      // }
      // Additional validations remain unchanged
      if (!values.businessName) {
        isValid = false;
        newErrors[location.id] = {
          ...newErrors[location.id],
          businessName: "Please enter your business name.",
        };
      }
      if (!values.location) {
        isValid = false;
        newErrors[location.id] = {
          ...newErrors[location.id],
          location: "Please enter your location.",
        };
      }

      if (!values.lat || !values.lng) {
        isValid = false;
        newErrors[location.id] = {
          ...newErrors[location.id],
          location: "Please choose a valid branch from the suggestions.",
        };
      }
      
      if (!values.address) {
        isValid = false;
        newErrors[location.id] = {
          ...newErrors[location.id],
          address: "Please enter your address.",
        };
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formattedData = locations.map((location) => ({
        business_name: formValues[location.id].businessName,
        location: formValues[location.id].location,
        address: formValues[location.id].address,
        latitude: formValues[location.id].lat, // Pass latitude
        longitude: formValues[location.id].lng, // Pass longitude
      }));

      try {
        const response = await submitForm(formattedData).unwrap();
        if (response) {
          if(userData?.free_trial == "active"){
            navigate("/food-service-directorypagelisting");
            handleCloseLocationModal();
            refetchinCompletedLOcationData();
            refetchCompletedLOcationData()
          }
          else{
            navigate("/food-service-directory-payment");
          }
         
        }
      } catch (err) {
        console.error("Failed to submit form, please enter a correct place:", err);
      }
    }
  };

  const handleRemoveLocation = (id) => {
    setLocations((prevLocations) =>
      prevLocations.filter((loc) => loc.id !== id)
    );
    setFormValues((prevValues) => {
      const newValues = { ...prevValues };
      delete newValues[id];
      return newValues;
    });
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id];
      return newErrors;
    });
  };
  const [operationalStatus, setOperationalStatus] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState({});

  const handleChangeStatus = (e, locationId) => {
    const { id, checked, value } = e.target;

    // Update selected options for each location
    setSelectedOptions((prevState) => {
      const updatedOptions = {
        ...prevState,
        [locationId]: {
          ...prevState[locationId],
          [value]: checked,
        },
      };

      // Build the updated operationalStatus array based on selected options
      const updatedStatus = [];
      if (updatedOptions[locationId]?.planning) updatedStatus.push(1); // ID for 'Planning to open'
      if (updatedOptions[locationId]?.open) updatedStatus.push(2); // ID for 'Open for business'
      if (updatedOptions[locationId]?.exiting) updatedStatus.push(3); // ID for 'Looking to exit'

      // Update form values with operational status for the specific location
      setFormValues((prevValues) => ({
        ...prevValues,
        [locationId]: {
          ...prevValues[locationId],
          confirmation_status: updatedStatus,
        },
      }));

      return updatedOptions;
    });
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAP_API_LOCATION,
    libraries,
  });
  const searchBoxRefs = useRef({});
  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const onPlacesChanged = (id) => {

   
    if (searchBoxRefs.current[id]) {
      const places = searchBoxRefs.current[id].getPlaces();
     
      if (places && places.length > 0) {
        const selectedPlace = places[0];
        const locationName = selectedPlace.name || "";

       
        let lat = null,
            lng = null;
  
        // Extract latitude and longitude if available
        if (selectedPlace.geometry && selectedPlace.geometry.location) {
          lat = parseFloat(selectedPlace.geometry.location.lat().toFixed(6));
          lng = parseFloat(selectedPlace.geometry.location.lng().toFixed(6));
        }
  
        // Update the form values for the specific location
        setFormValues((prevValues) => ({
          ...prevValues,
          [id]: {
            ...prevValues[id],
            location: locationName, // Set the selected location name
            lat,                    // Set the latitude
            lng,                    // Set the longitude
          },
        }));

        // setErrors((prevErrors) => {
        //   const updatedErrors = { ...prevErrors };
        //   if (updatedErrors[id]?.location) {
        //     delete updatedErrors[id].location;
        //   }
        //   return updatedErrors;
        // });
        
      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseLocationModal}
        backdrop="true"
        dialogClassName="modal-dialog-centered add-another-branch-modal"
      >
          <Modal.Header>
          <button
            type="button"
            className="close-icon"
            aria-label="Close"
            onClick={handleCloseLocationModal}
          >
            &times;
          </button>
        </Modal.Header>
        <Modal.Body>
          <h2>Add New Branch </h2>
          <p>
          Add a new address and location for free. Full integration and directory upgrades require payment
          </p>
          <p className="required-message-impoertant">Listings will not appear in the ecosystem until the business name and location address are correctly entered.</p>
          <form onSubmit={handleSubmit}>
            {locations.map((location) => (
              <div key={location.id}>
                <h4 className="mb-16">Branch/State  {location.id}</h4>

                <Row className="mt-16">
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <div className="form-control">
                      <label>
                        Business name<span className="red-star">*</span>
                      </label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter your business name"
                        value={formValues[location.id]?.businessName || ""}
                        onChange={(e) =>
                          handleInputChange(
                            location.id,
                            "businessName",
                            e.target.value
                          )
                        }
                      />
                      {errors[location.id]?.businessName && (
                        <p className="error">
                          {errors[location.id].businessName}
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <div className="form-control">
                      <label>
                      Branch/State <span className="red-star">*</span>
                      </label>
                      <br />
                      {/* <input
                        type="text"
                        placeholder="Enter your location"
                        value={formValues[location.id]?.location || ""}
                        onChange={(e) =>
                          handleInputChange(
                            location.id,
                            "location",
                            e.target.value
                          )
                        }
                      /> */}
                      <StandaloneSearchBox
                          onLoad={(ref) => (searchBoxRefs.current[location.id] = ref)} // Correctly set the reference for each location by its ID
                          onPlacesChanged={() => onPlacesChanged(location.id)} 
                      >
                        <input
                          type="text"
                          placeholder="Enter your branch"
                        
                          className="autocomplete-input"
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              [location.id]: {
                                ...formValues[location.id],
                                location: e.target.value,
                              },
                            })
                          }
                          style={{ width: "100%", padding: "10px" }}
                        />
                      </StandaloneSearchBox>
                      {errors[location.id]?.location && (
                        <p className="error">{errors[location.id].location}</p>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <div className="form-control">
                      <label>
                        Address<span className="red-star">*</span>
                      </label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter your address"
                        value={formValues[location.id]?.address || ""}
                        onChange={(e) =>
                          handleInputChange(
                            location.id,
                            "address",
                            e.target.value
                          )
                        }
                      />
                      {errors[location.id]?.address && (
                        <p className="error">{errors[location.id].address}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                {locations.length > 1 && (
                  <button
                    type="button"
                    className="prev-btn m-0"
                    onClick={() => handleRemoveLocation(location.id)}
                  >
                    Delete
                  </button>
                )}
                <hr />
              </div>
            ))}
            {/* <div className="btn-add-another-location">
              <Button type="button" onClick={handleAddNew}>
                + Add Another Location
              </Button>
            </div>
            <div className="location-modal-footer">
              <Button type="submit" className="save-btn">
                {isLoading ? "Submitting..." : "Save & Proceed"}
              </Button>
              <Button className="cancel-btn" onClick={handleCloseLocationModal}>
                Cancel
              </Button>
            </div> */}
            <Row className="action-btn">
              <Col xs={12} sm={12} lg={6} md={6} className="text-start">
                <button
                  type="button"
                  className="prev-btn m-0"
                  onClick={handleAddNew}
                >
                  Add new
                </button>
              </Col>
              <Col xs={12} sm={12} lg={6} md={6} className="text-end">
                <button
                  type="button"
                  className="prev-btn btn-64t35ne"
                  onClick={handleCloseLocationModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-save-primary-food" disabled={isLoading}>
                  Add
                </button>
              </Col>
              {isError && (
                <p style={{ color: "red" }}>
                  Error: {error?.data?.error || "Failed to submit form"}
                </p>
              )}
              {isSuccess && (
                <p style={{ color: "green" }} className="isSuceesMsg">
                 Branch/State added successfully!
                </p>
              )}
              {isLoading && <div class="loader-food"></div>}
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComponent;
