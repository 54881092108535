import React, { useState } from "react";
import "../Styles/CommonSignUp.css";
import { Row, Col } from "react-bootstrap";
import FoodServiceImg from "../Assets/Images/food-service-defaultimg.png";
import TradeServiceImg from "../Assets/Images/tradeservice-default-img.png";
import CafeDefaultImg from "../Assets/Images/cafe-defaultimg.png";
import Elipse from "../Assets/Images/enterprenurship-elipse1.svg";
import ElipseRed from "../Assets/Images/elipse-red.svg";
import ElipseBlue from "../Assets/Images/traders-elipse.svg";
import TickIcon from "../Assets/Images/tick-icon-black.svg";
import CrossIcon from "../Assets/Images/cross-icon-black.svg";
import TickPrimaryIcon from "../Assets/Images/tick-icon.svg";
import { Link } from "react-router-dom";
import Protext from "../Assets/Images/Pro.svg";
import liteIconDown from "../Assets/Images/lite-icon-down.svg";
import proIconDown from "../Assets/Images/po-icon-down.svg"
import { useGettsAmountQuery } from "../Services/CommonAPI"
import { Helmet } from 'react-helmet';
import BannerImg from "../Assets/Images/trade-signup.jpg"

function CommonSignUp() {
  const [activeCard, setActiveCard] = useState("trade");
  const [isFoodservice, setIsFoodservice] = useState(false);
  const [isTradeservice, setIsTradeservice] = useState(false);
  const [isCafe, setIsCafe] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  const { data: propricets } = useGettsAmountQuery();

  const handleCardClick = (cardName) => {
    setActiveCard(cardName);
    if (cardName == "food") {
      setIsFoodservice(true);
      setIsTradeservice(false);
      setIsCafe(false);
    }
    if (cardName == "cafe") {
      setIsFoodservice(false);
      setIsTradeservice(false);
      setIsCafe(true);
    }
    if (cardName == "trade") {
      setIsFoodservice(false);
      setIsTradeservice(true);
      setIsCafe(false);
    }
  };
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };


  return (
    <>

      <Helmet>
        <title>Clever Cafe | Sign Up - Where Every Day Is an Expo for Australia’s Cafe Industry</title>
        <meta name="description" content="Join Clever Cafe today and gain full access to a wealth of resources, industry connections, and daily opportunities designed to grow your cafe business." />
      </Helmet>

      <div className="common-signup common-signup-trade">
        <section className="common-signup-banner">
          <div className="common-signup-banner-content">
            {/* <h2>
            Choose your solution and access your
            <span className="disply-blk">cafe ecosystem membership</span>
          </h2> */}
            <h2>Ready to Get Started?
            </h2>
          </div>
        </section>
        <section className="resgister-section">
          <Row className="align-items-center">


            <Col
              sm={12}
              lg={4}
              md={4}
              className={activeCard === "trade" ? "center-card" : ""}
            >
              <div
                className={`card card-trade ${activeCard === "trade" ? "active" : ""
                  }`}
                onClick={() => handleCardClick("trade")}
              >
                <div className="card-image">
                  <h4>
                    <img src={ElipseBlue} className="elipse-img" />
                    Trades & Services
                  </h4>
                  <p>
                    <div className="blue-circle"></div>
                    <span className="mr-20">Covering 30+ Sectors of Operational Support
                    </span>
                  </p>
                  <h5>
                    <span><span className="price-symbol">$</span>{propricets?.amount}</span> /state monthly
                  </h5>
                </div>
                <div className="card-body">
                  <Link to="/trade-service-signup">
                    {" "}
                    <button className="register-btn">Register</button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </section>



        <section className="explore-features explore-features-trade">
          <Row>
            <Col md={6} lg={6} sm={12} className="m-0 p-0">
              <div className="explore-all-features">
                <div className="explore-all-table">
                  <h5>Explore all features</h5>
                  <p className="tax-deductiblle-cs">(Tax Deductible!)</p>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" className="feature-head">
                          Features
                        </th>

                        <th scope="col" className="pro-head">
                          <b>${propricets?.amount}</b>/ state monthly
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                            Professional directory listing
                          </span>

                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                            Ecosystem integration
                          </span>

                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                            24/7 relevant connections
                          </span>{" "}

                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                            Automated networking
                          </span>{" "}

                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                            Local & national market reach
                          </span>

                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                            Strengthen online presence
                          </span>{" "}

                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                            Review boosting
                          </span>{" "}

                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                            Media uploads
                          </span>

                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                            Story sharing
                          </span>{" "}

                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                            Brand awareness
                          </span>

                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                            Service showcase
                          </span>{" "}

                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                            Event promotion
                          </span>

                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                            Direct account links
                          </span>

                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <Row>
                    <Col md={12} lg={12} sm={12}>
                      <Link to="/trade-service-signup">
                        {" "}
                        <button className="register-btn-btm">
                          Register Now
                        </button>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6} sm={12} className="m-0 p-0">
              <div className="more-for-you">
                <h4>Matching Trades & Services with New Cafe Customers at The Right Time</h4>
                <Row>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Service Account Application </h5>
                    <ul>
                      <li>direct links for cafe member account applications</li>
                      <li>cafe members applying are savvy and well-educated</li>
                      <li>advanced training in business management and financial acumen</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Review Boosting</h5>
                    <ul>
                      <li>ecosystem recognition feature</li>
                      <li>direct links to review platforms</li>
                      <li>strengthened online presence</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Directory & Ecosystem</h5>
                    <ul>
                      <li>make a powerful introduction</li>
                      <li>professional business listings</li>
                      <li>showcase your brand and tell your story</li>
                      <li>focus on key states to enhance prominence</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Customer Education</h5>
                    <ul>
                      <li>upload various content types</li>
                      <li>educate cafe members</li>
                      <li>showcase services and promotions</li>
                      <li>connect better with cafe members through specialist cafe programs</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Easy Networking</h5>
                    <ul>
                      <li>seamless ecosystem integration</li>
                      <li>24/7 relevant connections</li>
                      <li>automated criteria-based networking</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Event Promotion </h5>
                    <ul>
                      <li>online and in-person event sharing</li>
                      <li>streamlined event promotion</li>
                      <li>targeted audience engagement</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Marketing & Exposure</h5>
                    <ul>
                      <li>
                        boost your visibility across both local and national
                        markets
                      </li>
                      <li>engage with cafe entrepreneurs at crucial times</li>
                      <li>
                        enhance your online presence and build industry
                        connections
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </section>

      </div>
    </>

  );
}

export default CommonSignUp;
