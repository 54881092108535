// Services/TradeserviceAPI.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASEURL_SERVER;

export const commonapi = createApi({
  reducerPath: 'commonapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('accessToken');

      
      const noAuthEndpoints = ['getStates','getOperationalStatus','getceAmount','getfsAmount','gettsAmount', 'submitInviteaFriend'];
      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getStates:builder.query({
        query: () => "/common/states/",
      }),
      getOperationalStatus:builder.query({
        query: () => "common/ce-operational-status/",
      }),

      getRefreshToken:builder.query({
        query: () => "auth/token/refresh/",
      }),

      getRefreshToken: builder.mutation({
        query: (Postata) => ({
          url: `auth/token/refresh/`,
          method: 'POST',
          body: Postata,
        }),
      }),
      logout: builder.mutation({
        query: (Postata) => ({
          url: `auth/logout/`,
          method: 'POST',
          body: Postata,
        }),
      }),
 getceAmount:builder.query({
  query: () => "stripe/ce/amount/",
}),
getfsAmount:builder.query({
  query: () => "stripe/fs/amount/",
}),
gettsAmount:builder.query({
  query: () => "stripe/ts/amount/",
}),

submitInviteaFriend : builder.mutation({
  query: (postdata) => ({
    url: 'common/invite-a-friend/',
    method: 'POST',
    body: postdata,
  }),
}),
  }),
});

export const {
 useGetStatesQuery,
 useGetOperationalStatusQuery,
 useGetRefreshTokenMutation,
 useGetceAmountQuery,
 useGetfsAmountQuery,
 useGettsAmountQuery,
 useSubmitInviteaFriendMutation
} = commonapi;
