import React, { useState, useEffect, useRef } from "react";
import "../Styles/ArticleDetail.css";
import ArticleDetailImg from "../Assets/Images/article-detail.png";
import CafeStartup from "../Assets/Images/cafe-starup.png";
import BusinessStrategy from "../Assets/Images/business-planning-strategy.png";
import Operations from "../Assets/Images/operations.png";
import Team from "../Assets/Images/team.png";
import Leadeship from "../Assets/Images/leadership.png";
import Profilibilty from "../Assets/Images/profitibility.png";
import Creativity from "../Assets/Images/creativity.png";
import ExitStrategy from "../Assets/Images/exit-strategy.png";
import WingPNG from "../Assets/Images/pngwing.png";
import { Row, Col } from "react-bootstrap";
import ArticleReadMore1 from "../Assets/Images/ariticle-read-more-1.png";
import ArticleReadMore2 from "../Assets/Images/article-read-more-2.png";
import ArticleReadMore3 from "../Assets/Images/article-read-more-3.png";
import RightArrow from "../Assets/Images/right-arrow.svg";
import { Link } from "react-router-dom";
import ReadMoreArticleBg from "../Assets/Images/readmore-article-bg.png";
import Rocket from "../Assets/Images/rocket_svgrepo.com.svg";
import Lock from "../Assets/Images/lock_svgrepo.com.svg";
import MessageBox from "../Assets/Images/message-text-1_svgrepo.com.svg";
import Linkdin from "../Assets/Images/linkedin-black.svg";
import Facebook from "../Assets/Images/facebook-black.svg";
import Instagram from "../Assets/Images/instagram-black.svg";
import Youtube from "../Assets/Images/youtube-black.svg";
import GoogleMyBusiness from "../Assets/Images/google-my-business.svg";
import EllispeYellow from "../Assets/Images/Ellipse-ylw.svg";
import EllipseRed from "../Assets/Images/Ellipse-red.svg";
import EllipseBlue from "../Assets/Images/elipse-blue.svg";
import { useGetArticleDetailsQuery, useGetRecentArticleDataQuery } from "../Services/ArticleAPI";
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import { useMailchimpMutation } from "../Services/ArticleAPI";
import PageLoader from "../Assets/Images/page-loader.gif"

function ArticleDetail() {
  const { id } = useParams();
  const { data: article, isLoading, error } = useGetArticleDetailsQuery({ id });
  const { data: recent } = useGetRecentArticleDataQuery();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const handleShareClick = (platform) => {
    const currentUrl = window.location.href; // URL to share, can be dynamic
    let shareUrl = "";

    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(currentUrl)}`;
        break;
      case "instagram":
        shareUrl = "https://www.instagram.com"; // Instagram does not support direct share links, just link to your profile/page
        break;
      case "youtube":
        shareUrl = "https://www.youtube.com"; // Link to your YouTube channel or a video
        break;
      default:
        return;
    }

    // Open the share URL in a new tab
    window.open(shareUrl, "_blank");
  };
  const [formData, setFormData] = useState({
    email: '',
  });
  const [verified, setVerified] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [mailchimp, { isLoading: Submitfoemloading, isError: submitformIsError, isSuccess: submitformisSuccess, error: iserrorSubmitForm }] = useMailchimpMutation();
  const recaptchaRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await mailchimp({ ...formData, recaptchaToken }).unwrap();
      // alert('Successfully subscribed to the newsletter!');
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Successfully subscribed to the newsletter!',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      setFormData({
        email: '',
      });
      recaptchaRef.current.reset();
      setVerified(false);
    } catch (error) {
      // alert('Subscription failed. Please try again.');
      Swal.fire({
        icon: 'error',
        title: error?.data?.error?.title,
        text: error?.data?.error?.title === "Member Exists" 
          ? "This email is already subscribed to the mailing list." 
          : error?.data?.error?.detail,
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
  };

  if (isLoading) {
    // Render a loader if the data is still loading
    return (
      <div className="page-loader">
        <img src={PageLoader} alt="PageLoader" width={100} />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Clever Cafe | Articles - Cafe & Hospitality Industry Insights & Tips</title>
        <meta name="description" content="Browse our expert articles for actionable advice, industry trends, and essential tips to enhance your cafe operations and profitability." />
      </Helmet>
      <div className="article-detail-page">
        <div className="article-detail">
          <section className="article-detail-banner">
            <h3>{/* {article?.title} */}</h3>

            {/* <img
            src={ArticleDetailImg}
            alt="ArticleDetailImg"
            className="banner-img"
          /> */}
            <p
              className="paragraph"
              dangerouslySetInnerHTML={{ __html: article?.content }}
            >
              {/* {article.content} */}
            </p>
          </section>

          <section className="cafe-startup">
            <div className="share-icon-btm">
              <a href="https://www.facebook.com/clevercafeau/" target="_blank">  <img src={Facebook} alt="share-icon" width={20} /></a>
              <a href="https://www.linkedin.com/company/clevercafecompany/" target="_blank">   <img src={Linkdin} alt="share-icon" width={20} /></a>

              <a href="https://www.instagram.com/clevercafeau/ " target="_blank" >  <img src={Instagram} alt="share-icon" width={20} /></a>
              <a href="https://www.youtube.com/@clevercafeau" target="_blank">  <img src={Youtube} alt="share-icon" width={20} /></a>


            </div>
          </section>
        </div>
        <section className="our-news-letter">
          <div className="news-letter-cnt">
            <h3>Our Newsletter</h3>
            <p>
              Subscribe to our monthly newsletter, We'll serve up all the latest
              from{" "}
              <span className="disply-blk">Clever Cafe and the industry.</span>
            </p>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <button type="submit" disabled={Submitfoemloading}>
                {Submitfoemloading ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </div>
          <img src={EllipseBlue} alt="elipse" className="ellipse-blue" />
          <img src={EllipseRed} alt="elipse" className="ellipse-red" />
          <img src={EllispeYellow} alt="elipse" className="ellipse-ylw" />
        </section>
        <section className="article-readmore">
          <h3>Read More Articles</h3>
          <img src={WingPNG} alt="pngwing" className="png-wing" />
          {recent?.map((article) => (
            <Row className="read-more-article-card">
              <Col xs={12} sm={12} lg={4} md={4}>
                <div className="card-image">
                  <img src={article.cover_image} alt="card-img" />
                </div>
              </Col>
              <Col xs={12} sm={12} lg={8} md={8}>
                <div className="card-body">
                  <p className="publish-date"> {article.category_name} • {article.date}</p>

                  <Link to={`/article-detail/${article.id}`}>    <img src={RightArrow} alt="arrow" className="right-arrow" />
                  </Link>
                  <h3>
                    {article.title}
                  </h3>
                  <p>
                    {article.description}
                  </p>

                </div>
              </Col>
            </Row>
          ))}

        </section>
        <section className="ready-to-start">
          <h4>Ready to get started?</h4>
          <h3>Join Australia's Premier Cafe</h3>
          <h3>Ecosystem</h3>
          <Link to="/food-service-signup-free">
            <button className="btn get-ready-outline-btn" type="button">
              Foodservice
            </button></Link>
          <Link to="/ccowner-signup-free-trial"> <button className="btn get-ready-btn" type="button">
            Get a membership
          </button></Link>
          <Link to="/trade-service-signup-free"><button className="btn get-ready-outline-btn" type="button">
            Trades & Services
          </button></Link>
          <Row className="mt-80 border-top">
            <Col xs={12} sm={12} lg={4} md={4}>
              <p>
                <img src={Rocket} alt="help-img" className="help-img" />
                Unmatched tools, insights & connections
              </p>
            </Col>
            <Col xs={12} sm={12} lg={4} md={4}>
              <p>
                <img src={Lock} alt="help-img" className="help-img" />
                Safe and secure always backed up
              </p>
            </Col>
            <Col xs={12} sm={12} lg={4} md={4}>
              <p>
                <img src={MessageBox} alt="help-img" className="help-img" />
                Dedicated support here for you at any time
              </p>
            </Col>
          </Row>
        </section>
        <div className="share-icon-div">
          <ul>
            <a href="https://www.facebook.com/clevercafeau/" target="_blank">  <li>
              <img src={Facebook} alt="share-icon" />
            </li></a>
            <a href="https://www.linkedin.com/company/clevercafecompany/" target="_blank">   <img src={Linkdin} alt="share-icon" width={20} /> <li>

            </li></a>
            <a href="https://www.instagram.com/clevercafeau/ " target="_blank" >  <img src={Instagram} alt="share-icon" width={20} /> <li>

            </li></a>
            <a href="https://www.youtube.com/@clevercafeau" target="_blank">   <li>
              <img src={Youtube} alt="share-icon" />
            </li></a>
          </ul>
        </div>
        <img
          src={ReadMoreArticleBg}
          alt="ReadMoreArticleBg"
          className="read-more-articlebg"
        />
      </div>
    </>
  );
}

export default ArticleDetail;
