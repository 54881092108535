import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/Login.css";
import { useSubmitLoginMutation } from "../../../Services/TradeserviceAPI";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../Assets/Images/logo-white.svg";
import VectorImg from "../../../Assets/Images/login-cafe-bg-vector.svg";
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const formErrors = {};
    if (!formData.email) formErrors.email = "User Name is required.";
    if (!formData.password) formErrors.password = "Paasword is required.";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (validateForm) {
  //   }
  // };
  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useSubmitLoginMutation();

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isError) {


      if (error?.status === 403) {
        setErrorMessage("Too many attempts. Please try again later.");
      } else {
        setErrorMessage(error?.data?.message || error?.data?.detail || error?.data?.error || "Login Failed");
      }
    }
  }, [isError, error]);


  const handleSubmit = async (e) => {
    const un = "techteam";
    e.preventDefault();
    if (validateForm()) {
      let postData = {
        username: formData.email,
        password: formData.password,
      };
      try {
        const response = await submitForm(postData).unwrap();
        localStorage.setItem("accessToken", response.access);
        localStorage.setItem("refreshToken", response.refresh);
        const token = response.access;
        const payload = token.split('.')[1];
        const decodedPayload = JSON.parse(atob(payload));
        const userId = decodedPayload.user_id;

        localStorage.setItem("accountHolderId", userId);
        setFormData({
          email: "",
          password: "",
        });

        const timer = setTimeout(() => {
          // navigate("/trade-service-directorypagelisting");
          window.location.replace("/trade-service-directorypagelisting");
        }, 1000);
      } catch (err) {
        console.error("Failed to submit form:", err);
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Signed in successfully",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      if (isError) {

        if (error?.status === 403) {
          setErrorMessage("Too many attempts. Please try again later.");
        } else {
          setErrorMessage(error?.data?.message || error?.data?.detail || error?.data?.error || "Login Failed");
        }
      }
    }
  }, [isSuccess, isError, error]);




  return (
    <>
      <Helmet>
        <title>Clever Cafe | Login - Access Your Cafe Industry Dashboard</title>
        <meta name="description" content="Log in to manage your cafe's journey, connect with suppliers, and access tools designed to streamline operations and boost profitability." />
        <meta name="keywords" content="cafe consulting services in australia, best cafe consulting services in australia, opening a cafe business plan, smart cafe business plans australia" />
      </Helmet>
      <div className="login trade-login">
        <Row>
          <Col xs={12} md={5} sm={12} lg={5}>
            <div className="login-bg">
              <div className="logo-div">
                <Link to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
              </div>
              <div className="login-bg-text">
                <h2>
                  Trades & services
                </h2>
                <p>
                  Discover the Breakthrough You've Been Looking For with Australia's Premier Cafe Industry Support Platform
                </p>
              </div>
              <img src={VectorImg} alt="VectorImg" className="vector-img-login" />
            </div>
          </Col>
          <Col xs={12} md={7} sm={12} lg={7} className="left-div">
            <p className="go-to-register">
              Don't have an account?{" "}
              <Link to="/trade-service-signup">
                <span>Register</span>
              </Link>
            </p>
            <div className="login-form">
              <h3>
                Welcome to
                <br />
                <span>Clever Cafe</span>
              </h3>
              <p className="login-para">
                {/* To begin this journey, tell us what type of{" "}
              <span className="disply-blk">account you’ll be opening.</span>{" "} */}
                To continue, please log in to the appropriate account.
              </p>
              <Row className="mrl-_30">
                <Col xs={12} sm={12} lg={4} md={4}>
                  <Link to="/cafe-login"> <div className="card">
                    <div className="card-text">Cafe Entrepreneurship</div>
                  </div></Link>
                </Col>
                <Col xs={12} sm={12} lg={4} md={4}>
                  <Link to="/food-login"><div className="card">
                    <div className="card-text">Foodservice</div>
                  </div></Link>
                </Col>
                <Col xs={12} sm={12} lg={4} md={4}>
                  <div className="card trade-active">
                    <div className="card-text">Trades & services</div>
                  </div>
                </Col>
              </Row>
              <form onSubmit={handleSubmit}>
                <div className="form-control">
                  <label>Username/ Email</label>
                  <br />

                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your username/ Email"
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="form-control">
                  <label>Password</label>
                  <br />
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Enter your password"
                  />
                  <Link to="/trade-forgot-password-email"><div className="forgot-password-login">Forgot password?</div></Link>
                  {errors.password && <p className="error">{errors.password}</p>}
                </div>
                <div className="form-control">
                  <button type="submit" className="login-submit-btn">
                    Login
                  </button>
                </div>
                {isError && errorMessage && (
                  <p style={{ color: "red" }}>
                    {errorMessage}
                  </p>
                )}
                {isSuccess && (
                  <p style={{ color: "green" }} className="isSuceesMsg">
                    {/* Login Successfull! */}
                  </p>
                )}
                {isLoading && <div class="loader-trade"></div>}
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </>

  );
}

export default Login;
