import React, { useState, useEffect } from "react";
import "../Styles/Preference.css";
import { Row, Col } from "react-bootstrap";
import ProfileIMg from "../../../Assets/Images/default-profile-img.png";
import EditImg from "../../../Assets/Images/edit-green.svg";
import DeleteList from "../../../Assets/Images/delete-green.svg";
import EditPasswordModal from "../Components/PreferenceEditPassword";
import DeleteAccountModal from "../Components/PreferenceAccountDeleteModal";
import {
  useAccountChangeProfileMutation,
  useGetAccountHolderDataQuery,
  useBranchDeleteMutation,
  useEmailNotificationStatusMutation,
  useGetEmailNotificationStatusQuery,
} from "../../../Services/TradeserviceAPI";
import { useGetBranchListQuery } from "../../../Services/TradeserviceAPI";
import Swal from 'sweetalert2';

import { useDeleteBranchyTicketIdMutation } from "../../../Services/SupportAPI";
import { useDeleteBranchbyNoticeIdMutation  } from "../../../Services/CommunityAPI"
import { useDeleteBranchbyRecordIdMutation } from "../../../Services/SecondaryAPI"

function Preference() {
  const [activeTab, setActiveTab] = useState(0);

  const [profileImage, setProfileImage] = useState(null);
  const [selectedProfileImage, setSeletedImage] = useState(null);
  const [showEditModal, setshowEditModal] = useState(false);
  const [showAccountDeleteMOdal, setshowAccountDeleteModal] = useState(false);

  const [deleteUserbyTicketId] = useDeleteBranchyTicketIdMutation();


  // Handle the file input change
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSeletedImage(e.target.files[0]);
      // Set the selected image as the new profile image
      const selectedImage = URL.createObjectURL(e.target.files[0]);
      setProfileImage(selectedImage);
      handlesubmit();
    }
  };
  const handleShowEditModal = () => {
    setshowEditModal(true);
  };
  const handleCloseEDitPasswordModal = () => {
    setshowEditModal(false);
  };
  const handleDeleteCickModal = () => {
    setshowAccountDeleteModal(true);
  };

  const handleCloseAccountDeleteModal = () => {
    setshowAccountDeleteModal(false);
  };
  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useAccountChangeProfileMutation();

  const {
    data: accouldHolderData,
    isLoading: accountholderdataloading,
    refetch,
  } = useGetAccountHolderDataQuery();
  const { data: branchListData, refetch: branchlistRefetch } = useGetBranchListQuery();

  const handlesubmit = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxFileSize = 500 * 1024;
      const allowedTypes = ["image/png","image/jpg", "image/jpeg"]; 

  
      // File size validation
      if (file.size > maxFileSize) {
        Swal.fire({
          icon: 'error',
          title: 'Submission failed!',
          text: 'File size exceeds 500KB. Please upload a smaller file.',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        e.target.value = "";
        return;
      }
  
      // File type validation
      if (!allowedTypes.includes(file.type)) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid file format!',
          text: 'Only PNG, JPG and JPEG formats are allowed.',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        e.target.value = "";
        return;
      }
  
      // Proceed if validation passes
      const formData = new FormData();
      formData.append("image", file);
      try {
        const response = await submitForm(formData).unwrap();
        if (response) {
          Swal.fire({
            icon: 'success',
            title: 'Submission Successful!',
            text: 'Profile picture has been updated successfully',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          refetch();
        }
      } catch (err) {
        Swal.fire({
          icon: 'error',
          title: 'Submission failed!',
          text: 'Failed to update profile picture.',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
    } else {
      alert("Please try again");
    }
  };
  const [deleteBranch, {isSuccess:deletebranchSuccess}] = useBranchDeleteMutation();
  const [deleteBranchId, setDeleteBranchId] = useState("");
  const handleDeleteBranch = async (branchId) => {
    setDeleteBranchId(branchId);
    const confirmed = window.confirm("Are you sure you want to delete this branch?");
    if (!confirmed) return;

    try {
      const response = await deleteBranch(branchId).unwrap();
      alert("Branch deleted successfully");
      branchlistRefetch();
    } catch (err) {
      console.error("Failed to delete branch:", err);
      alert("Failed to delete branch");
    }
  };
  const branchId = localStorage.getItem("tdservicebranchId_current");

  const [emailNotifications, setEmailNotifications] = useState({
    ecosystem_matches: false,
    eco_enquiry: false,
    ecosystem_notification: false,
  });

  const [submitEmailNotificationStatus, { }] = useEmailNotificationStatusMutation();

  const handleChange = (event) => {
    const { name, checked } = event.target;

    // Update state
    setEmailNotifications((prevState) => ({
      ...prevState,
      [name]: checked,
    }));

    const Postata = {
      ...emailNotifications,
      [name]: checked, // Include the updated field
    };

    submitEmailNotificationStatus({ Postata });
  };

  const {
    data: getEmailNotificationStatus,
    refetch: refetchEmailNOtificationStatus,
  } = useGetEmailNotificationStatusQuery();

  useEffect(() => {
    if (getEmailNotificationStatus) {
      setEmailNotifications({
        ecosystem_matches: getEmailNotificationStatus.ecosystem_matches,
        eco_enquiry: getEmailNotificationStatus.eco_enquiry,
        ecosystem_notification: getEmailNotificationStatus.ecosystem_notification,
      });
    }
  }, [getEmailNotificationStatus]);

  useEffect(() => {
    const deleteRelatedRecords = async () => {
      const user_id = accouldHolderData?.user_id
      try {
        await deleteUserbyTicketId({user_id,deleteBranchId}).unwrap();
      } catch (err) {
        console.error("Failed to delete ticket record:", err);
      }

    };
  
    if (deletebranchSuccess) {
      deleteRelatedRecords();
    }
  }, [deletebranchSuccess]);

  return (
    <div className="preference tradeservice-preference">
      <h4>Preference</h4>
      <div className="vertical-tabs-container">
        {/* Tab Headers */}
        <div className="tab-headers">
          <div
            className={`tab-header ${activeTab === 0 ? "active" : ""}`}
            onClick={() => setActiveTab(0)}
          >
            Account
          </div>
          <div
            className={`tab-header ${activeTab === 1 ? "active" : ""}`}
            onClick={() => setActiveTab(1)}
          >
            Notifications
          </div>
        </div>

        {/* Tab Content */}
        <div className="tab-content">
          {activeTab === 0 && (
            <div className="account-tab">
              <div className="main-head">
                <h5>Account</h5>
              </div>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <div>
                    <p className="password">Password</p>
                    <p className="edit-password-trade" onClick={handleShowEditModal}>
                      Edit Password
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6} className="profile-div">
                  <p className="profile-pic-text">Profile picture</p>
                  <div className="profile-picture">
                    {/* The "Edit Image" Icon */}
                    <label htmlFor="imageUpload">
                      <img
                        src={EditImg}
                        alt="Edit"
                        className="profilpic-edit"
                      />
                    </label>

                    {/* The Hidden File Input */}
                    <input
                      type="file"
                      id="imageUpload"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handlesubmit}
                    />

                    {/* Display the Profile Image */}
                    <img
                      src={accouldHolderData?.image || ProfileIMg}
                      alt="Profile"
                      className="profile-pic"
                    />
                  </div>
                </Col>
              </Row>
              <div className="listing-div">
                <div className="listing-main-head">
                  <h5>Listings</h5>
                  {/* <div>
                    <button className="primary-btn-trade">Edit</button>
                  </div> */}
                </div>
              </div>{" "}
              <div className="listing-items">
                {branchListData?.map((branch) => {
                  const isActive = branch.id === branchId;

                  return (
                    <div
                      className={`items ${isActive ? "active-branch" : ""}`}
                      key={branch.id}
                    >
                      <Row>
                        <Col xs={10} sm={10} md={10}>
                          <p className="head">
                            {branch.location}{" "}
                            {isActive && (
                              <span className="active-text">(Active)</span>
                            )}
                          </p>
                          {/* <p className="sub-head">
                            {branch.operational_status === "open"
                              ? "Open for business"
                              : branch.operational_status === "planning"
                              ? "Planning to open"
                              : branch.operational_status === "exit"
                              ? "Looking to exit"
                              : "Unknown status"}
                          </p> */}
                        </Col>
                        <Col xs={2} sm={2} md={2} className="text-end">
                          <img src={DeleteList} alt="DeleteList" onClick={() => handleDeleteBranch(branch.id)} />
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </div>
              <div className="account-delete">
                <p className="action-text">Action</p>
                <p
                  className="delete-accnt-text-trade"
                  onClick={handleDeleteCickModal}
                >
                  Delete my account
                </p>
              </div>
            </div>
          )}
          {activeTab === 1 && <div className="account-notification-trade">
            <h5 className="email-notification-head">Notifications</h5>
            <div className="email-notification">
              <Row>
                <Col sm={10} md={10} lg={10}>
                  <p className="notification-list">Ecosystem notification</p>
                </Col>
                <Col sm={2} md={2} lg={2}>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="ecosystem_notification"
                      checked={emailNotifications.ecosystem_notification}
                      onChange={handleChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </Col>
              </Row>
              <Row>
                <Col sm={10} md={10} lg={10}>
                  <p className="notification-list">Ecosystem matches</p>
                </Col>
                <Col sm={2} md={2} lg={2}>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="ecosystem_matches"
                      checked={emailNotifications.ecosystem_matches}
                      onChange={handleChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </Col>
              </Row>
              {/* <Row>
                  <Col sm={10} md={10} lg={10}>
                    <p className="notification-list">Ecosystem enquiry</p>
                  </Col>
                  <Col sm={2} md={2} lg={2}>
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="eco_enquiry"
                        checked={emailNotifications.eco_enquiry}
                        onChange={handleChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </Col>
                </Row> */}
            </div>
          </div>}
        </div>
      </div>
      <EditPasswordModal
        showEditModal={showEditModal}
        handleCloseEDitPasswordModal={handleCloseEDitPasswordModal}
      />
      <DeleteAccountModal
        showAccountDeleteMOdal={showAccountDeleteMOdal}
        handleCloseAccountDeleteModal={handleCloseAccountDeleteModal}
      />
    </div>
  );
}

export default Preference;
